/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AttributesFormPartial.vue?vue&type=template&id=760dcb4a"
import script from "./AttributesFormPartial.ts?vue&type=script&lang=js&external"
export * from "./AttributesFormPartial.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports