import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { ModalEventMixin } from '~/mixins';
import { ManagableEventTypes } from '~/types';
let ModalFlight = class ModalFlight extends Mixins(ModalEventMixin) {
    baseUrl = '/events/flights';
    eventType = ManagableEventTypes.FLIGHT;
    formData = {
        isActive: true,
        launchDate: null,
        returnDate: null,
        commentText: null,
        name: null
    };
    get isFormValid() {
        return Boolean(this.formData.launchDate || this.formData.returnDate);
    }
    buildCreatePayload() {
        if (!this.isFormValid) {
            return null;
        }
        const payload = {
            commentText: this.formData.commentText,
            isActive: this.formData.isActive,
            launchDate: this.formData.launchDate,
            name: this.formData.name,
            returnDate: this.formData.returnDate
        };
        return payload;
    }
};
ModalFlight = __decorate([
    Component
], ModalFlight);
export default ModalFlight;
