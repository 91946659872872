import HardwareController from '~/utils/HardwareController';
export const HardwareModuleName = 'hardware';
export var StateTypes;
(function (StateTypes) {
    StateTypes["controller"] = "controller";
    StateTypes["entities"] = "entities";
    StateTypes["hardwareType"] = "hardwareType";
})(StateTypes || (StateTypes = {}));
const state = {
    controller: null,
    entities: [],
    hardwareType: null
};
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["setEntities"] = "setEntities";
    MutationTypes["setHardwareType"] = "setHardwareType";
})(MutationTypes || (MutationTypes = {}));
const mutations = {
    [MutationTypes.setHardwareType](state, payload) {
        state.hardwareType = payload;
        state.controller = new HardwareController(payload);
    },
    [MutationTypes.setEntities](state, payload) {
        state.entities = payload;
    }
};
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["fetchEntities"] = "fetchEntities";
})(ActionTypes || (ActionTypes = {}));
const actions = {
    async [ActionTypes.fetchEntities]({ commit, state }, includeArchive) {
        commit(MutationTypes.setEntities, []);
        const res = await state.controller?.fetchAllData(includeArchive);
        commit(MutationTypes.setEntities, res?.data);
    }
};
export var GetterTypes;
(function (GetterTypes) {
    GetterTypes["hardwareTypeDisplay"] = "hardwareTypeDisplay";
})(GetterTypes || (GetterTypes = {}));
const getters = {
    [GetterTypes.hardwareTypeDisplay]({ controller }) {
        if (!controller) {
            return null;
        }
        return controller.hardwareTypeDisplay;
    }
};
const hardwareModule = {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
export default hardwareModule;
