import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { EventTableMixin } from '~/mixins/events/EventTableMixin';
import { transformEvents } from '~/utils';
let ReadOnlyIncrement = class ReadOnlyIncrement extends Mixins(EventTableMixin) {
    get evas() {
        if (!this.eventData.evas) {
            return [];
        }
        return transformEvents(this.eventData.evas);
    }
    get comments() {
        return this.eventData.increment?.comments ?? [];
    }
};
ReadOnlyIncrement = __decorate([
    Component
], ReadOnlyIncrement);
export default ReadOnlyIncrement;
