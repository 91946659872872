import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay, numberDisplay } from '../helpers';
import { addBaseProperties, addFemurComments, addLaunchReturn } from '../mappers';
import { buildNotOkIcon, buildOkIcon } from '../ui/buildIconAsString';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS = [
    ...STANDARD_COLS,
    {
        align: 'right',
        headerSortType: HeaderSortOptions.NUMBER,
        noEscape: true,
        sortable: true,
        text: 'Total Filtered<br/>H<sub>2</sub>O (lbs)',
        value: '_volume'
    },
    {
        align: 'center',
        noEscape: true,
        sortable: true,
        text: 'Qty Status',
        value: '_quantityStatusIcon' //example "OK"
    }
];
export const FEMUR_EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS = [
    SERIAL_COL,
    {
        align: 'right',
        text: 'Total Filtered<br/><small>(max 1,000lbs)</small>',
        value: '_volume',
        sortable: true,
        noEscape: true,
        headerSortType: HeaderSortOptions.NUMBER
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    {
        text: 'H<sub>2</sub>O (lbs)',
        value: '_water',
        sortable: true,
        align: 'right',
        headerSortType: HeaderSortOptions.NUMBER
    }
];
export const transformEmuFeedwaterSupplyFilter = (items) => {
    return items
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map(addFemurComments)
        .map((item) => {
        const status = item.status;
        const filterQty = status?.volume?.value || 0;
        let _volume = numberDisplay(filterQty);
        _volume = alertableStatusDisplay(status?.volume, _volume);
        // adding row class based on volume
        const rowClass = item.status?.volume?.alertType === AlertType.ALERT
            ? 'red lighten-3'
            : item.status?.volume?.alertType === AlertType.WARNING
                ? 'orange lighten-3'
                : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _volume,
            _quantityStatusIcon: filterQty >= 1000 ? buildNotOkIcon() : buildOkIcon()
        });
        return _item;
    });
};
