import { alertableStatusDisplay, dateDisplay } from '..';
import { GENERIC_STATE_ICON_COL, NEXT_ACTIVITY_RESET_DUE_DATE_COL, STANDARD_COLS } from '../genericTableColumns';
import { addBaseProperties, addGenericState, addLaunchReturn } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const REBA_TABLE_HEADERS = [
    ...STANDARD_COLS,
    GENERIC_STATE_ICON_COL,
    NEXT_ACTIVITY_RESET_DUE_DATE_COL
];
export const ACTIVITY_REBA_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformReba = (items) => {
    return items
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map(addGenericState)
        .map((item) => {
        const status = item.status;
        let _nextActivityResetDueDate = dateDisplay(status?.activityResetDueDate?.value);
        _nextActivityResetDueDate = alertableStatusDisplay(status?.activityResetDueDate, _nextActivityResetDueDate);
        const _item = Object.assign({
            _nextActivityResetDueDate
        }, item);
        return _item;
    });
};
