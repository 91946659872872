import get from 'lodash.get';
import isempty from 'lodash.isempty';
import uniq from 'lodash.uniq';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $get: { value: get },
            $isEmpty: { value: isempty },
            $uniq: { value: uniq }
        });
    }
};
