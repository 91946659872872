var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FemurTable as FemurTableType, FemurTypeDisplay, HardwareType } from '~/types';
import { sortObjectBy, transformHardwaresToTableFormat } from '~/utils';
import { FemurTableHeadersByHardwareType } from '~/utils/constants/tableDefinitions';
let FemurTable = class FemurTable extends Vue {
    hardwareType;
    itemInstances;
    get tableItems() {
        return transformHardwaresToTableFormat(this.hardwareType, this.itemInstances)
            .map((item) => {
            // exit out of the map if not metox
            if (item._subType !== HardwareType.METOX) {
                return item;
            }
            // don't alert for this one off item instance https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/-/issues/416
            if (item.asBuiltNumber === '04-00' && item.drawingNumber === 'SV821799' && item.serialNumber === '0006') {
                item._regenerationDate = item._regenerationDate
                    .replace('fad fa-exclamation fa-sm alert-status error--text', '')
                    .replace(` class="error--text text--darken-2"`, '');
            }
            return item;
        })
            .sort(sortObjectBy('_serial', true));
    }
    get tableHeaders() {
        return FemurTableHeadersByHardwareType.get(this.hardwareType);
    }
    get tableTitle() {
        return FemurTypeDisplay.get(this.hardwareType) || '';
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof FemurTableType !== "undefined" && FemurTableType) === "function" ? _a : Object)
], FemurTable.prototype, "hardwareType", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], FemurTable.prototype, "itemInstances", void 0);
FemurTable = __decorate([
    Component
], FemurTable);
export default FemurTable;
