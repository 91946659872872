import Vue from 'vue';
import VueRouter from 'vue-router';
import $store from '~/store';
import { ActionTypes, UserModuleName } from '~/store/user';
import { EntityType } from '~/types';
import { UserRole } from '~/types/schemas';
import { READ_ONLY_HARDWARE_TYPES, RouterLogUtility } from '~/utils';
Vue.use(VueRouter);
const routes = [
    {
        path: `${process.env.BASE_URL}`,
        name: 'Home',
        component: () => import(/* webpackChunkName: "oot" */ '../views/HomeView/HomeView.vue')
    },
    {
        path: `${process.env.BASE_URL}hardware/:subType`,
        name: 'HardwareListView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/HardwareListView/HardwareListView.vue')
    },
    {
        path: `${process.env.BASE_URL}users/whoami`,
        name: 'WhoAmIView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/WhoAmIView/WhoAmIView.vue'),
        meta: {
            name: 'OOT - Who am I?',
            doNotList: true
        }
    },
    {
        path: `${process.env.BASE_URL}events/manage`,
        name: 'EventsManageView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/EventsManageView/EventsManageView.vue'),
        meta: {
            name: 'Events Manage',
            doNotList: true,
            accessRequirements: [],
            wikiUrl: `/Events`
        }
    },
    {
        path: `${process.env.BASE_URL}hardware/:subType/manage/:itemInstanceId`,
        name: 'HardwareTypeManageView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/HardwareTypeManageView/HardwareTypeManageView.vue'),
        meta: {
            name: 'Hardware Manage',
            doNotList: true,
            accessRequirements: [UserRole.HARDWARE_ADMIN],
            readonlyRedirect(route) {
                return `/reporting/item_instances/${route.params.itemInstanceId}?subType=${route.params.subType}`;
            }
        },
        beforeEnter(to, _, next) {
            const { subType, itemInstanceId } = to.params;
            const hardwareType = subType.toUpperCase();
            if (READ_ONLY_HARDWARE_TYPES.includes(hardwareType)) {
                return next({
                    path: `/reporting/item_instances/${itemInstanceId}`,
                    query: { subType }
                });
            }
            to.meta.wikiUrl = `hardware/${hardwareType.replaceAll(' ', '-')}`;
            return next();
        }
    },
    {
        path: `${process.env.BASE_URL}activities/manage/:activityId`,
        name: 'ActivityManageView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ActivityManageView/ActivityManageView.vue'),
        meta: {
            name: 'Activity Manage',
            doNotList: true,
            accessRequirements: [UserRole.ACTIVITY_ADMIN],
            readonlyRedirect(route) {
                return `/reporting/activities/${route.params.activityId}`;
            },
            wikiUrl: `/Activities`
        }
    },
    {
        path: `${process.env.BASE_URL}activities`,
        name: 'ActivitiesView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ActivitiesView/ActivitiesView.vue'),
        meta: {
            name: 'Activities',
            doNotList: true,
            accessRequirements: [],
            wikiUrl: `/Planned-Activities`
        }
    },
    {
        path: `${process.env.BASE_URL}reporting`,
        name: 'ReportingSearchView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ReportingSearchView/ReportingSearchView.vue'),
        meta: {
            name: 'Reporting Search View',
            doNotList: true,
            accessRequirements: []
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/annex_2`,
        name: 'Annex2ReportView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/Annex2ReportView/Annex2ReportView.vue'),
        meta: {
            name: 'Annex 2',
            description: 'This report displays pending maintenance due in a selected date range',
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.ACTIVITY, EntityType.EVENT],
            accessRequirements: [],
            wikiUrl: `/reporting/Annex-2`
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/femur_003`,
        name: 'FemurReportView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/FemurReportView/FemurReportView.vue'),
        meta: {
            name: 'FEMU-R-003',
            description: 'This report displays requirements vs actuals for on-orbit maintenance, stowage, and testing of the EMU and its Contract End Items (CEIs)',
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.ACTIVITY, EntityType.EVENT],
            accessRequirements: [],
            wikiUrl: `/reporting/FEMU-R-003`
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/flight_hardware`,
        name: 'FlightHardwareReportView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/FlightHardwareReportView/FlightHardwareReportView.vue'),
        meta: {
            name: 'Flight Hardware',
            description: 'This report displays Hardware Up and Hardware Down for a Flight Event.',
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.EVENT],
            accessRequirements: [],
            wikiUrl: `/reporting/Flight-Hardware`
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/activities`,
        name: 'ActivityReportView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ActivityReportView/ActivityReportView.vue'),
        meta: {
            name: 'Activities',
            description: 'This report displays all Activities matching the entered criteria',
            entityRelationships: [EntityType.ACTIVITY],
            accessRequirements: [],
            wikiUrl: `/reporting/Activities`
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/increments`,
        name: 'IncrementsReportView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/IncrementsReportView/IncrementsReportView.vue'),
        meta: {
            name: 'Increments',
            description: 'This report displays all Increments and associated EVAs in a selected date range',
            entityRelationships: [EntityType.EVENT],
            accessRequirements: [],
            wikiUrl: `/reporting/Increments`
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/activities/:activityId`,
        name: 'ReadOnlyActivityView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ReadOnlyActivityView/ReadOnlyActivityView.vue'),
        meta: {
            name: 'Read Only Activity Report',
            doNotList: true,
            accessRequirements: []
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/events/:eventId`,
        name: 'ReadOnlyEventView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ReadOnlyEventView/ReadOnlyEventView.vue'),
        meta: {
            name: 'Read Only Event Report',
            doNotList: true,
            accessRequirements: []
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/item_instances`,
        name: 'ItemInstanceReportView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ItemInstanceReportView/ItemInstanceReportView.vue'),
        meta: {
            name: 'Item Instances',
            description: 'This report displays all Item Instances matching the entered criteria',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            accessRequirements: [],
            wikiUrl: `/reporting/Item-Instances`
        }
    },
    {
        path: `${process.env.BASE_URL}reporting/item_instances/:itemInstanceId`,
        name: 'ReadOnlyItemInstanceView',
        component: () => import(/* webpackChunkName: "oot" */ '../views/ReadOnlyItemInstanceView/ReadOnlyItemInstanceView.vue'),
        meta: {
            name: 'Read Only Item Instance',
            doNotList: true,
            accessRequirements: []
        }
    },
    {
        path: `${process.env.BASE_URL}settings/manage_alerts`,
        name: 'AlertsManageView',
        component: () => import(/* webpackChunkName: "manage_alerts" */ '../views/AlertsManageView/AlertsManageView.vue'),
        meta: {
            name: 'Alerts',
            doNotList: true,
            accessRequirements: [UserRole.THRESHOLD_ALERT_ADMIN]
        }
    },
    {
        path: `${process.env.BASE_URL}unauthorized`,
        name: 'UnauthorizedView',
        component: () => import(/* webpackChunkName: "unauthorized" */ '../views/UnauthorizedView/UnauthorizedView.vue'),
        meta: {
            name: 'Unauthorized',
            doNotList: true,
            allowAnonymous: true
        }
    }
];
const routerWithLogging = new RouterLogUtility(VueRouter, routes);
routerWithLogging.router.beforeEach(async (to, from, next) => {
    const userModule = $store.state[UserModuleName];
    await $store.dispatch(`${UserModuleName}/${ActionTypes.fetchUser}`);
    if (to.meta?.allowAnonymous) {
        return next();
    }
    if (userModule.missingNamsApproval) {
        return next('/unauthorized');
    }
    const user = userModule.user;
    const hasAccess = to.meta?.accessRequirements
        ? to.meta.accessRequirements.every((req) => user?.roles?.includes(req))
        : true;
    if (hasAccess) {
        return next();
    }
    if (to.meta?.readonlyRedirect) {
        const readonlyRoute = to.meta?.readonlyRedirect(to);
        return next(readonlyRoute);
    }
    return next('/unauthorized');
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = async function (location) {
    let route;
    try {
        route = await originalPush.call(this, location);
    }
    catch (err) {
        if (err?.name !== 'NavigationDuplicated') {
            throw err;
        }
    }
    return route;
};
export default routerWithLogging.router;
