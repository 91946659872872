var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EntityType, FlightEventsResponse } from '~/types';
import { transformEvents } from '~/utils';
let EventMini = class EventMini extends Vue {
    entityType = EntityType.EVENT;
    isFetching = false;
    eventData;
    title;
    get eventTitle() {
        return this.eventData?.name;
    }
    get computedIcon() {
        return this.$icons[this.entityType];
    }
    get verticalLabelDisplayItems() {
        if (!this.eventData) {
            return [];
        }
        let items = [];
        // passing hardwareSubType in case we want to show more detailed vertical display items later
        const transformedEvents = transformEvents([this.eventData])[0];
        items = items.concat([
            {
                name: 'Launch',
                value: transformedEvents._launchDate
            },
            {
                name: 'Return',
                value: transformedEvents._returnDate
            },
            {
                name: 'Is Active?',
                value: transformedEvents._isActive
            }
        ]);
        return items;
    }
};
__decorate([
    Prop({
        default: {},
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof FlightEventsResponse !== "undefined" && FlightEventsResponse) === "function" ? _a : Object)
], EventMini.prototype, "eventData", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], EventMini.prototype, "title", void 0);
EventMini = __decorate([
    Component
], EventMini);
export default EventMini;
