import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/utils/base/BaseButton';
/**
 * This component represents a single action button on a mini. It assumes its
 * direct parent is of class `mini_actions_v2`. It accepts these props:
 *
 * - icon: this is the class string for the desired icon. usually you would get
 *   this from $icons on a Vue instance. (prop in BaseButton)
 *
 * - iconSize: this is a string number with option `px` (if absent, `px` will be
 *   appended). Example: `24px`. Default: `28`
 */
let ButtonMiniAction = class ButtonMiniAction extends BaseButton {
    iconSize;
    iconColor;
    label;
    get computedIconSize() {
        return this.iconSize.includes('px') ? this.iconSize : `${this.iconSize}px`;
    }
    get isLink() {
        return Boolean(this.href || this.to);
    }
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "color", void 0);
__decorate([
    Prop({
        default: '28',
        type: String
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "iconSize", void 0);
__decorate([
    Prop({
        type: String,
        default: 'white--text'
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "iconColor", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Action Button'
    }),
    __metadata("design:type", String)
], ButtonMiniAction.prototype, "label", void 0);
ButtonMiniAction = __decorate([
    Component
], ButtonMiniAction);
export default ButtonMiniAction;
