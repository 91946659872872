import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let ReadOnlyItemInstanceView = class ReadOnlyItemInstanceView extends Vue {
    get itemInstance() {
        const id = this.$route.params.itemInstanceId;
        const subType = `${this.$route.query.subType}`.toUpperCase();
        return { id, subType };
    }
};
ReadOnlyItemInstanceView = __decorate([
    Component
], ReadOnlyItemInstanceView);
export default ReadOnlyItemInstanceView;
