/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const removePropertyFromObject = (object, keyToRemove) => {
    const objectToManipulate = { ...object };
    return Object.keys(objectToManipulate)
        .filter((key) => key !== keyToRemove)
        .reduce((obj, key) => {
        obj[key] = objectToManipulate[key];
        return obj;
    }, {});
};
