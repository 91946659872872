import { __decorate, __metadata } from "tslib";
import { Component, Emit, Vue } from 'vue-property-decorator';
let BaseModalMixin = class BaseModalMixin extends Vue {
    formData;
    closeDialog() {
        this.emitModalClose();
    }
    emitModalClose() { }
    emitEntityCreated(text = 'Creation successful.') {
        this.$notification.add({ type: 'success', text });
    }
    emitEntityEdited(text = 'Edit successful.') {
        this.$notification.add({ type: 'success', text });
    }
    emitEntityDeleted(text = 'Deletion successful.') {
        this.$notification.add({ type: 'success', text });
    }
};
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalMixin.prototype, "emitModalClose", null);
__decorate([
    Emit('entityCreated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseModalMixin.prototype, "emitEntityCreated", null);
__decorate([
    Emit('entityEdited'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseModalMixin.prototype, "emitEntityEdited", null);
__decorate([
    Emit('entityDeleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseModalMixin.prototype, "emitEntityDeleted", null);
BaseModalMixin = __decorate([
    Component
], BaseModalMixin);
export { BaseModalMixin };
