var _a, _b;
import { __decorate, __metadata } from "tslib";
import { addDays } from 'date-fns';
import qs from 'qs';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { BaseModalMixin } from '~/mixins';
import { ActivityType, ActivityTypeDisplay, CommentType, HardwareType, HardwareTypeToActivityTypes, HeaderSortOptions } from '~/types';
import { ErrorUtility, formatTableValuesForHref, sortObjectBy, transformActivities } from '~/utils';
import { getActivityFormFields } from '~/utils/FormBuilder';
import { ACTIVITY_DATE_COL, LAST_UPDATE_BY_COL, LAST_UPDATE_DATE_COL, SUBTYPE_COL } from '~/utils/genericTableColumns';
import { enumToSelectOptions } from '~/utils/ui/enumToSelectOptions';
let ModalActivity = class ModalActivity extends Mixins(BaseModalMixin) {
    activities = null;
    activityComments = null;
    activityDate = null;
    activityType = null;
    attributesForm = {
        attributes: {}
    };
    activityTypes = enumToSelectOptions(ActivityType, ActivityTypeDisplay);
    activityFormPartials = [];
    hardware = [];
    loadingFormFields = false;
    selectedRow = null;
    ahdData;
    hardwareType;
    itemInstanceId;
    isPlanning;
    get activitiesTableHeaders() {
        return [
            ACTIVITY_DATE_COL,
            { ...SUBTYPE_COL, noEscape: true },
            LAST_UPDATE_DATE_COL,
            LAST_UPDATE_BY_COL,
            {
                align: 'right',
                headerSortType: HeaderSortOptions.NUMBER,
                sortable: true,
                text: '# of Hardware<br/>Logged Against',
                value: '_hardwareCount'
            }
        ];
    }
    get activityTypeOptions() {
        return this.activityTypes
            .filter((type) => {
            return HardwareTypeToActivityTypes.get(this.hardwareType)?.includes(type.value);
        })
            .sort(sortObjectBy('value'));
    }
    get activityTypeDisplay() {
        return this.activityType ? ActivityTypeDisplay.get(this.activityType) : '';
    }
    get activityDateDisplay() {
        return this.$dateDisplay(this.activityDate);
    }
    get disableSaveButton() {
        return !this.isFormValid;
    }
    get filters() {
        if (!this.activityDate) {
            return null;
        }
        return {
            activityDateAfter: this.activityDate,
            activityDateBefore: addDays(new Date(this.activityDate), 1),
            subType: [this.activityType]
        };
    }
    get isHardwareEmuWithInstalledHut() {
        if (!this.ahdData || !this.ahdData.subType || !this.ahdData.childItemInstances.length) {
            return false;
        }
        return (this.ahdData.subType === HardwareType.EMU &&
            this.ahdData.childItemInstances.find((child) => child.subType === HardwareType.HUT_ORU));
    }
    get isFormValid() {
        return Boolean(this.activityDate && this.activityType);
    }
    get isHardwareHutInstalledOnEmu() {
        if (!this.ahdData || !this.ahdData.subType || !this.ahdData.parentItemInstance) {
            return false;
        }
        return (this.ahdData.subType === HardwareType.HUT_ORU && this.ahdData.parentItemInstance.subType === HardwareType.EMU);
    }
    get loggingMultipleTypesOfHardwareMessage() {
        if (!(this.isHardwareHutInstalledOnEmu || this.isHardwareEmuWithInstalledHut)) {
            return false;
        }
        else {
            return this.isHardwareHutInstalledOnEmu
                ? `The activity being logged is for a HUT ORU installed on an EMU. Activities will be logged for
      both pieces of hardware.`
                : `The activity being logged is for an EMU with a HUT ORU installed on it. Activities will be logged for
      both pieces of hardware.`;
        }
    }
    get relatedActivities() {
        const activities = this.activities && this.hardwareType
            ? transformActivities(this.activities, this.hardwareType).sort(sortObjectBy('_activityDate', true))
            : [];
        const activitiesWithLink = activities.map((a) => ({
            ...a,
            _subType: formatTableValuesForHref(a._subType, `/activities/manage/${a.id}`)
        }));
        return activitiesWithLink;
    }
    get rowsPerPageItems() {
        return [
            50,
            100,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get shouldShowSubForm() {
        return Boolean(this.activityDate && this.activityType && !this.loadingFormFields);
    }
    get shouldShowNoAdditionalInfoAlert() {
        if (!this.activityType) {
            return false;
        }
        return (!this.activityFormPartials.length || !this.activityFormPartials.some((formPartial) => formPartial.fields.length));
    }
    buildQueryVariables() {
        const _qv = { ...this.filters };
        return _qv;
    }
    async fetchActivities() {
        if (!this.activityDate || !this.activityType) {
            return;
        }
        try {
            const resp = await this.$http.get(`/activities?${qs.stringify(this.buildQueryVariables())}`);
            this.activities = resp.data;
        }
        catch (err) {
            this.activities = null;
            ErrorUtility({
                err,
                backupErrorText: 'An error occurred during the fetching of data'
            });
        }
    }
    dismissRelatedActivitiesAlert() {
        this.activities = [];
    }
    onFormDataChange(val) {
        this.attributesForm = val;
    }
    async onClickOfCreate() {
        if (!this.activityDate || !this.activityType) {
            return;
        }
        const payload = {
            subType: this.activityType,
            activityDate: this.activityDate,
            isPlanning: this.isPlanning,
            itemInstances: [
                {
                    itemInstanceId: this.itemInstanceId,
                    activityAttributes: this.attributesForm.attributes
                }
            ]
        };
        try {
            let activityResponse = null;
            let associatedHardwareActivityResponse = null;
            // if this is HUT installed on an EMU
            if (this.isHardwareHutInstalledOnEmu) {
                activityResponse = await this.createActivity(payload);
                const activityId = activityResponse.data.id;
                associatedHardwareActivityResponse = await this.$http.put(`/activities/${activityId}/${HardwareType.EMU}/${this.ahdData.parentItemInstance.id}`, {});
            }
            else if (
            // if this is EMU with HUT installed on it
            this.isHardwareEmuWithInstalledHut) {
                activityResponse = await this.createActivity(payload);
                const activityId = activityResponse.data.id;
                const childHutOru = this.ahdData.childItemInstances.find((child) => child.subType === HardwareType.HUT_ORU);
                associatedHardwareActivityResponse = await this.$http.put(`/activities/${activityId}/${HardwareType.HUT_ORU}/${childHutOru.id}`, {});
            }
            else {
                activityResponse = await this.createActivity(payload);
            }
            let associatedHardwareItemInstanceId = null;
            // Separately add comments if there are any
            if (associatedHardwareActivityResponse) {
                associatedHardwareItemInstanceId = this.isHardwareHutInstalledOnEmu
                    ? this.ahdData.parentItemInstance.id
                    : this.ahdData.childItemInstances.find((child) => child.subType === HardwareType.HUT_ORU).id;
            }
            if (this.activityComments && activityResponse.data.id) {
                await this.createActivityComment(activityResponse.data.id, this.itemInstanceId);
                if (associatedHardwareActivityResponse) {
                    await this.createActivityComment(associatedHardwareActivityResponse?.data.id, associatedHardwareItemInstanceId);
                }
            }
            this.emitEntityCreated('Activity created.');
            this.closeDialog();
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    createActivity(payload) {
        return this.$http.post('/activities', payload);
    }
    createActivityComment(activityId, itemInstanceId) {
        const url = `/comments`;
        const payload = {
            activityId: activityId,
            commentText: this.activityComments,
            itemInstanceId: itemInstanceId,
            subType: CommentType.ACTIVITY
        };
        return this.$http.post(url, payload);
    }
    async onActivityTypeChange(activityType) {
        if (!activityType) {
            return;
        }
        this.loadingFormFields = true;
        this.activityFormPartials = await getActivityFormFields(this.hardwareType, activityType);
        this.attributesForm.attributes = {};
        this.loadingFormFields = false;
    }
    checkForSimilarActivities() {
        if (this.activityDate && this.hardwareType) {
            this.fetchActivities();
        }
        else {
            this.activities = null;
        }
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", Object)
], ModalActivity.prototype, "ahdData", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _a : Object)
], ModalActivity.prototype, "hardwareType", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], ModalActivity.prototype, "itemInstanceId", void 0);
__decorate([
    Prop({
        required: false,
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ModalActivity.prototype, "isPlanning", void 0);
__decorate([
    Watch('activityType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof ActivityType !== "undefined" && ActivityType) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], ModalActivity.prototype, "onActivityTypeChange", null);
__decorate([
    Watch('activityDate'),
    Watch('activityType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalActivity.prototype, "checkForSimilarActivities", null);
ModalActivity = __decorate([
    Component
], ModalActivity);
export default ModalActivity;
