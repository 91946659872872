var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { UserMixin } from '~/mixins/user/UserMixin';
import { Maybe } from '~/types';
let TheSessionTimeout = class TheSessionTimeout extends Mixins(UserMixin) {
    showSessionTimeout = false;
    get currentUser() {
        return this.user;
    }
    onUserChange(val) {
        this.showSessionTimeout = val === null;
    }
    onClickOfReload() {
        window.location.reload();
    }
};
__decorate([
    Watch('currentUser', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TheSessionTimeout.prototype, "onUserChange", null);
TheSessionTimeout = __decorate([
    Component
], TheSessionTimeout);
export default TheSessionTimeout;
