import { DEFAULT_DASH } from '.';
import { CREWMEMBER_ASSIGNED_COL, STANDARD_COLS, TAPE_INSTALL_DATE_COL } from '../genericTableColumns';
import { addAttributeDateDisplays, addBaseProperties, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const HAB_HAP_E_TABLE_HEADERS = [
    ...STANDARD_COLS,
    CREWMEMBER_ASSIGNED_COL,
    TAPE_INSTALL_DATE_COL
];
export const ACTIVITY_HAB_HAP_E_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS, CREWMEMBER_ASSIGNED_COL];
export const transformHabHapE = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        .map((item) => {
        const crewmemberAssigned = item?.status?.crewmemberAssigned?.value ?? DEFAULT_DASH;
        item._crewmemberAssigned = crewmemberAssigned;
        return item;
    });
};
