import { format } from 'date-fns';
import { dateDisplay, dateTimeDisplay, fromNow } from '~/utils';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $dateDisplay: { value: dateDisplay },
            $dateTimeDisplay: { value: dateTimeDisplay },
            $formatDate: { value: format },
            $fromNow: { value: fromNow }
        });
    }
};
