import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ButtonDelete = class ButtonDelete extends Vue {
    disabled;
    isRemoving;
    onClick() {
        this.$emit('click');
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonDelete.prototype, "disabled", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonDelete.prototype, "isRemoving", void 0);
ButtonDelete = __decorate([
    Component
], ButtonDelete);
export default ButtonDelete;
