import { EvaReadinessDisplay } from '~/types';
import { EvaReadiness } from '~/types/schemas';
import { CosmicIcons } from './CosmicIcons';
export const buildIconAsString = (options) => {
    if (!options) {
        return '';
    }
    const iconClass = options.iconClass || '';
    const _color = options.color || 'default';
    const iconColor = _color.endsWith('--text') ? _color : `${_color}--text`;
    return `
   <i class="${iconClass} ${iconColor}" title="${options.title || ''}" tooltip="${options.title || ''}" style="${options.style}"></i>
  `;
};
export const buildIconWithLabelAsString = (options) => {
    if (!options) {
        return '';
    }
    const iconClass = options.iconClass || '';
    const _color = options.color || 'default';
    const iconColor = _color.endsWith('--text') ? _color : `${_color}--text`;
    const justifyClass = options.labelAlignment === 'center' ? '' : 'side_by_side_icon';
    return `
  <div class="monospace_font d-flex align-center justify-center ${justifyClass}" style="flex-direction: column;">
    <span class="text-xs-right">
      <i class="text-xs-left pr-1 ${iconClass} ${iconColor}" title="${options.title || ''} tooltip="${options.title || ''}" style="${options.style}">
      </i>
    </span>
    <small class="font-weight-bold text-xs-left pl-1">${options.label}</small>
  </div>
  
  `;
};
export const buildEvaReadinessIconAsString = (evaReadiness, labelAlignment = 'center') => {
    // Get color based on status
    let iconColorFromEvaReadiness = 'grey';
    switch (evaReadiness) {
        case EvaReadiness.BACKUP_USE_ONLY:
        case EvaReadiness.PENDING_ANALYSIS:
        case EvaReadiness.REQUIRES_INSPECTION:
            iconColorFromEvaReadiness = 'grey';
            break;
        case EvaReadiness.GO_FOR_EVA:
            iconColorFromEvaReadiness = 'success';
            break;
        case EvaReadiness.NO_GO_FOR_EVA:
            iconColorFromEvaReadiness = 'error';
            break;
        default:
            iconColorFromEvaReadiness = 'default';
    }
    // Get title
    const title = evaReadiness && EvaReadinessDisplay.get(evaReadiness)
        ? `Readiness: ${EvaReadinessDisplay.get(evaReadiness)}`
        : 'No Readiness set';
    // Get icon
    const icon = evaReadiness ? CosmicIcons[evaReadiness] : CosmicIcons.help;
    const opts = {
        color: iconColorFromEvaReadiness,
        iconClass: `${icon} text--darken-2`,
        label: evaReadiness ? EvaReadinessDisplay.get(evaReadiness) || 'No Readiness set' : 'No Readiness set',
        labelAlignment,
        title
    };
    return buildIconWithLabelAsString(opts);
};
export const buildOkIcon = (title = 'Status - OK') => {
    return buildIconAsString({
        iconClass: `${CosmicIcons['circle_check']} text--darken-2`,
        title,
        color: 'success--text'
    });
};
export const buildNotOkIcon = (title = 'Status - NOT OK') => {
    return buildIconAsString({
        iconClass: 'fad fa-ba text--darken-2',
        title,
        color: 'error--text'
    });
};
