var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { HardwareType, HardwareTypeDisplay, IHardware } from '~/types';
import { sortObjectBy } from '~/utils';
import { READ_ONLY_HARDWARE_TYPES } from '~/utils/constants/index';
import { getHardwareStatusPropertiesForAhd, transformHardwaresToTableFormat } from '~/utils/helpers/transformHardwaresToTableFormat';
import { default as AKA_LIST } from './knownWords';
const omittedDataValues = [
    '_asBuiltNumber',
    '_barcode',
    '_isArchivedIcon',
    '_isInstalledIcon',
    '_launchEventTitle',
    '_location',
    '_locationIcon',
    '_returnEventTitle',
    '_serial',
    '_size',
    '_statusIcon',
    '_subType',
    'drawingNumber',
    'isInstalled',
    'isLlb2',
    'megaUnit',
    'evaReadiness',
    'metoxRegenerationDueDate'
];
// These are specifically for attributes
// Matching values are still valid `status` properties
// This is helpful when an attribute and a status share the same name and you only want to display one
const omittedAttributeValues = ['returnDueDate'];
let AppHardwareDisplay = class AppHardwareDisplay extends Vue {
    HardwareTypeDisplay = HardwareTypeDisplay;
    READ_ONLY_HARDWARE_TYPES = READ_ONLY_HARDWARE_TYPES;
    showModalEditHardware = false;
    data;
    showEditButton;
    hideBorderBottom;
    get isArchived() {
        return Boolean(this.data?.isArchived);
    }
    get itemInstanceId() {
        return this.data?.id || null;
    }
    get itemInstanceManageUrl() {
        if (!this.itemInstanceId || !this.subType) {
            return null;
        }
        return `/hardware/${this.subType.toLowerCase()}/manage/${this.itemInstanceId}`;
    }
    get hardwareTypeIsReadOnly() {
        if (!this.subType) {
            return false;
        }
        return this.READ_ONLY_HARDWARE_TYPES.includes(this.subType);
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    get hasSize() {
        return this.data?.subType === HardwareType.EMU || this.data?.subType === HardwareType.HUT_ORU;
    }
    get shouldShowEditButton() {
        return this.showEditButton;
    }
    get shouldHidePastDueAlerts() {
        // currently hiding for one off metox ii sv821799 ab 04-00 serial 6000
        return (this.data?.asBuiltNumber === '04-00' &&
            this.data?.drawingNumber === 'SV821799' &&
            this.data?.serialNumber === '0006');
    }
    get subType() {
        return this.data?.subType;
    }
    get subTypeUrl() {
        if (!this.subType) {
            return null;
        }
        return `/hardware/${this.subType}`;
    }
    get transformedSelectedItemInstance() {
        if (!this.data || !this.subType) {
            return null;
        }
        return transformHardwaresToTableFormat(this.subType, [this.data])[0];
    }
    /**
     * Returns all `this.transformedSelectedItemInstance.attributes`; removing ommited items
     */
    get usableDataAttributes() {
        return Object.keys(this.transformedSelectedItemInstance?.attributes || {}).filter((attr) => !omittedDataValues.includes(attr) && !omittedAttributeValues.includes(attr));
    }
    get usableDataAttributeDisplays() {
        return this.usableDataAttributes
            .map((attributeName) => {
            return {
                text: this.cleanDataPointName(attributeName),
                value: this.$get(this.transformedSelectedItemInstance, `_${attributeName}`) ||
                    this.$get(this.transformedSelectedItemInstance?.attributes, attributeName),
                noHtmlText: this.cleanDataPointName(attributeName, true)
            };
        })
            .sort(sortObjectBy('text'));
    }
    /**
     * Returns all `this.data.status` attributes; removing those explicitly omitted by value
     */
    get usableDataStatuses() {
        return getHardwareStatusPropertiesForAhd(this.data).filter((attr) => !omittedDataValues.includes(attr));
    }
    get usableDataStatusDueDateDisplays() {
        if (!this.transformedSelectedItemInstance) {
            return [];
        }
        return this.usableDataStatuses
            .filter((dataStatus) => this.transformedSelectedItemInstance[`_${dataStatus}`])
            .filter((dataStatus) => dataStatus.endsWith('DueDate') || dataStatus.includes('expiration'))
            .map((attributeName) => {
            return {
                text: this.cleanDataPointName(attributeName),
                value: this.transformedSelectedItemInstance[`_${attributeName}`],
                noHtmlText: this.cleanDataPointName(attributeName, true)
            };
        })
            .sort(sortObjectBy('text'));
    }
    get usableDataStatusOtherDisplays() {
        if (!this.transformedSelectedItemInstance) {
            return [];
        }
        return this.usableDataStatuses
            .filter((dataStatus) => this.transformedSelectedItemInstance[`_${dataStatus}`])
            .filter((dataStatus) => !dataStatus.endsWith('DueDate') && !dataStatus.includes('expiration')) // notable short coming, not grabbing things like percent bc those change names
            .map((attributeName) => {
            return {
                text: this.cleanDataPointName(attributeName),
                value: this.transformedSelectedItemInstance[`_${attributeName}`],
                noHtmlText: this.cleanDataPointName(attributeName, true)
            };
        })
            .sort(sortObjectBy('text'));
    }
    // this is used to hide a Metox alert for one sv821799 ii being sunsetted
    transformHeaderToHideAlerts(headerValue) {
        return headerValue.replace('fad fa-exclamation fa-sm alert-status error--text', '').replace('error--text', '');
    }
    vSafeHtml(headerValue) {
        return this.shouldHidePastDueAlerts ? this.transformHeaderToHideAlerts(headerValue) : headerValue;
    }
    entityEdited() { }
    cleanDataPointName(str, stripHtml = false) {
        str = str[0].toUpperCase() + str.substring(1);
        Array.from(AKA_LIST.entries()).forEach(([wordToReplace, slugToReplaceItWith]) => {
            str = str.replace(wordToReplace, slugToReplaceItWith);
        });
        if (stripHtml) {
            return str.replace(/<[^>]*>?/gm, '');
        }
        return str;
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof IHardware !== "undefined" && IHardware) === "function" ? _a : Object)
], AppHardwareDisplay.prototype, "data", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "showEditButton", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "hideBorderBottom", void 0);
__decorate([
    Emit('entity-edited'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppHardwareDisplay.prototype, "entityEdited", null);
AppHardwareDisplay = __decorate([
    Component
], AppHardwareDisplay);
export default AppHardwareDisplay;
