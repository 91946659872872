var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActivityTypeDisplay, BaseActivity, EntityType, HardwareType } from '~/types';
import { transformActivities } from '~/utils';
let ActivityMini = class ActivityMini extends Vue {
    entityType = EntityType.ACTIVITY;
    // public manageIcon = this.$icons['part_tool'];
    activityData;
    hardwareSubType;
    get subType() {
        if (!this.activityData?.subType) {
            return;
        }
        return this.activityData && this.activityData.subType ? ActivityTypeDisplay.get(this.activityData.subType) : '-';
    }
    get computedIcon() {
        return this.$icons[this.entityType];
    }
    get verticalLabelDisplayItems() {
        if (!this.activityData) {
            return [];
        }
        let items = [];
        // passing hardwareSubType in case we want to show more detailed vertical display items later
        const transformedActivity = transformActivities([this.activityData], this.hardwareSubType)[0];
        items = items.concat([
            {
                name: 'Last updated',
                value: transformedActivity._lastUpdateDate
            },
            {
                name: 'Last Update By',
                value: transformedActivity._lastUpdatedBy
            },
            {
                name: 'Activity date',
                value: transformedActivity._activityDate
            }
        ]);
        return items;
    }
};
__decorate([
    Prop({
        default: {},
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof BaseActivity !== "undefined" && BaseActivity) === "function" ? _a : Object)
], ActivityMini.prototype, "activityData", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _b : Object)
], ActivityMini.prototype, "hardwareSubType", void 0);
ActivityMini = __decorate([
    Component
], ActivityMini);
export default ActivityMini;
