var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { HardwareTypeDisplay, IEvaForm, Maybe } from '~/types';
import { COMMENT_TABLE_HEADERS, ErrorUtility, sortObjectBy, transformComments } from '~/utils';
let EvaForm = class EvaForm extends Vue {
    availableHardware = [];
    hardwareController = null;
    itemInstances = null;
    finishedFetchingOptions = false;
    selectedComment = null;
    displayedComments = []; // This local copy is for handling comment edits/deletes
    commentEditText = '';
    COMMENT_TABLE_HEADERS = COMMENT_TABLE_HEADERS;
    formData;
    isEditing;
    comments;
    get hardwareOptions() {
        if (!this.itemInstances) {
            return [];
        }
        return this.itemInstances
            .filter((ii) => !ii.isArchived) // discount double check
            .filter((ii) => ii.status?.isOnOrbit?.value)
            .map((ii) => ({
            displayText: `${HardwareTypeDisplay.get(ii.subType)}: ${ii.serialNumber}`,
            value: ii.id
        }))
            .sort(sortObjectBy('displayText'));
    }
    get commentTableItems() {
        return transformComments(this.displayedComments).sort(sortObjectBy('createdDate', true));
    }
    async mounted() {
        const { data } = await this.$http.get('/item-instances?includeArchived=false');
        this.itemInstances = data;
    }
    async onClickOfCommentUpdate() {
        await this.updateComment();
        // Don't trigger a reload because that will reset the rest of the form--just update the local copy
        const comment = this.displayedComments.find((c) => c.id === this.selectedComment?.id);
        comment.commentText = this.commentEditText;
        this.selectedComment = null;
    }
    async onClickOfCommentDelete() {
        await this.deleteComment();
        // Don't trigger a reload because that will reset the rest of the form--just update the local copy
        this.displayedComments = this.displayedComments.filter((c) => c.id !== this.selectedComment?.id);
        this.selectedComment = null;
    }
    onStartDateChange() {
        this.formData.endDate = this.formData.startDate;
    }
    onSelectedCommentChange() {
        this.commentEditText = this.selectedComment?.commentText ?? '';
    }
    OnCommentsChange() {
        this.displayedComments = this.comments ?? [];
    }
    updateComment() {
        if (!this.selectedComment) {
            return;
        }
        try {
            const url = `/comments/${this.selectedComment.id}`;
            const opts = {
                commentText: this.commentEditText
            };
            return this.$http.put(url, opts);
        }
        catch (err) {
            ErrorUtility({ err, backupErrorText: 'Failed to save comment changes.' });
        }
    }
    deleteComment() {
        if (!this.selectedComment) {
            return;
        }
        try {
            const url = `/comments/${this.selectedComment.id}`;
            return this.$http.delete(url);
        }
        catch (err) {
            ErrorUtility({ err, backupErrorText: 'Failed to delete comment.' });
        }
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof IEvaForm !== "undefined" && IEvaForm) === "function" ? _a : Object)
], EvaForm.prototype, "formData", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], EvaForm.prototype, "isEditing", void 0);
__decorate([
    Prop({
        default: () => [],
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], EvaForm.prototype, "comments", void 0);
__decorate([
    Watch('formData.startDate'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], EvaForm.prototype, "onStartDateChange", null);
__decorate([
    Watch('selectedComment'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], EvaForm.prototype, "onSelectedCommentChange", null);
__decorate([
    Watch('comments', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], EvaForm.prototype, "OnCommentsChange", null);
EvaForm = __decorate([
    Component
], EvaForm);
export default EvaForm;
