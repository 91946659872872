import { EntityType } from '~/types';
export const CosmicIcons = {
    reporting: 'fad fa-chart-line',
    // Entity Types
    [EntityType.ACTIVITY]: 'fad fa-stopwatch',
    [EntityType.ASSEMBLY]: 'fad fa-sitemap',
    [EntityType.ASSEMBLY_TEMPLATE]: 'far fa-map',
    [EntityType.ASSEMBLY_TRANSACTION]: 'fal fa-receipt',
    [EntityType.HARDWARE_LIST]: 'fad fa-map',
    [EntityType.HARDWARE_LIST_TEMPLATE]: 'far fa-map',
    [EntityType.CHANGE_SET]: 'fad fa-code-merge',
    [EntityType.COMMENT]: 'fad fa-comment-alt-lines',
    [EntityType.CONTACT]: 'fad fa-address-book',
    [EntityType.CONTRACT]: 'fad fa-file-contract',
    [EntityType.CREW]: 'fad fa-user-astronaut',
    [EntityType.DOCUMENT]: 'fad fa-file-alt',
    [EntityType.EVENT]: 'fad fa-calendar-alt',
    [EntityType.INVENTORY]: 'fad fa-th',
    [EntityType.ITEM_DRAWING]: 'fad fa-square',
    [EntityType.ITEM_INSTANCE]: 'fas fa-th',
    [EntityType.ITEM_MASTER]: 'fad fa-th-large',
    [EntityType.LOCATION]: 'fad fa-archive',
    [EntityType.MEASUREMENT]: 'fas fa-ruler-combined',
    [EntityType.ORGANIZATION]: 'fad fa-building',
    [EntityType.RESPONSIBLE_ORGANIZATION]: 'fad fa-landmark',
    [EntityType.USER_GROUP]: 'fad fa-users',
    [EntityType.USER]: 'fad fa-user',
    [EntityType.VEHICLE]: 'fad fa-rocket',
    EVA: 'fad fa-cat-space',
    FLIGHT: 'fad fa-rocket-launch',
    INCREMENT: 'fad fa-calendar-week',
    // [EventType.LAUNCH]: '',
    // [EventType.RETURN]: '',
    ON_ORBIT: 'fad fa-planet-moon',
    ON_GROUND: 'fad fa-globe-americas',
    // BatteryState
    CHARGED: 'fal fa-battery-full',
    DISCHARGED: 'fal fa-battery-slash',
    NOT_CHARGED: 'fal fa-battery-slash',
    PARTIAL: 'fal fa-battery-quarter',
    UNKNOWN: 'fal fa-question-circle',
    WETTED: 'fad fa-dewpoint',
    NON_WETTED: 'fal fa-cactus',
    BACKUP_USE_ONLY: 'fad fa-backpack',
    GO_FOR_EVA: 'fad fa-thumbs-up',
    NO_GO_FOR_EVA: 'fad fa-thumbs-down',
    PENDING_ANALYSIS: 'fad fa-head-side-brain',
    REQUIRES_INSPECTION: 'fad fa-file-search',
    // User Types
    ADMIN: 'fad fa-user-crown',
    USER: 'fad fa-user',
    SYSTEM: 'fad fa-solar-system',
    // AlertTypes
    success: 'fad fa-check-circle',
    warning: 'fad fa-exclamation-triangle',
    error: 'fad fa-exclamation',
    angle_left: 'fas fa-angle-left',
    angle_right: 'fas fa-angle-right',
    arrow_left: 'fad fa-long-arrow-alt-left',
    arrow_right: 'fad fa-long-arrow-alt-right',
    arrow_up: 'fad fa-long-arrow-alt-up',
    arrow_down: 'fad fa-long-arrow-alt-down',
    atom: 'fad fa-atom-alt',
    bolt: 'fad fa-bolt',
    browser: 'fad fa-browser',
    calendar: 'fad fa-calendar',
    calendar_day: 'fad fa-calendar-day',
    checklist: 'fad fa-clipboard-list-check',
    chevron_down: 'fa fa-chevron-down',
    chevron_up: 'fa fa-chevron-up',
    circle_check: 'fas fa-check-circle',
    circle_plus: 'fad fa-plus-circle',
    circle: 'fad fa-circle',
    clear: 'fad fa-times-circle',
    clone: 'fad fa-clone',
    close: 'fad fa-times-circle',
    cog: 'fad fa-cog',
    comparison: 'fad fa-columns',
    complete: 'fad fa-check',
    components_isTool: 'fad fa-wrench',
    cost: 'fad fa-dollar-sign',
    create_fat: 'fas fa-plus',
    create: 'fal fa-plus',
    download: 'fad fa-download',
    edit: 'fad fa-pencil',
    exclamation: 'fad fa-exclamation',
    expand: 'fas fa-angle-up',
    filter: 'fas fa-sm fa-filter',
    help: 'fad fa-question-circle',
    home: 'fad fa-home',
    info: 'fal fa-info',
    lil_edit: 'fad fa-pencil-alt caption',
    link: 'fad fa-link',
    loading: 'fad fa-spinner fa-spin',
    mail: 'fad fa-mail-bulk',
    mini: 'fad fa-rectangle-wide',
    minimize: 'fas fa-window-minimize',
    paperwork: 'fad fa-scroll',
    part_tool: 'fad fa-wrench',
    pipeline_approved: 'fad fa-check-circle',
    pipeline_connector: 'fas fa-arrow-right',
    pipeline_null: 'fad fa-minus-circle',
    pipeline_open: 'fas fa-circle-notch',
    pipeline_rejected: 'fad fa-times-circle',
    print_slash: 'fad fa-print-slash',
    print: 'fad fa-print',
    refresh: 'fad fa-sync',
    reload: 'fad fa-sync',
    reopen: 'fad fa-redo-alt',
    repeat: 'fad fa-repeat',
    report_bar: 'fad fa-chart-bar',
    report: 'fad fa-user-chart',
    save: 'fad fa-save',
    search: 'fad fa-search',
    select_all: 'fad fa-check-square',
    sort: 'px-1 fad fa-long-arrow-up',
    strong_close: 'fad fa-times-circle',
    table: 'fad fa-table',
    trash: 'fad fa-trash-alt',
    user_hat: 'fad fa-user-hard-hat'
};
