import axios from 'axios';
import qs from 'qs';
import { shallowDatePropertyIterator } from '~/utils/dates/shallowDatePropertyIterator';
const transformDates = (data, headers) => {
    if (data !== undefined) {
        // check data.attributes for any property that ends in __Date or __DateTime and append TZ information
        data.attributes = shallowDatePropertyIterator(data?.attributes);
        data = shallowDatePropertyIterator(data);
        headers['Content-Type'] = 'application/json';
        return JSON.stringify(data);
    }
    else {
        return data;
    }
};
const paramsSerializer = (params) => {
    return qs.stringify(params, {
        allowDots: true,
        arrayFormat: 'repeat'
    });
};
const axiosConfig = {
    withCredentials: true,
    transformRequest: [transformDates],
    paramsSerializer
};
export const axiosInstance = axios.create(axiosConfig);
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $http: { value: axiosInstance }
        });
    }
};
