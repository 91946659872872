var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { MaintenanceWindowPreset, Maybe } from '~/types';
import { buildDateRangeFromMaintenanceFilter } from '~/utils';
let Annex2ReportFilterSpan = class Annex2ReportFilterSpan extends Vue {
    MaintenanceWindowPreset = MaintenanceWindowPreset;
    maintenanceWindowInDays = null;
    presetDefault;
    includePastDue;
    value;
    get filter() {
        if (!this.maintenanceWindowInDays) {
            return null;
        }
        // "all upcoming" means all maintenance that needs to happen, starting today
        if (this.maintenanceWindowInDays === MaintenanceWindowPreset.ALL_UPCOMING) {
            return { startDate: this.$dateDisplay(new Date()), endDate: null };
        }
        // "all overdue" means all maintenance that should have already happened,
        // but easn't yet
        if (this.maintenanceWindowInDays === MaintenanceWindowPreset.ALL_OVERDUE) {
            return { startDate: null, endDate: this.$dateDisplay(new Date()) };
        }
        const range = buildDateRangeFromMaintenanceFilter(this.maintenanceWindowInDays);
        if (range && this.includePastDue) {
            range.startDate = null;
        }
        return range;
    }
    onPresetChange(preset) {
        this.maintenanceWindowInDays = preset;
    }
    // this watcher should probably check to see if the date range matches one of
    // this component's presets, but right now all we need to be able to do is
    // clear it out.
    onValueChange(value) {
        if (!value) {
            this.maintenanceWindowInDays = null;
            return;
        }
    }
    OnMaintenanceWindowInDaysChange(val) { }
};
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], Annex2ReportFilterSpan.prototype, "presetDefault", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], Annex2ReportFilterSpan.prototype, "includePastDue", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], Annex2ReportFilterSpan.prototype, "value", void 0);
__decorate([
    Watch('presetDefault', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], Annex2ReportFilterSpan.prototype, "onPresetChange", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], Annex2ReportFilterSpan.prototype, "onValueChange", null);
__decorate([
    Emit('input'),
    Watch('filter', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], Annex2ReportFilterSpan.prototype, "OnMaintenanceWindowInDaysChange", null);
Annex2ReportFilterSpan = __decorate([
    Component
], Annex2ReportFilterSpan);
export default Annex2ReportFilterSpan;
