import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/utils/base/BaseButton';
let ButtonExport = class ButtonExport extends BaseButton {
    title;
};
__decorate([
    Prop({
        default: 'Export to PDF',
        type: String
    }),
    __metadata("design:type", String)
], ButtonExport.prototype, "title", void 0);
ButtonExport = __decorate([
    Component
], ButtonExport);
export default ButtonExport;
