var _a, _b;
import { __decorate, __metadata } from "tslib";
import qs from 'qs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActivityTypeDisplay, Maybe } from '~/types';
import { sortObjectBy } from '~/utils';
let HardwarePlannedActivities = class HardwarePlannedActivities extends Vue {
    ActivityTypeDisplay = ActivityTypeDisplay;
    plannedActivities;
    subType;
    itemInstanceId;
    get queryString() {
        const qv = {};
        if (this.itemInstanceId) {
            qv.itemInstanceId = this.itemInstanceId;
        }
        if (this.subType) {
            qv.subType = this.subType;
        }
        return `?${qs.stringify(qv)}`;
    }
    get sortedActivities() {
        return this.plannedActivities.sort(sortObjectBy('activityDate'));
    }
    goToActivityManage(activity) {
        this.$router.push(`/activities/manage/${activity.id}`);
    }
};
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], HardwarePlannedActivities.prototype, "plannedActivities", void 0);
__decorate([
    Prop({
        required: false,
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], HardwarePlannedActivities.prototype, "subType", void 0);
__decorate([
    Prop({
        required: false,
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], HardwarePlannedActivities.prototype, "itemInstanceId", void 0);
HardwarePlannedActivities = __decorate([
    Component
], HardwarePlannedActivities);
export default HardwarePlannedActivities;
