import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserMixin } from '~/mixins/user/UserMixin';
import { HardwareType, HardwareTypeDisplay } from '~/types';
import { UserRole } from '~/types/schemas';
import { READ_ONLY_HARDWARE_TYPES, sortObjectBy } from '~/utils';
import { isNullOrUndefined } from '~/utils/helpers/isNullOrUndefined';
let TheSideNav = class TheSideNav extends Mixins(UserMixin) {
    menuFilterText = '';
    routes = Object.entries(HardwareType)
        .map((ootItem) => {
        const displayName = HardwareTypeDisplay.get(ootItem[0]);
        if (!displayName) {
            return null;
        }
        const to = `${ootItem[1]}`;
        return {
            displayName,
            to
        };
    })
        .filter((item) => {
        return !isNullOrUndefined(item);
    });
    value;
    get computedFilteredNavList() {
        const routes = this.menuFilterText
            ? this.routes.filter((route) => route.displayName.toLowerCase().includes(this.menuFilterText.toLowerCase()))
            : this.routes;
        return routes.sort(sortObjectBy('displayName'));
    }
    get showSettingsNavItem() {
        return this.user?.roles?.includes(UserRole.THRESHOLD_ALERT_ADMIN);
    }
    getCorrectColorForTag(item) {
        return this.isMenuActive(item) ? 'info lighten-4' : 'info darken-3';
    }
    hasReadOnlyTag(item) {
        return READ_ONLY_HARDWARE_TYPES.includes(HardwareType[item.to]);
    }
    metaNavigateToActivities() {
        window.open('/activities');
    }
    metaNavigateToAlertsManage() {
        window.open('/settings/manage_alerts');
    }
    metaNavigateToHardwareList(to) {
        window.open(`/hardware/${to}`);
    }
    metaNavigateHome() {
        window.open('/');
    }
    metaNavigateToEvents() {
        window.open('/events/manage');
    }
    metaNavigateToReporting() {
        window.open('/reporting');
    }
    navigateToActivities() {
        this.$router.push({ path: '/activities', query: {} });
    }
    navigateToAlertsManage() {
        this.$router.push({ path: '/settings/manage_alerts' });
    }
    navigateToEvents() {
        this.$router.push({ path: '/events/manage', query: {} });
    }
    navigateToHardwareList(to) {
        this.$router.push({
            path: `/hardware/${to}`
        });
    }
    navigateHome() {
        this.$router.push({ path: '/', query: {} });
    }
    navigateToReporting() {
        this.$router.push({ path: '/reporting', query: {} });
    }
    isMenuActive(item) {
        return this.$route.params.subType?.toLowerCase() === item.to.toLowerCase();
    }
    emitNavDrawerValue(val) { }
    onOpenStateChange(val) {
        if (val) {
            const sideNav = document.querySelector('.the_side_nav');
            const textEl = document.querySelector('.nav_filter input');
            sideNav.scrollTop = 0;
            textEl.focus();
        }
    }
};
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], TheSideNav.prototype, "value", void 0);
__decorate([
    Emit('sideNavValue'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "emitNavDrawerValue", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "onOpenStateChange", null);
TheSideNav = __decorate([
    Component
], TheSideNav);
export default TheSideNav;
