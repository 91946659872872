import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { EVA_READINESS_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, GENERIC_STATUS_ICON_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
const TAK_COLLECTION_DUE_DATE_COL = {
    text: 'TAK Data Collection due',
    value: '_takCollectionDueDate',
    sortable: true,
    noEscape: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
const TAK_APPROVAL_DUE_DATE_COL = {
    text: 'TAK Data Approval due',
    value: '_takApprovalDueDate',
    sortable: true,
    noEscape: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const PGT_TABLE_HEADERS = [
    ...STANDARD_COLS,
    TAK_COLLECTION_DUE_DATE_COL,
    TAK_APPROVAL_DUE_DATE_COL
];
export const FEMUR_PGT_TABLE_HEADERS = [
    SERIAL_COL,
    EVA_READINESS_COL,
    TAK_COLLECTION_DUE_DATE_COL,
    TAK_APPROVAL_DUE_DATE_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_PGT_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    {
        text: 'Role',
        value: '_role'
    },
    Object.assign({ ...GENERIC_STATUS_ICON_COL }, { text: 'EVA Readiness' }),
    {
        text: 'TAK Serial',
        value: '_takSerial',
        headerSortType: HeaderSortOptions.NUMBER
    }
];
export const transformPgt = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map(addFemurComments)
        .map(addStatusDateDisplays)
        .map((item) => {
        const status = item.status;
        let _takCollectionDueDate = dateDisplay(status?.takDataCollectionDueDate?.value);
        _takCollectionDueDate = alertableStatusDisplay(status?.takDataCollectionDueDate, _takCollectionDueDate);
        let _takApprovalDueDate = dateDisplay(status?.takDataApprovalDueDate?.value);
        _takApprovalDueDate = alertableStatusDisplay(status?.takDataApprovalDueDate, _takApprovalDueDate);
        // alerts based on status
        const alerts = [
            status?.takDataCollectionDueDate?.alertType,
            status?.takDataApprovalDueDate?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _takCollectionDueDate,
            _takApprovalDueDate
        });
        return _item;
    });
};
