import { dateDisplay } from '~/utils';
/**
 * Returns an object in the shape of DatetimeRangeInput.
 *
 * @param fromDate beginning of date range
 * @param toDate end of date range
 * @param useDefaults whether to use default dates instead of null
 */
export const getDateRangeInputObject = (fromDate, toDate, useDefaults = false) => {
    if (useDefaults) {
        // default the fromDate to something really old if needed
        fromDate = fromDate || dateDisplay(new Date('1970-1-1'));
        // default the toDate to now if needed
        toDate = toDate || dateDisplay(new Date());
    }
    // build the date range object
    return {
        start: { value: fromDate || null, inclusive: true },
        end: { value: toDate || null, inclusive: true }
    };
};
