var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ActionTypes, StateTypes, UserModuleName } from '~/store/user';
import { Maybe } from '~/types';
const userModule = namespace(UserModuleName);
let UserMixin = class UserMixin extends Vue {
    user;
    authStateChecked;
    missingNamsApproval;
    isLoadingUser;
    fetchUser;
};
__decorate([
    userModule.State(StateTypes.user),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], UserMixin.prototype, "user", void 0);
__decorate([
    userModule.State(StateTypes.authStateChecked),
    __metadata("design:type", Boolean)
], UserMixin.prototype, "authStateChecked", void 0);
__decorate([
    userModule.State(StateTypes.missingNamsApproval),
    __metadata("design:type", Boolean)
], UserMixin.prototype, "missingNamsApproval", void 0);
__decorate([
    userModule.State(StateTypes.isLoadingUser),
    __metadata("design:type", Boolean)
], UserMixin.prototype, "isLoadingUser", void 0);
__decorate([
    userModule.Action(ActionTypes.fetchUser),
    __metadata("design:type", Function)
], UserMixin.prototype, "fetchUser", void 0);
UserMixin = __decorate([
    Component
], UserMixin);
export { UserMixin };
