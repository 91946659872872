import { add } from 'date-fns';
import { MaintenanceCycleDisplay } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { numberDisplay, sortObjectBy } from '../helpers';
import { addBaseProperties } from '../mappers';
export const buildDateRangeFromMaintenanceFilter = (dayRange) => {
    try {
        if (!dayRange) {
            return null;
        }
        const today = new Date();
        const numDayRange = parseInt(dayRange, 10);
        const endDate = add(today, {
            days: numDayRange
        });
        return { endDate: dateDisplay(endDate), startDate: dateDisplay(today) };
    }
    catch (error) {
        return null;
    }
};
export const MAINTENANCE_SUMMARY_TABLE_COLS = [
    {
        text: 'Days<br/>remaining',
        value: '_remaining',
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right',
        width: '100px',
        sortable: true
    },
    {
        text: 'Maintenance due /<br/>expiry',
        value: '_maintenanceDueExpiration',
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE,
        sortable: true
    },
    {
        text: 'Limit<br/>remaining',
        value: '_limitRemaining',
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right',
        width: '100px',
        sortable: true
    },
    {
        text: 'Limit',
        value: '_maintenanceLimit',
        noWrap: true,
        headerSortType: HeaderSortOptions.NUMBER,
        sortable: true
    },
    {
        text: 'Maintenance<br/>type',
        value: '_maintenanceType',
        sortable: true,
        noEscape: true
    },
    {
        text: 'Hardware type',
        value: '_subType',
        sortable: true
    },
    {
        text: 'Serial',
        value: '_serial',
        headerSortType: HeaderSortOptions.NUMBER,
        sortable: true
    }
];
export const transformPendingMaintenance = (items) => {
    // each item that gets passed into this transform has an array of maintenance
    // items. so we have to reduce it so that we end up with one big array of
    // maintenance items.
    const maintenanceItems = items.reduce((maintItems, item, i) => {
        const newMaintItems = item.pendingMaintenance?.map((pendingMaintenance, j) => {
            // this frankenshape will make it easier to use standard hardware mappers
            return {
                id: `${i}${j}`,
                itemInstance: item.itemInstance,
                pendingMaintenance
            };
        }) ?? [];
        return maintItems.concat(newMaintItems);
    }, []);
    const displayItems = maintenanceItems
        .map(addBaseProperties)
        // extra typing required because we just added the base properties in the previous mapper
        .map((item) => {
        const maintenanceType = item.pendingMaintenance.maintenanceCycle;
        const _maintenanceType = maintenanceType
            ? MaintenanceCycleDisplay.get(maintenanceType) ?? '-'
            : item.pendingMaintenance.description ?? '-';
        const display = getMaintenanceStatusDisplay(item);
        return {
            _remaining: display.daysRemaining,
            _maintenanceDueExpiration: display.maintenanceDue,
            _limitRemaining: display.limitRemaining,
            _maintenanceLimit: display.limit,
            _maintenanceType,
            ...item
        };
    });
    return displayItems.sort(sortObjectBy('pendingMaintenance.daysRemaining'));
};
const isDateBased = ({ pendingMaintenance }) => {
    // Assumption is that only dates will have a string `value`
    return typeof pendingMaintenance.value === 'string';
};
const getMaintenanceStatusDisplay = (item) => {
    const { pendingMaintenance } = item;
    const { value, requirementRemaining, requirementValue, requirementUnit } = pendingMaintenance;
    if (isDateBased(item)) {
        const daysRemaining = numberDisplay(Math.ceil(requirementRemaining ?? 0));
        return {
            daysRemaining: `${daysRemaining}d`,
            maintenanceDue: dateDisplay(value),
            limit: '-',
            limitRemaining: '-'
        };
    }
    // Round the number of days to a whole number
    const valueDisplay = requirementUnit === 'days' ? Math.floor(value) : value;
    const requirementRemainingDisplay = requirementUnit === 'days' ? Math.ceil(requirementRemaining ?? 0) : value;
    return {
        daysRemaining: '-',
        maintenanceDue: '-',
        limit: `${valueDisplay} of ${requirementValue} ${requirementUnit}`,
        limitRemaining: `${requirementRemainingDisplay?.toString() ?? ' - '}  ${requirementUnit}`
    };
};
