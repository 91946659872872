/**
 * Returns a string representation of a number with preserved leading zeroes.
 * Whatever the stringLength is, numPad will append zeroes minus the length of the
 * provided number length.
 *
 * Example: numPad(6, 4) // "0006"
 *          numPad(10, 4) // "0010"
 */
/**
 * @param  {string|number} numberAsString
 * @param  {number} stringLength
 */
export function numPad(numberAsString, stringLength) {
    numberAsString = numberAsString.toString();
    while (numberAsString.length < stringLength)
        numberAsString = '0' + numberAsString;
    return numberAsString;
}
