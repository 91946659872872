/* unplugin-vue-components disabled */import __unplugin_components_6 from '/app/src/components/ReadOnlyComments/ReadOnlyComments.vue';
import __unplugin_components_5 from '/app/src/components/buttons/ButtonReadOnly/ButtonReadOnly.vue';
import __unplugin_components_4 from '/app/src/components/AppTable/AppTable.vue';
import __unplugin_components_3 from '/app/src/components/EventMini/EventMini.vue';
import __unplugin_components_2 from '/app/src/components/AppHeader/AppHeader.vue';
import __unplugin_components_1 from '/app/src/components/Alert/Alert.vue';
import __unplugin_components_0 from '/app/src/components/AppTable/AppTable.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(__unplugin_components_0,{staticClass:"mb-3",attrs:{"headers":_vm.itemInstanceTableHeaders,"items":_vm.associatedHardware,"color":"item_instances","selectable-rows":""},on:{"rowClicked":_vm.onClickOfHardwareRow},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Associated Hardware ( "),_c('small',[_c('strong',[_vm._v(_vm._s(_vm.associatedHardware.length))])]),_vm._v(" ) ")]},proxy:true}])}),(_vm.increments.length === 0)?_c(__unplugin_components_1,{attrs:{"type":"info"}},[_vm._v(" This EVA does not fall within any known Increment date ranges. ")]):_vm._e(),(_vm.increments.length === 1)?_c(__unplugin_components_2,{attrs:{"size":"mini"}},[_vm._v("Computed Increment")]):_vm._e(),(_vm.increments.length === 1)?_c(__unplugin_components_3,{attrs:{"event-data":_vm.increments[0],"event-type":_vm.increments[0].subType}}):_vm._e(),(_vm.increments.length > 1)?_c(__unplugin_components_4,{staticClass:"mb-3",attrs:{"headers":_vm.incrementTableHeaders,"items":_vm.increments,"color":"events"},on:{"rowClicked":_vm.onClickOfIncrementRow},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Computed Increment")]},proxy:true}],null,false,2809119629)}):_vm._e(),(_vm.increments.length === 1)?_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"text-xs-right",attrs:{"xs12":""}},[_c(__unplugin_components_5,{attrs:{"event":_vm.increments[0]}})],1)],1):_vm._e(),_c(__unplugin_components_6,{attrs:{"comments":_vm.comments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }