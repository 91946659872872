import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { BATTERY_VOLTAGE_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay, percentageDisplay } from '../helpers';
import { addBaseProperties, addFemurComments, addInstalledChildren, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const SAFER_TABLE_HEADERS = [
    ...STANDARD_COLS,
    {
        text: 'GN2',
        value: '_gn2',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right',
        noEscape: true
    },
    {
        text: 'PWR',
        value: '_pwr',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right',
        noEscape: true
    },
    BATTERY_VOLTAGE_COL,
    {
        text: 'Safer TM CheckOut required',
        value: '_saferCheckOutRequiredDueDate',
        sortable: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    }
];
export const FEMUR_SAFER_TABLE_HEADERS = [
    SERIAL_COL,
    {
        text: 'Test Module CheckOut<br/>due<br/><small>(every 2y)</small>',
        value: '_checkoutDueDate',
        sortable: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Test Module CheckOut<br/>Last Actual<br/><small>(every 2y)</small>',
        value: '_lastActualCheckoutDate',
        sortable: true,
        headerSortType: HeaderSortOptions.DATE
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_SAFER_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    { text: 'On Time', value: '_onTimeInterval' },
    {
        text: 'GN2',
        value: '_gn2',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right'
    },
    {
        text: 'PWR',
        value: '_pwr',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right'
    },
    BATTERY_VOLTAGE_COL
];
export const transformSafer = (items) => {
    return items
        .map(addLaunchReturn)
        .map(addBaseProperties)
        .map(addStatusDateDisplays)
        .map(addInstalledChildren)
        .map((item) => {
        const status = item.status;
        let _batteryVoltage = status?.lastBatteryVolts?.value ? `${status.lastBatteryVolts.value}V` : '-';
        _batteryVoltage = alertableStatusDisplay(status.lastBatteryVolts, _batteryVoltage);
        const saferTmCheckOutDueDate = status?.saferTmCheckOutDueDate?.value;
        const _saferCheckOutRequiredDueDate = dateDisplay(saferTmCheckOutDueDate);
        let _gn2 = percentageDisplay(status?.lastGn2Percent?.value);
        _gn2 = alertableStatusDisplay(status?.lastGn2Percent, _gn2);
        let _pwr = percentageDisplay(status?.lastPwrPercent?.value);
        _pwr = alertableStatusDisplay(status?.lastPwrPercent, _pwr);
        // alerts based on status
        const alerts = [
            status?.lastGn2Percent?.alertType,
            status?.lastPwrPercent?.alertType,
            status?.lastBatteryVolts?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        return Object.assign({}, item, {
            rowClass,
            _batteryVoltage,
            _checkoutDueDate: dateDisplay(saferTmCheckOutDueDate),
            _saferCheckOutRequiredDueDate,
            _gn2,
            _lastActualCheckoutDate: dateDisplay(item.status?.lastSaferTmCheckOutDate?.value),
            _pwr
        });
    })
        .map(addFemurComments);
};
