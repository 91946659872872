// Register components you would like to globally be accessible here
import Vue from 'vue';
// Example:
// import HelloWorld from "./HelloWorld.vue";
// Vue.component("HelloWorld", HelloWorld);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const req = require.context('./', true, /\.(vue)$/i);
for (const key of req.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, req(key).default);
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const formPartials = require.context('./formPartials', true, /\.(vue)$/i);
for (const key of formPartials.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, formPartials(key).default);
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const inputs = require.context('./inputs', true, /\.(vue)$/i);
for (const key of inputs.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, inputs(key).default);
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const buttons = require.context('./buttons', true, /\.(vue)$/i);
for (const key of buttons.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, buttons(key).default);
}
// For the vue_ui folder
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// const vueUi = require.context('../nasa_ui', true, /\.(vue)$/i);
// for (const key of vueUi.keys()) {
//   const name = key.match(/\w+/)![0];
//   Vue.component(name, vueUi(key).default);
// }
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// const vueUiButtons = require.context('../nasa_ui/buttons', true, /\.(vue)$/i);
// for (const key of vueUiButtons.keys()) {
//   const name = key.match(/\w+/)![0];
//   Vue.component(name, vueUiButtons(key).default);
// }
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// const vueUiInputs = require.context('../nasa_ui/inputs', true, /\.(vue)$/i);
// for (const key of vueUiInputs.keys()) {
//   const name = key.match(/\w+/)![0];
//   Vue.component(name, vueUiInputs(key).default);
// }
