import { __decorate, __metadata } from "tslib";
import BaseModal from '../base/BaseModal';
import { AppDynamicWrapperType } from '../AppDynamicWrapper/AppDynamicWrapper';
import { Component, Prop, Emit } from 'vue-property-decorator';
/**
 * https://vuetifyjs.com/en/components/dialogs#usage
 *
 * AVAILABLE PROPS:
 *  autoOpen - true
 *  modal - true
 *
 */
let AreYouSureDialog = class AreYouSureDialog extends BaseModal {
    AppDynamicWrapperType = AppDynamicWrapperType;
    // This disables the Yes button. Not sure why you would need to do that but it
    // was on this modal previous to the AppModal refactor, so I'm leaving it here.
    disabled;
    get computedTitle() {
        return this.title || 'Are you sure?';
    }
    onClickOfYes() {
        this.emitModalClose();
    }
    onClickOfNo() {
        this.emitModalClose();
    }
};
__decorate([
    Prop({
        type: String,
        default: 'warning darken-3'
    }),
    __metadata("design:type", String)
], AreYouSureDialog.prototype, "color", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AreYouSureDialog.prototype, "disabled", void 0);
__decorate([
    Prop({
        default: '450px',
        type: String
    }),
    __metadata("design:type", String)
], AreYouSureDialog.prototype, "modalWidth", void 0);
__decorate([
    Emit('affirmativeClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AreYouSureDialog.prototype, "onClickOfYes", null);
__decorate([
    Emit('negativeClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AreYouSureDialog.prototype, "onClickOfNo", null);
AreYouSureDialog = __decorate([
    Component
], AreYouSureDialog);
export default AreYouSureDialog;
