import { createDbDate } from '.';
const _isDateString = (property) => {
    const DATE_PROPERTIES = ['DateTime', 'Date'];
    return DATE_PROPERTIES.some((val) => property.endsWith(val));
};
const _addTimeZoneToDateProperties = (obj) => {
    Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === 'object') {
            _addTimeZoneToDateProperties(obj[key]);
        }
        else {
            if (_isDateString(key) && obj[key]) {
                obj[key] = createDbDate(obj[key]);
            }
        }
    });
    return obj;
};
export const shallowDatePropertyIterator = (target) => {
    for (const key in target) {
        if (target[key] && typeof target[key] === 'string') {
            _addTimeZoneToDateProperties(target);
        }
        if (target[key] && typeof target[key] === 'object') {
            target[key] = _addTimeZoneToDateProperties(target[key]);
        }
    }
    return target;
};
