import { Vue } from 'vue-property-decorator';
import { ErrorUtility } from '~/utils';
export default class BasePDFExport extends Vue {
    /**
     * Notifies the user using the standard Toast control
     *
     * @param messageOverride string
     */
    notifyUser(messageOverride) {
        ErrorUtility({
            err: new Error(),
            backupErrorText: messageOverride || 'An error occurred during the creation of the PDF. Please refresh.'
        });
    }
}
