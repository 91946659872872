export const stripUnderscorePropsFromObject = (item) => {
    const _clone = Object.assign({}, item);
    Object.keys(_clone).forEach(key => {
        if (key === '__typename') {
            return;
        }
        if (!key.startsWith('_')) {
            if (_clone[key] === null || typeof _clone[key] === 'boolean') {
                return;
            }
            if (typeof _clone[key] === 'object' && !Array.isArray(_clone[key])) {
                _clone[key] = stripUnderscorePropsFromObject(_clone[key]);
            }
            return;
        }
        const newKey = key.substring(key.lastIndexOf('_') + 1);
        _clone[newKey] = _clone[key];
        delete _clone[key];
        if (_clone[newKey] === null || typeof _clone[newKey] === 'boolean') {
            return;
        }
        if (typeof _clone[newKey] === 'object' && !Array.isArray(_clone[newKey])) {
            return stripUnderscorePropsFromObject(_clone[newKey]);
        }
    });
    return _clone;
};
