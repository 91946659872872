import { __decorate, __metadata } from "tslib";
import qs from 'qs';
import { Component, Watch } from 'vue-property-decorator';
import { AlertType } from '~/types';
import { ErrorUtility } from '../ErrorUtility';
import BaseView from './BaseView';
let BaseReportView = class BaseReportView extends BaseView {
    isSearching = false;
    queryResult = null;
    queryVariables = {};
    reportNameForUrl = '';
    showReportResults = false;
    reportName = '';
    get breadcrumbs() {
        return [
            {
                text: 'Reports',
                to: '/reporting?go'
            },
            {
                text: this.$route?.meta?.name,
                disabled: true
            }
        ];
    }
    get disableSearchButton() {
        return this.isSearching;
    }
    get rowsPerPageItems() {
        return [
            50,
            100,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get shouldShowReportResults() {
        return !!(this.queryResult !== null && this.showReportResults);
    }
    onClickOfBackToFilters() {
        this.showReportResults = false;
        this.queryResult = null;
        if (this.$route.query.hasOwnProperty('autoExecute')) {
            const query = Object.assign({}, this.$route.query);
            delete query.autoExecute;
            this.$router.replace({
                query: query
            });
        }
    }
    onClickOfRunReport() {
        if (this.disableSearchButton) {
            return;
        }
        this.setQueryString();
        this.runReport();
    }
    onClickOfShareLink() {
        const fullUrl = this.buildSharableLink();
        if (!fullUrl) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Copy to clipboard failed.'
            });
            return;
        }
        this.copyToClipboard(fullUrl);
    }
    async runReport(vars) {
        try {
            this.isSearching = true;
            // Example response
            const resp = await { errors: [] };
            this.isSearching = false;
            if (resp.errors) {
                this.showReportResults = false;
                throw resp.errors;
            }
            // Should set query result
            // this.queryResult = resp.data.blah?.nodes as SomethingFragment[];
            this.showReportResults = true;
        }
        catch (err) {
            this.isSearching = false;
            this.showReportResults = false;
            ErrorUtility({
                err,
                backupErrorText: 'An error occurred during the fetching of data'
            });
        }
    }
    setQueryString() {
        const qs = Object.assign({}, this.queryVariables);
        try {
            const routeQs = this.buildQueryVariables(this.$route.query);
            const sRouteQs = JSON.stringify(routeQs);
            const enRouteQs = btoa(sRouteQs);
            const localQv = Object.assign({}, this.queryVariables);
            const sLocalQv = JSON.stringify(localQv);
            const enLocalQv = btoa(sLocalQv);
            if (enRouteQs === enLocalQv) {
                return;
            }
            this.$router
                .push({
                query: qs
            })
                .catch((err) => { });
        }
        catch (err) {
            ErrorUtility({
                err,
                backupErrorText: 'Failed to update URL with report filters.'
            });
        }
    }
    onQueryStringChange(val) {
        this.$set(this, 'queryVariables', this.buildQueryVariables(val));
        if (this.$route.query.hasOwnProperty('autoExecute')) {
            this.runReport();
        }
    }
    buildQueryVariables(val) {
        const _qv = {};
        // Logic regarding if things exist
        return _qv;
    }
    buildSharableLink() {
        const slug = `/reporting/${this.reportNameForUrl}?`;
        if (this.queryVariables) {
            const qv = Object.assign({}, this.buildQueryVariables(), { autoExecute: true });
            const queryString = qs.stringify(qv, { indices: false });
            return `${window.location.origin}${slug}${queryString}`;
        }
        return null;
    }
    copyToClipboard(whatToCopy) {
        navigator.clipboard.writeText(whatToCopy).then(() => {
            this.$notification.add({
                type: AlertType.INFO,
                text: 'URL copied to clipboard!'
            });
        }, () => {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Copy to clipboard failed.'
            });
        });
    }
};
__decorate([
    Watch('$route.query', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseReportView.prototype, "onQueryStringChange", null);
BaseReportView = __decorate([
    Component
], BaseReportView);
export default BaseReportView;
