import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/utils/base/BaseButton';
let ButtonCancel = class ButtonCancel extends BaseButton {
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ButtonCancel.prototype, "color", void 0);
ButtonCancel = __decorate([
    Component
], ButtonCancel);
export default ButtonCancel;
