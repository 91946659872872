import { HeaderSortOptions } from '~/types/misc';
import { STANDARD_COLS } from '~/utils/genericTableColumns';
import { addBaseProperties, addGenericState, addLaunchReturn } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const EMU_BATTERY_TABLE_HEADERS = [...STANDARD_COLS];
export const ACTIVITY_EMU_BATTERY_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    {
        text: 'Is Charge?',
        value: '_isChargeIcon',
        noEscape: true,
        align: 'center'
    },
    {
        text: 'Charge</br>Location',
        value: '_location',
        sortable: true
    },
    {
        text: 'Charge Ah',
        value: '_changeAmpHours',
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'Is Discharge?',
        value: '_isDischargeIcon',
        noEscape: true,
        align: 'center'
    },
    {
        text: 'Discharge Ah',
        value: '_dischangeAmpHours',
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'Discharge<br/>to 16V?',
        value: '_isDischargeTo16vIcon',
        noEscape: true,
        align: 'center'
    }
];
export const transformEmuBattery = (items) => {
    return items.map(addBaseProperties).map(addGenericState).map(addLaunchReturn);
};
