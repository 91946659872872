import { HardwareTypeDisplay } from '~/types';
import BaseController from './base/BaseController';
import { TableHeadersByHardwareType } from './constants/tableDefinitions';
import { transformHardwaresToTableFormat } from './helpers/transformHardwaresToTableFormat';
export default class HardwareController extends BaseController {
    _hardwareType;
    hardwareTypeDisplay;
    constructor(hardwareType) {
        super();
        if (!hardwareType) {
            return;
        }
        this.hardwareType = hardwareType;
    }
    get baseUrl() {
        return `/item-instances/${this.hardwareType.toLowerCase()}`;
    }
    get hardwareType() {
        return this._hardwareType;
    }
    set hardwareType(value) {
        this._hardwareType = value;
        this.hardwareTypeDisplay = HardwareTypeDisplay.get(value) || '';
    }
    get tableHeaderDefinitions() {
        if (!this.hardwareType) {
            return [];
        }
        const tableHeaders = TableHeadersByHardwareType.get(this.hardwareType);
        if (!tableHeaders) {
            console.error(`${this.hardwareType} doesn't have table cols registered.`);
        }
        return tableHeaders || [];
    }
    fetchAllData(includeArchived = true) {
        if (!this.hardwareType) {
            throw Error('Unknown hardwareType');
        }
        return this.$http.get(`${this.baseUrl}?includeArchived=${includeArchived.toString()}`);
    }
    fetchSingleData(itemInstanceId) {
        if (!this.hardwareType || !itemInstanceId) {
            throw Error('Unknown hardwareType');
        }
        return this.$http.get(`${this.baseUrl}/${itemInstanceId}`);
    }
    createItemInstance(payload) {
        if (!this.hardwareType) {
            return;
        }
        return this.$http.post(`${this.baseUrl}`, payload);
    }
    updateItemInstance(itemInstanceId, payload) {
        if (!this.hardwareType) {
            return;
        }
        return this.$http.put(`${this.baseUrl}/${itemInstanceId}`, payload);
    }
    transformItemForTable(items) {
        return transformHardwaresToTableFormat(this.hardwareType, items);
    }
}
