var _a;
import { __decorate, __metadata } from "tslib";
import isEmpty from 'lodash.isempty';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AlertType, EntityType, Maybe } from '~/types';
import { ACTIVITY_HARDWARE_TABLE_HEADERS, buildChipAsString, ErrorUtility, markdownToHtml, sortObjectBy, transformActivities, transformItemInstances } from '~/utils';
let ReadOnlyActivity = class ReadOnlyActivity extends Vue {
    activity = null;
    isFetching = false;
    selectedItemInstance = null;
    selectedItemInstanceRow = null;
    tableHeaders = ACTIVITY_HARDWARE_TABLE_HEADERS;
    AlertType = AlertType;
    activityId;
    size;
    get comments() {
        return this.activity?.comments || [];
    }
    get computedFormWrapperSize() {
        return this.size;
    }
    get itemInstances() {
        return this.activity?.itemInstances?.map((i) => {
            if (isEmpty(i.activityAttributes)) {
                return Object.assign({}, i.itemInstance, { attributes: null });
            }
            else {
                return Object.assign({}, i.itemInstance, { attributes: { ...i.activityAttributes } });
            }
        });
    }
    get isPlannedActivity() {
        return Boolean(this.activity?.isPlanning);
    }
    get rowsPerPageItems() {
        return [
            50,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get transformedActivity() {
        if (!this.activity) {
            return;
        }
        return transformActivities([this.activity])[0];
    }
    get transformedSelectedItemInstance() {
        if (!this.selectedItemInstance) {
            return null;
        }
        return transformItemInstances([this.selectedItemInstance])[0];
    }
    get transformedItemInstances() {
        if (!this.itemInstances) {
            return [];
        }
        const transformedItems = transformItemInstances(this.itemInstances);
        return transformedItems
            .map((ii) => {
            let comment;
            const foundComment = this.activity?.comments?.find((comment) => comment.itemInstanceId === ii.id);
            if (!foundComment) {
                comment = buildChipAsString({
                    chipIconClass: this.$icons[EntityType.COMMENT],
                    chipText: 'No Comments logged.',
                    color: EntityType.COMMENT,
                    iconColor: EntityType.COMMENT
                });
            }
            else {
                comment = markdownToHtml(foundComment.commentText);
            }
            return { ...ii, _comment: comment };
        })
            .sort(sortObjectBy('subType'));
    }
    async fetchEntity() {
        if (!this.activityId) {
            return;
        }
        try {
            this.isFetching = true;
            const activity = await this.$http.get(`/activities/${this.activityId}`);
            this.isFetching = false;
            this.activity = activity?.data;
        }
        catch (err) {
            this.isFetching = false;
            ErrorUtility({ err });
        }
    }
    async fetchSelectedHardware() {
        this.selectedItemInstance = null;
        if (!this.selectedItemInstanceRow) {
            return;
        }
        const hardwareType = this.selectedItemInstanceRow.subType.toLocaleLowerCase();
        const id = this.selectedItemInstanceRow.id;
        try {
            const { data } = await this.$http.get(`/item-instances/${hardwareType}/${id}`);
            this.selectedItemInstance = data;
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", Object)
], ReadOnlyActivity.prototype, "activityId", void 0);
__decorate([
    Prop({
        type: String,
        default: '2'
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ReadOnlyActivity.prototype, "size", void 0);
__decorate([
    Watch('activityId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ReadOnlyActivity.prototype, "fetchEntity", null);
__decorate([
    Watch('selectedItemInstanceRow'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ReadOnlyActivity.prototype, "fetchSelectedHardware", null);
ReadOnlyActivity = __decorate([
    Component
], ReadOnlyActivity);
export default ReadOnlyActivity;
