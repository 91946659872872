import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, FIFTEEN_YEAR_EXPIRY_COL, FIVE_YEAR_MAINT_COL, PIA_DATE_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const LIB_CHARGER_TABLE_HEADERS = [
    ...STANDARD_COLS,
    FIVE_YEAR_MAINT_COL,
    FIFTEEN_YEAR_EXPIRY_COL
];
export const FEMUR_LIB_CHARGER_TABLE_HEADERS = [
    SERIAL_COL,
    PIA_DATE_COL,
    {
        text: 'Ground Calibration',
        value: '_groundCalibrationDate',
        sortable: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Last Calibration',
        value: '_lastCalibarationDate',
        sortable: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE,
        noEscape: true
    },
    FIVE_YEAR_MAINT_COL,
    FIFTEEN_YEAR_EXPIRY_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_LIB_CHARGER_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformLibCharger = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map(addFemurComments)
        .map(addStatusDateDisplays)
        .map((item) => {
        const status = item.status;
        let _fiveYearMaintenanceDueDate = dateDisplay(status?.calibrationDueDate?.value);
        _fiveYearMaintenanceDueDate = alertableStatusDisplay(status?.calibrationDueDate, _fiveYearMaintenanceDueDate);
        let _fifteenYearLifeExpirationDate = dateDisplay(status?.expirationDate?.value);
        _fifteenYearLifeExpirationDate = alertableStatusDisplay(status?.expirationDate, _fifteenYearLifeExpirationDate);
        let _lastCalibarationDate = dateDisplay(status?.lastCalibrationDate?.value);
        _lastCalibarationDate = alertableStatusDisplay(status?.lastCalibrationDate, _lastCalibarationDate);
        // alerts based on status
        const alerts = [
            status?.calibrationDueDate?.alertType,
            status?.expirationDate?.alertType,
            status?.lastCalibrationDate?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _fiveYearMaintenanceDueDate,
            _fifteenYearLifeExpirationDate,
            _lastCalibarationDate
        });
        return _item;
    });
};
