import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Vue } from 'vue-property-decorator';
import pkg from '~/../../package.json';
let TheFooter = class TheFooter extends Mixins(Vue) {
    isLoading = false;
    pkg = pkg;
    showChangeLog = false;
    navStateIsOpen;
    get currentUiVersion() {
        if (!this.pkg.version) {
            return '';
        }
        return `v${this.pkg.version}`;
    }
    get feedbackLink() {
        return 'https://appdat.jsc.nasa.gov/cosmic/feedback/cosmic_oot_feedback/-/issues/new';
    }
    get knowledgebaseLink() {
        return 'https://appdat.jsc.nasa.gov/cosmic/oot/user-guide';
    }
    onClickOfVersion() {
        this.showChangeLog = true;
    }
    onClickOfCloseDialog() {
        this.showChangeLog = false;
    }
    async onClickOfLogout() { }
};
__decorate([
    Prop({
        type: Boolean,
        required: true,
        default: true
    }),
    __metadata("design:type", Boolean)
], TheFooter.prototype, "navStateIsOpen", void 0);
__decorate([
    Emit('logout'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TheFooter.prototype, "onClickOfLogout", null);
TheFooter = __decorate([
    Component
], TheFooter);
export default TheFooter;
