import { numPad } from './numPad';
/**
 * Takes a range as a string i.e. "0006-0010" and returns a
 * string[] of said range preserving any leading zeroes included
 * in the original input. i.e. ["0006", "0007", "0008", "0009", "0010"]
 *
 * @param  {string} range
 */
export function rangeToListOfNumbers(range) {
    const min = range.split('-')[0];
    const max = range.split('-')[1];
    const stringLength = min.split('0').length;
    const list = [];
    for (let x = +max; x >= +min; x--) {
        list.unshift(numPad(x, stringLength));
    }
    return list;
}
