// This is a place to put all default values for Hardware Create forms
import { HardwareType } from '~/types';
const defaults = {
    [HardwareType.EHIP_LIGHT]: {
        drawingNumber: 'SEG39130226',
        asBuiltNumber: '306',
        isArchived: false
    },
    [HardwareType.EMU]: {
        drawingNumber: 'SED13101492',
        asBuiltNumber: '324',
        isArchived: false,
        attributes: {
            isMegaUnit: true
        }
    },
    [HardwareType.EMU_BATTERY]: {
        drawingNumber: 'SV819600',
        asBuiltNumber: '02-00'
    },
    [HardwareType.EMU_BATTERY_LLB]: {
        drawingNumber: 'SV1014881',
        isArchived: false,
        attributes: {
            isLlb2: true
        }
    },
    [HardwareType.EMU_ION_FILTER]: {
        drawingNumber: 'SEG33118472',
        asBuiltNumber: '301'
    },
    [HardwareType.EMU_MICRON_FILTER]: {
        drawingNumber: 'SEG33118473',
        asBuiltNumber: '301'
    },
    [HardwareType.EMU_WATER_PROCESSING_JUMPER]: {
        drawingNumber: 'SEG33125118',
        asBuiltNumber: '301',
        isArchived: false
    },
    [HardwareType.EMU_BIOCIDE_FILTER]: {
        drawingNumber: 'SEG33118474',
        isArchived: false
    },
    [HardwareType.EMU_FEEDWATER_SUPPLY_FILTER]: {
        drawingNumber: 'SEG39135322',
        asBuiltNumber: '301',
        isArchived: false
    },
    [HardwareType.EMU_SCRUB_ASSEMBLY]: {
        drawingNumber: 'SEG33125129',
        asBuiltNumber: '301',
        isArchived: false
    },
    [HardwareType.FPS]: {
        drawingNumber: 'SV787994',
        isArchived: false,
        attributes: {
            isInstalled: false
        }
    },
    [HardwareType.HL_BATTERY]: {
        drawingNumber: 'SEG93130223',
        asBuiltNumber: '302',
        isArchived: false
    },
    [HardwareType.HUT_ORU]: {
        drawingNumber: '0102-110102',
        isArchived: false,
        attributes: {
            hutInstalled: false
        }
    },
    [HardwareType.HUT_ORU_SCRUBBER]: {
        drawingNumber: 'SEG39138583',
        asBuiltNumber: '301',
        isArchived: false
    },
    [HardwareType.IEU_SCU]: {
        drawingNumber: 'SV819500',
        asBuiltNumber: '07-00',
        isArchived: false
    },
    [HardwareType.LCVG]: {
        drawingNumber: '0107-10007',
        asBuiltNumber: '07',
        isArchived: false
    },
    [HardwareType.LIB_CHARGER]: {
        drawingNumber: 'SEG13102011',
        isArchived: false
    },
    [HardwareType.LPGT_BATTERY]: {
        isArchived: false
    },
    [HardwareType.LREBA]: {
        isArchived: false
    },
    [HardwareType.METOX]: {
        isArchived: false,
        drawingNumber: 'SV821799',
        asBuiltNumber: '03-00'
    },
    [HardwareType.METOX_SIMULATOR]: {
        drawingNumber: 'SEG13102952'
    },
    [HardwareType.PGT]: {
        isArchived: false
    },
    [HardwareType.PGT_BATTERY]: {
        isArchived: false,
        drawingNumber: 'SEG33110979',
        asBuiltNumber: '305'
    },
    [HardwareType.REBA]: {
        isArchived: false,
        drawingNumber: 'SEG3112213',
        asBuiltNumber: '301',
        attributes: {
            initialState: false
        }
    },
    [HardwareType.SAFER]: {
        isArchived: false,
        drawingNumber: 'SED33105900',
        asBuiltNumber: '315'
    },
    [HardwareType.SAFETY_TETHER_55]: {
        isArchived: false
    },
    [HardwareType.SAFETY_TETHER_85]: {
        isArchived: false
    },
    [HardwareType.SCOF]: {
        isArchived: false,
        drawingNumber: 'SV799099',
        asBuiltNumber: '00-01'
    },
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER]: {
        isArchived: false
    },
    [HardwareType.UIA]: {
        isArchived: false,
        drawingNumber: 'SEG481374',
        asBuiltNumber: '101'
    },
    [HardwareType.UIA_BIOCIDE_FILTER]: {
        isArchived: false,
        drawingNumber: 'SEG39128318',
        asBuiltNumber: '303'
    },
    [HardwareType.WAIST_TETHER]: {
        isArchived: false
    }
};
export default defaults;
