var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { sortObjectBy } from '~/utils';
import { EVENT_TABLE_HEADERS, transformEvents } from '~/utils/constants';
let EventsTable = class EventsTable extends Vue {
    EVENT_TABLE_HEADERS = EVENT_TABLE_HEADERS;
    subType = null;
    events;
    get computedEvents() {
        if (!this.events) {
            return [];
        }
        return transformEvents(this.events).sort(sortObjectBy('_activityDate', true));
    }
    get rowsPerPageItems() {
        return [
            10,
            25,
            50,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    onClickOfEventTableRow(item) {
        return item;
    }
};
__decorate([
    Prop({
        type: Array,
        default: () => []
    }),
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], EventsTable.prototype, "events", void 0);
__decorate([
    Emit('rowClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], EventsTable.prototype, "onClickOfEventTableRow", null);
EventsTable = __decorate([
    Component
], EventsTable);
export default EventsTable;
