import { Vue } from 'vue-property-decorator';
import { AlertType } from '~/types';
import { ERROR_MESSAGE_MAPPER } from '~/utils/constants';
export function ErrorUtility(options) {
    /**
     * @param  { IHandleErrorUtilityOptions } options
     * Takes in an error object, a vue instance, and optional override text, and optional args.
     *
     * ErrorUtility $logs the error object via telemetry and then notifies the user
     * an appropriate error message by parsing err.message in ERROR_MESSAGE_MAPPER.
     *
     * If backupErrorText is supplied, this text is displayed in the notification instead.
     *
     * Override text is a bridge for us to use until we can appropriately map and handle specific
     * GraphQL errors, only use if you absolutely need to.
     */
    const { err, backupErrorText, ...args } = options;
    const computedErrorMessage = ERROR_MESSAGE_MAPPER(err.message, backupErrorText);
    // const log = new Log();
    // log.error({ err });
    const vueInstance = new Vue();
    vueInstance.$notification.add({
        text: computedErrorMessage,
        type: AlertType.ERROR
    });
    return null;
}
