var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { HardwareType, Maybe } from '~/types';
import { getActivityFormFields, getFormFields } from '~/utils/FormBuilder';
import { FormControl } from '~/utils/forms/types';
import { isNullOrUndefined } from '~/utils/helpers/isNullOrUndefined';
var DisplayType;
(function (DisplayType) {
    DisplayType["Text"] = "Text";
    DisplayType["Number"] = "Number";
    DisplayType["Date"] = "Date";
    DisplayType["Bool"] = "Bool";
})(DisplayType || (DisplayType = {}));
let ReadOnlyAttributes = class ReadOnlyAttributes extends Vue {
    DisplayType = DisplayType;
    attributeDisplayItems = [];
    isActivity;
    isHardware;
    activityType;
    hardwareType;
    readOnlyAttributes;
    getAttributes() {
        if (this.isActivity) {
            if (!this.activityType) {
                throw Error('Activity attributes must supply an `activityType` prop');
            }
            return getActivityFormFields(this.hardwareType, this.activityType);
        }
        if (this.isHardware) {
            return getFormFields(this.hardwareType);
        }
        throw Error(`Specify whether attributes are related to activity or hardware`);
    }
    displayValue(displayItem) {
        if (isNullOrUndefined(displayItem.value)) {
            return '-';
        }
        switch (displayItem.type) {
            case DisplayType.Number:
            case DisplayType.Text:
                return displayItem.value;
            case DisplayType.Bool:
                return this.$booleanDisplay(Boolean(displayItem.value));
            case DisplayType.Date:
                return this.$dateDisplay(`${displayItem.value}`);
            default:
                break;
        }
    }
    async onReadOnlyAttributesChange() {
        const formSections = await this.getAttributes();
        const displayItems = formSections
            .flatMap((section) => section.fields)
            .map((field) => ({
            label: field.label,
            value: this.formControlValue(this.readOnlyAttributes[field.key], field),
            type: this.formControlDisplayType.get(field.control)
        }));
        this.attributeDisplayItems = displayItems;
    }
    formControlDisplayType = new Map([
        [FormControl.RadioBoolean, DisplayType.Bool],
        [FormControl.TextField, DisplayType.Text],
        [FormControl.Dropdown, DisplayType.Text],
        [FormControl.NumberField, DisplayType.Number],
        [FormControl.DatePicker, DisplayType.Date]
    ]);
    formControlValue(value, field) {
        // if the control was a dropdown, get the displayText
        const isDropdown = field.control === FormControl.Dropdown;
        const hasSelectOptions = Boolean(field.attrs?.selectOptions?.length);
        if (isDropdown && hasSelectOptions) {
            const selectedOption = field.attrs.selectOptions.find((option) => option.value === value);
            return selectedOption?.displayText;
        }
        return value;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ReadOnlyAttributes.prototype, "isActivity", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ReadOnlyAttributes.prototype, "isHardware", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ReadOnlyAttributes.prototype, "activityType", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _b : Object)
], ReadOnlyAttributes.prototype, "hardwareType", void 0);
__decorate([
    Prop({
        type: Object,
        default: {}
    }),
    __metadata("design:type", Object)
], ReadOnlyAttributes.prototype, "readOnlyAttributes", void 0);
__decorate([
    Watch('readOnlyAttributes', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ReadOnlyAttributes.prototype, "onReadOnlyAttributesChange", null);
ReadOnlyAttributes = __decorate([
    Component
], ReadOnlyAttributes);
export default ReadOnlyAttributes;
