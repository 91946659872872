var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseItemInstance, EntityType, HardwareTypeDisplay } from '~/types';
import { asBuiltNumberDisplay, serialNumberDisplay } from '~/utils';
let ItemInstanceMini = class ItemInstanceMini extends Vue {
    entityType = EntityType.ITEM_INSTANCE;
    hardware = null;
    itemInstance;
    get iconClass() {
        return this.$icons[EntityType.ITEM_INSTANCE];
    }
    get name() {
        return this.itemInstance?.drawingNumber;
    }
    get verticalLabelDisplayItems() {
        if (!this.itemInstance) {
            return [];
        }
        const items = [];
        if (this.itemInstance?.asBuiltNumber) {
            items.push({
                name: 'As-Built',
                value: `${asBuiltNumberDisplay(this.itemInstance?.asBuiltNumber)}`
            });
        }
        if (this.itemInstance?.serialNumber) {
            items.push({
                name: 'Serial',
                value: serialNumberDisplay(this.itemInstance?.serialNumber)
            });
        }
        if (this.itemInstance?.barcode) {
            items.push({
                name: 'Barcode',
                value: this.itemInstance?.barcode || '-'
            });
        }
        if (this.itemInstance?.subType) {
            items.push({
                name: 'Type',
                value: HardwareTypeDisplay.get(this.itemInstance?.subType)
            });
        }
        return items;
    }
};
__decorate([
    Prop({
        default: () => { },
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof BaseItemInstance !== "undefined" && BaseItemInstance) === "function" ? _a : Object)
], ItemInstanceMini.prototype, "itemInstance", void 0);
ItemInstanceMini = __decorate([
    Component
], ItemInstanceMini);
export default ItemInstanceMini;
