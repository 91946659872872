import { __decorate, __metadata } from "tslib";
import { Component, Emit, Vue } from 'vue-property-decorator';
let AppExportMenu = class AppExportMenu extends Vue {
    options = ['PDF', 'XLS'];
    onClickOfPdf() { }
    onClickOfXls() { }
};
__decorate([
    Emit('pdf-click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppExportMenu.prototype, "onClickOfPdf", null);
__decorate([
    Emit('xls-click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppExportMenu.prototype, "onClickOfXls", null);
AppExportMenu = __decorate([
    Component
], AppExportMenu);
export default AppExportMenu;
