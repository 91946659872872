import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { UserMixin } from './mixins/user/UserMixin';
import axios from 'axios';
let CosmicOotApp = class CosmicOotApp extends Mixins(UserMixin) {
    navStateOpen = true;
    onNavStateChange(val) {
        this.navStateOpen = val;
    }
    async resetUser() {
        const logoutURL = '/api/oauth/logout';
        // using axios here instead of bound $http instance. Different base URL
        await axios.get(logoutURL);
        this.$router.push('/');
    }
};
CosmicOotApp = __decorate([
    Component
], CosmicOotApp);
export default CosmicOotApp;
