var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ActivityTypeDisplay, AlertType, BaseActivity } from '~/types';
import { dateDisplay } from '~/utils';
let PlannedActivityAlert = class PlannedActivityAlert extends Vue {
    AlertType = AlertType;
    showConfirmationDialog = false;
    activity;
    readOnly;
    get activityDate() {
        return dateDisplay(this.activity.activityDate);
    }
    get activityType() {
        return ActivityTypeDisplay.get(this.activity.subType);
    }
    get itemInstanceCount() {
        return this.activity.itemInstances?.length ?? 0;
    }
    onClickOfConfirmActualize() { }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof BaseActivity !== "undefined" && BaseActivity) === "function" ? _a : Object)
], PlannedActivityAlert.prototype, "activity", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], PlannedActivityAlert.prototype, "readOnly", void 0);
__decorate([
    Emit('actualize-select'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PlannedActivityAlert.prototype, "onClickOfConfirmActualize", null);
PlannedActivityAlert = __decorate([
    Component
], PlannedActivityAlert);
export default PlannedActivityAlert;
