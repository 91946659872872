var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { IHardwareFormBase } from '~/types';
let HardwareCommentsFormPartial = class HardwareCommentsFormPartial extends Vue {
    localFormData = {};
    formData;
    onChangeOfLocalFormData(localFormData) {
        const commentFields = {
            femuRCommentText: localFormData.femuRCommentText,
            generalCommentText: localFormData.generalCommentText
        };
        return Object.assign({}, this.formData, commentFields);
    }
};
__decorate([
    Prop({ required: true }),
    __metadata("design:type", typeof (_a = typeof IHardwareFormBase !== "undefined" && IHardwareFormBase) === "function" ? _a : Object)
], HardwareCommentsFormPartial.prototype, "formData", void 0);
__decorate([
    Emit('input'),
    Watch('localFormData', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Partial !== "undefined" && Partial) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], HardwareCommentsFormPartial.prototype, "onChangeOfLocalFormData", null);
HardwareCommentsFormPartial = __decorate([
    Component
], HardwareCommentsFormPartial);
export default HardwareCommentsFormPartial;
