import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseVue from './BaseVue';
/**
 * AVAILABLE PROPS:
 *
 */
let BaseButton = class BaseButton extends BaseVue {
    color;
    disabled;
    href;
    icon;
    isSaving;
    showLoader;
    small;
    textColor;
    /**
     * Use this instead of `href` if you are linking inside the same module. It'll
     * use VueRouter and is way faster than a regular link.
     *
     * ONLY use to if you are 100% certain that you are linking inside the same
     * module. Trying to link cross-module with to will fail and render a blank
     * page. If there's any chance that your code may involve jumping across
     * modules, DON'T use this. Use href instead.
     */
    to;
    get computedDisabled() {
        return this.disabled || this.showLoader || this.isSaving;
    }
    get computedTextColor() {
        return `${this.textColor}--text`;
    }
    // Vuetify has an identically named prop called "icon" and we dont use that fncality
    // so remove it so it stops doing weird ui things
    get propsWithoutIcon() {
        const obj = Object.assign({}, this.$props);
        delete obj.icon;
        return obj;
    }
    emitClick() { }
};
__decorate([
    Prop({
        default: 'primary',
        type: String
    }),
    __metadata("design:type", String)
], BaseButton.prototype, "color", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseButton.prototype, "disabled", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], BaseButton.prototype, "href", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseButton.prototype, "icon", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseButton.prototype, "isSaving", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseButton.prototype, "showLoader", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseButton.prototype, "small", void 0);
__decorate([
    Prop({
        default: 'white',
        type: String
    }),
    __metadata("design:type", String)
], BaseButton.prototype, "textColor", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], BaseButton.prototype, "to", void 0);
__decorate([
    Emit('click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseButton.prototype, "emitClick", null);
BaseButton = __decorate([
    Component
], BaseButton);
export default BaseButton;
