var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { cloneDeep } from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ActionTypes, EventModuleName, GetterTypes } from '~/store/event';
import { HardwareParent, HardwareType, HardwareTypeDisplay, IHardwareFormBase, SizeType, SizeTypeDisplay } from '~/types';
import { ErrorUtility, transformItemInstances } from '~/utils';
import HardwareController from '~/utils/HardwareController';
const eventModule = namespace(EventModuleName);
let HardwareIdentificationFormPartial = class HardwareIdentificationFormPartial extends Vue {
    localFormData = {};
    parentItem = null;
    SizeType = SizeType;
    SizeTypeDisplay = SizeTypeDisplay;
    formData;
    isLoading;
    // used to show/hide size ddl on EMU and HUT ORU
    subType;
    flightDownOptions;
    flightUpOptions;
    getFlights;
    get isEmuForm() {
        return this.subType === HardwareType.EMU;
    }
    get isHutOruForm() {
        return this.subType === HardwareType.HUT_ORU;
    }
    get installationParentType() {
        return HardwareParent.get(this.subType);
    }
    get hasParentType() {
        return Boolean(this.installationParentType);
    }
    get transformedParentItem() {
        if (!this.parentItem) {
            return null;
        }
        return transformItemInstances([this.parentItem])[0];
    }
    get parentItemName() {
        if (!this.parentItem) {
            return null;
        }
        const { subType, serialNumber } = this.parentItem;
        return `${HardwareTypeDisplay.get(subType)}: ${serialNumber}`;
    }
    async mounted() {
        try {
            await this.getFlights();
        }
        catch (err) {
            ErrorUtility({ err, backupErrorText: 'EMU create/edit form failed to load flight data' });
        }
    }
    // if isLoading is true then it means we're waiting on pre-pop form data
    onChangeOfIsLoading(isLoading) {
        // if isLoading is true, do nothing
        if (isLoading) {
            return;
        }
        // else, we have pre-pop data... clone it to local state
        this.localFormData = cloneDeep(this.formData);
    }
    onChangeOfLocalFormData(localFormData) {
        const topLevelFields = {
            drawingNumber: localFormData.drawingNumber,
            asBuiltNumber: localFormData.asBuiltNumber,
            serialNumber: localFormData.serialNumber,
            barcode: localFormData.barcode,
            size: localFormData.size,
            launchEventId: localFormData.launchEventId,
            returnEventId: localFormData.returnEventId
        };
        return Object.assign({}, this.formData, topLevelFields);
    }
    async onParentItemInstanceChange(id) {
        if (!id) {
            this.parentItem = null;
            return;
        }
        try {
            const controller = new HardwareController(this.installationParentType);
            const { data } = await controller.fetchSingleData(id);
            this.parentItem = data;
        }
        catch (err) {
            ErrorUtility({
                err,
                backupErrorText: `There was a problem getting ${HardwareTypeDisplay.get(this.installationParentType)} data`
            });
        }
    }
};
__decorate([
    Prop({ required: true }),
    __metadata("design:type", typeof (_a = typeof IHardwareFormBase !== "undefined" && IHardwareFormBase) === "function" ? _a : Object)
], HardwareIdentificationFormPartial.prototype, "formData", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], HardwareIdentificationFormPartial.prototype, "isLoading", void 0);
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_b = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _b : Object)
], HardwareIdentificationFormPartial.prototype, "subType", void 0);
__decorate([
    eventModule.Getter(GetterTypes.flightsDownOptions),
    __metadata("design:type", Array)
], HardwareIdentificationFormPartial.prototype, "flightDownOptions", void 0);
__decorate([
    eventModule.Getter(GetterTypes.flightsUpOptions),
    __metadata("design:type", Array)
], HardwareIdentificationFormPartial.prototype, "flightUpOptions", void 0);
__decorate([
    eventModule.Action(ActionTypes.getFlights),
    __metadata("design:type", Function)
], HardwareIdentificationFormPartial.prototype, "getFlights", void 0);
__decorate([
    Watch('isLoading', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], HardwareIdentificationFormPartial.prototype, "onChangeOfIsLoading", null);
__decorate([
    Emit('input'),
    Watch('localFormData', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Partial !== "undefined" && Partial) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], HardwareIdentificationFormPartial.prototype, "onChangeOfLocalFormData", null);
__decorate([
    Watch('formData.parentItemInstanceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], HardwareIdentificationFormPartial.prototype, "onParentItemInstanceChange", null);
HardwareIdentificationFormPartial = __decorate([
    Component
], HardwareIdentificationFormPartial);
export default HardwareIdentificationFormPartial;
