export function getNumericalRangeAsStrings(start, end) {
    if (!start || !end) {
        return null;
    }
    const numStart = typeof start === 'string' ? parseInt(start) : start;
    const numEnd = typeof end === 'string' ? parseInt(end) : end;
    const range = [];
    for (let i = numStart; i <= numEnd; i++) {
        range.push(i.toString());
    }
    return range.length ? range : null;
}
