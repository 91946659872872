var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommentType, CommentTypeDisplay } from '~/types';
import { markdownToHtml, sortObjectBy } from '~/utils';
import { LAST_UPDATE_BY_COL, LAST_UPDATE_DATE_COL, SUBTYPE_COL } from '~/utils/genericTableColumns';
import { addLastUpdated } from '~/utils/mappers';
const allCommentTypes = Object.values(CommentType);
let CommentsTableMixin = class CommentsTableMixin extends Vue {
    comments;
    displayedCommentTypes;
    get computedComments() {
        if (!this.comments) {
            return [];
        }
        return this.comments
            .filter(({ subType }) => subType && this.displayedCommentTypes.includes(subType))
            .map(addLastUpdated)
            .map((comment) => {
            return {
                _text: markdownToHtml(comment?.commentText),
                _subType: CommentTypeDisplay.get(comment?.subType) || '-',
                ...comment
            };
        })
            .sort(sortObjectBy('lastUpdateDate', true));
    }
    get commentsTableHeaders() {
        return [
            LAST_UPDATE_DATE_COL,
            LAST_UPDATE_BY_COL,
            SUBTYPE_COL,
            {
                text: 'Comment',
                value: '_text',
                sortable: true,
                align: 'left',
                noEscape: true
            }
        ];
    }
    get rowsPerPageItems() {
        return [
            10,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
};
__decorate([
    Prop({
        default: () => [],
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], CommentsTableMixin.prototype, "comments", void 0);
__decorate([
    Prop({
        required: false,
        type: Array,
        default: () => allCommentTypes
    }),
    __metadata("design:type", Array)
], CommentsTableMixin.prototype, "displayedCommentTypes", void 0);
CommentsTableMixin = __decorate([
    Component
], CommentsTableMixin);
export { CommentsTableMixin };
