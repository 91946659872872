import { AlertType } from '~/types';
import { CHARGER_SERIAL_COL, CHARGE_LOCATION_COL, CYCLE_COUNT_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, GENERIC_STATE_ICON_COL, INSTALLED_ON_COL, INSTALLED_SERIAL_NUMBER_COL, LAST_DISCHARGE_AUTOCYCLE_COL, SERIAL_COL, SEVEN_YEAR_EXPIRY_COL, STANDARD_COLS, VOLTS_COL } from '../genericTableColumns';
import { addAttributeDateDisplays, addBaseProperties, addCycles, addFemurComments, addGenericState, addInstalledOnIcon, addLastDischargeAutocycle, addLaunchReturn, addSevenYearExpiry, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const SAFER_LPGT_BATTERY_TABLE_HEADERS = [
    ...STANDARD_COLS,
    GENERIC_STATE_ICON_COL,
    INSTALLED_SERIAL_NUMBER_COL,
    LAST_DISCHARGE_AUTOCYCLE_COL,
    SEVEN_YEAR_EXPIRY_COL
];
export const FEMUR_SAFER_LPGT_BATTERY_TABLE_HEADERS = [
    SERIAL_COL,
    INSTALLED_ON_COL,
    { ...INSTALLED_SERIAL_NUMBER_COL, text: 'Installed SAFER Serial' },
    LAST_DISCHARGE_AUTOCYCLE_COL,
    SEVEN_YEAR_EXPIRY_COL,
    CYCLE_COUNT_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_SAFER_LPGT_BATTERY_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    VOLTS_COL,
    CHARGE_LOCATION_COL,
    CHARGER_SERIAL_COL
];
export const transformSaferLpgtBattery = (items = []) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addFemurComments)
        .map(addGenericState)
        .map(addLastDischargeAutocycle)
        .map(addLaunchReturn)
        .map(addSevenYearExpiry)
        .map(addStatusDateDisplays)
        .map(addCycles)
        .map(addInstalledOnIcon)
        .map((item) => {
        const status = item.status;
        // alerts based on status
        const alerts = [
            status?.lastDischargeDate?.alertType,
            status?.expirationDate?.alertType,
            status?.cycles?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        return Object.assign({}, item, { rowClass });
    });
};
