import sanitizeHtml from 'sanitize-html';
// Accept almost all sanitize-html defaults
// List can be found here: https://www.npmjs.com/package/sanitize-html
export const directive = (el, binding) => {
    const sanitized = sanitizeHtml(binding.value, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['strike', 'i']),
        allowedAttributes: {
            '*': ['class', 'style', 'title'],
            a: ['href', 'target', 'name', 'class', 'style', 'title']
        }
    });
    el.innerHTML = sanitized;
};
export default {
    install: (Vue) => {
        Vue.directive('safe-html', {
            update: directive,
            bind: directive
        });
    }
};
