import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { UserMixin } from '~/mixins/user/UserMixin';
import { EntityType } from '~/types';
let TheUserAvatar = class TheUserAvatar extends Mixins(UserMixin) {
    get currentUser() {
        return this.user;
    }
    get color() {
        return EntityType.USER;
    }
    get initials() {
        if (!this.currentUser) {
            return '<i class="fad fa-space-station-moon-alt"></i>';
        }
        if (this.currentUser.auid === 'cosmic') {
            return '<i class="fad fa-starfighter"></i>';
        }
        else {
            const firstInitial = this.currentUser.givenName ? this.currentUser.givenName?.charAt(0) : '*';
            const lastInitial = this.currentUser.familyName ? this.currentUser.familyName.charAt(0) : '*';
            return `${firstInitial}${lastInitial}`;
        }
    }
    routeToUser() {
        this.$router.push(`/users/whoami`);
    }
};
TheUserAvatar = __decorate([
    Component
], TheUserAvatar);
export default TheUserAvatar;
