import { FemurSpecificTypes, HardwareType } from '~/types';
import * as transforms from '../constants';
import { transformEmuBiocideFilterStatusPropertiesForAhd, transformEmuScrubAssemblyStatusPropertiesForAhd, transformIeuScuStatusPropertiesForAhd, transformUiaStatusPropertiesForAhd } from '../constants';
export const transformHardwaresToTableFormat = (type, items = []) => {
    switch (type) {
        case HardwareType.EHIP_LIGHT:
            return transforms.transformEhipLight(items);
        case HardwareType.EMU:
            return transforms.transformEmu(items);
        case HardwareType.EMU_BATTERY:
            return transforms.transformEmuBattery(items);
        case HardwareType.EMU_BATTERY_LLB:
            return transforms.transformEmuBatteryLlb(items);
        case HardwareType.EMU_BIOCIDE_FILTER:
            return transforms.transformEmuBiocideFilter(items);
        case HardwareType.EMU_FEEDWATER_SUPPLY_FILTER:
            return transforms.transformEmuFeedwaterSupplyFilter(items);
        case HardwareType.EMU_ION_FILTER:
            return transforms.transformEmuIonFilter(items);
        case HardwareType.EMU_MICRON_FILTER:
            return transforms.transformEmuMicronFilter(items);
        case HardwareType.EMU_SCRUB_ASSEMBLY:
            return transforms.transformEmuScrubAssembly(items);
        case HardwareType.EMU_WATER_PROCESSING_JUMPER:
            return transforms.transformEmuWaterProcessingJumper(items);
        case HardwareType.FPS:
            return transforms.transformFps(items);
        case HardwareType.HAB_HAP_E:
            return transforms.transformHabHapE(items);
        case HardwareType.HL_BATTERY:
            return transforms.transformHlBattery(items);
        case HardwareType.HUT_ORU:
            return transforms.transformHutOru(items);
        case HardwareType.HUT_ORU_SCRUBBER:
            return transforms.transformHutOruScrubber(items);
        case HardwareType.IEU_SCU:
            return transforms.transformIeuScu(items);
        case HardwareType.LCVG:
            return transforms.transformLcvg(items);
        case HardwareType.LEGACY_FPU:
            return transforms.transformLegacyFpu(items);
        case HardwareType.LIB_CHARGER:
            return transforms.transformLibCharger(items);
        case HardwareType.LPGT_BATTERY:
            return transforms.transformLpgtBattery(items);
        case HardwareType.LREBA:
            return transforms.transformLreba(items);
        case HardwareType.METOX:
            return transforms.transformMetox(items);
        case HardwareType.METOX_SIMULATOR:
            return transforms.transformMetoxSimulator(items);
        case HardwareType.PGT:
            return transforms.transformPgt(items);
        case HardwareType.PGT_BATTERY:
            return transforms.transformPgtBattery(items);
        case HardwareType.REBA:
            return transforms.transformReba(items);
        case HardwareType.SAFER:
            return transforms.transformSafer(items);
        case HardwareType.SAFER_LPGT_BATTERY:
            return transforms.transformSaferLpgtBattery(items);
        case HardwareType.SAFETY_TETHER_55:
            return transforms.transformSafetyTether55(items);
        case HardwareType.SAFETY_TETHER_85:
            return transforms.transformSafetyTether85(items);
        case HardwareType.SCOF:
            return transforms.transformScof(items);
        case HardwareType.TETHER_EXTENSION_D_RING_EXTENDER:
            return transforms.transformTetherExtensionDringExtender(items);
        case HardwareType.UIA_BIOCIDE_FILTER:
            return transforms.transformUiaBiocideFilter(items);
        case HardwareType.UIA:
            return transforms.transformUia(items);
        case HardwareType.WAIST_TETHER:
            return transforms.transformWaistTether(items);
        case FemurSpecificTypes.EMU_IEU_SCU_UIA:
            return transforms.transformIeuScuUiaEmuForFemur(items);
        default:
            return [];
    }
};
// The AHD will only render properties it finds on an item's `status` object
// This transform is an opportunity to modify any of those property names
// so they can be picked up by the AHD .
// Example: IEU/SCU should have an AHD label for "Water Circulation due" based on the scrubDate
// but this is not on the IeuScuStatus model.
export const getHardwareStatusPropertiesForAhd = (item) => {
    const keys = Object.keys(item?.status ?? {});
    switch (item.subType) {
        case HardwareType.IEU_SCU:
            return transformIeuScuStatusPropertiesForAhd(keys);
        case HardwareType.UIA:
            return transformUiaStatusPropertiesForAhd(keys);
        case HardwareType.EMU_BIOCIDE_FILTER:
            return transformEmuBiocideFilterStatusPropertiesForAhd(keys);
        case HardwareType.EMU_SCRUB_ASSEMBLY:
            return transformEmuScrubAssemblyStatusPropertiesForAhd(keys);
        default:
            return keys;
    }
};
