import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/utils/base/BaseButton';
let ButtonGeneric = class ButtonGeneric extends BaseButton {
    loadingText;
};
__decorate([
    Prop({
        default: 'Loading…',
        type: String
    }),
    __metadata("design:type", String)
], ButtonGeneric.prototype, "loadingText", void 0);
ButtonGeneric = __decorate([
    Component
], ButtonGeneric);
export default ButtonGeneric;
