import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { UserMixin } from '~/mixins/user/UserMixin';
import { EntityType } from '~/types';
let UserMini = class UserMini extends Mixins(UserMixin) {
    entityType = EntityType.USER;
    get auidDisplay() {
        return this.user?.auid || '-';
    }
    get emailDisplay() {
        return this.user?.email || '-';
    }
    get isCosmicUser() {
        return this.user?.auid === 'cosmic';
    }
    get name() {
        if (this.isCosmicUser) {
            return 'COSMIC';
        }
        const { givenName, familyName } = this.user;
        return `${givenName} ${familyName}`;
    }
    get uupicDisplay() {
        return this.user?.uupic || '-';
    }
    get userHasFirstAndLastName() {
        return Boolean(this.user?.givenName && this.user.familyName);
    }
    /**
     * According to https://www.nasa.gov/offices/ocio/launchpad_faq.html
     * AUID is a max of 8 characters
     */
    get shouldShowAuidWarning() {
        return this.auidDisplay === '-' || this.auidDisplay.length > 8;
    }
    get verticalLabelDisplayItems() {
        if (!this.user) {
            return [];
        }
        return [
            {
                name: 'AUID',
                value: this.auidDisplay
            }
        ];
    }
};
UserMini = __decorate([
    Component
], UserMini);
export default UserMini;
