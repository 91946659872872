var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CommentType, EntityType } from '~/types';
import { ErrorUtility, transformHardwaresToTableFormat } from '~/utils';
import { IItemInstanceQueryInfo } from '../../views/ReadOnlyItemInstanceView/ReadOnlyItemInstanceView';
let ReadOnlyItemInstance = class ReadOnlyItemInstance extends Vue {
    EntityType = EntityType;
    queryResult = null;
    shownCommentTypes = [CommentType.GENERAL, CommentType.FEMU_R_003];
    transformedEntity = null;
    itemInstance;
    get activities() {
        return this.queryResult?.activities || [];
    }
    get activityComments() {
        return this.comments.filter((c) => c.subType === CommentType.ACTIVITY);
    }
    get attributes() {
        if (!this.queryResult?.attributes) {
            return;
        }
        return this.queryResult?.attributes;
    }
    get baseUrl() {
        return `/item-instances`;
    }
    get comments() {
        return this.queryResult?.comments || [];
    }
    get drawingNumber() {
        return this.queryResult?.drawingNumber ?? '-';
    }
    get events() {
        return this.queryResult?.events || [];
    }
    get shouldShowReadOnlyAttributes() {
        if (!this.attributes) {
            return false;
        }
        return Boolean(Object.keys(this.attributes).length);
    }
    get transformedItemInstance() {
        if (!this.hardwareSubType) {
            return null;
        }
        return this.queryResult ? transformHardwaresToTableFormat(this.hardwareSubType, [this.queryResult])[0] : null;
    }
    get hardwareSubType() {
        return this.queryResult?.subType;
    }
    onClickOfActivityTableRow(activity) {
        this.$router.push(`/reporting/activities/${activity.id}`);
    }
    onClickOfEventTableRow(event) {
        this.$router.push(`/reporting/events/${event.id}?subType=${event.subType}`);
    }
    async fetchItemInstance() {
        try {
            const resp = await this.$http.get(`${this.baseUrl}/${this.itemInstance.subType}/${this.itemInstance.id}`);
            this.queryResult = resp.data;
        }
        catch (err) {
            ErrorUtility({
                err
            });
        }
    }
};
__decorate([
    Prop({
        default: '',
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof IItemInstanceQueryInfo !== "undefined" && IItemInstanceQueryInfo) === "function" ? _a : Object)
], ReadOnlyItemInstance.prototype, "itemInstance", void 0);
__decorate([
    Watch('itemInstanceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ReadOnlyItemInstance.prototype, "fetchItemInstance", null);
ReadOnlyItemInstance = __decorate([
    Component
], ReadOnlyItemInstance);
export default ReadOnlyItemInstance;
