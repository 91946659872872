import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { INSTALLED_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay, booleanDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addLaunchReturn } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const LEGACY_FPU_TABLE_HEADERS = [
    ...STANDARD_COLS,
    INSTALLED_COL,
    {
        text: 'Return due',
        value: '_returnDueDate',
        headerSortType: HeaderSortOptions.DATE,
        sortable: true
    }
];
export const ACTIVITY_LEGACY_FPU_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformLegacyFpu = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map((item) => {
        const status = item.status;
        const attributes = item.attributes;
        const isInstalled = attributes?.isInstalled || false;
        const isInstalledText = isInstalled ? 'Installed' : 'Not Installed';
        const returnDue = alertableStatusDisplay(status?.returnDueDate, dateDisplay(status?.returnDueDate?.value));
        const rowClass = status.returnDueDate?.alertType === AlertType.ALERT
            ? 'red lighten-3'
            : status.returnDueDate?.alertType === AlertType.WARNING
                ? 'orange lighten-3'
                : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _isInstalledIcon: booleanDisplay(isInstalled, isInstalledText),
            _isInstalledText: isInstalledText,
            _returnDueDate: returnDue
        });
        return _item;
    });
};
