export var FormControl;
(function (FormControl) {
    FormControl["TextField"] = "AppText";
    FormControl["NumberField"] = "AppNumber";
    FormControl["Checkbox"] = "AppCheckbox";
    FormControl["DatePicker"] = "AppDate";
    FormControl["TimePicker"] = "AppTime";
    FormControl["Dropdown"] = "AppSelect";
    FormControl["EnumDropdown"] = "AppSelectEnum";
    FormControl["RadioBoolean"] = "AppBoolean";
})(FormControl || (FormControl = {}));
