import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay, numberDisplay } from '..';
import { FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, IODINATION_DUE_DATE_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addFemurComments, addIodinateDueDate, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { buildIconAsString } from '../ui/buildIconAsString';
import { CosmicIcons } from '../ui/CosmicIcons';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const LCVG_TABLE_HEADERS = [
    ...STANDARD_COLS,
    {
        text: 'LCVG Wetted Status',
        value: '_statusIcon',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    IODINATION_DUE_DATE_COL
];
export const FEMUR_LCVG_TABLE_HEADERS = [
    SERIAL_COL,
    {
        text: 'Status',
        value: '_statusIcon',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    {
        text: 'Iodination<br/>Actual Wet<br/><small>(every 90d)</small>',
        value: '_actualWet',
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Iodination<br/>due<br><small>(every 90d)</small>',
        value: '_iodinateDueDate',
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    },
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const ACTIVITY_LCVG_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformLcvg = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addIodinateDueDate)
        .map(addLaunchReturn)
        .map(addFemurComments)
        .map(addStatusDateDisplays)
        .map((item) => {
        const status = item.status;
        // If this is null its not wetted
        const isWetted = Boolean(item.status?.lastLcvgFillDate?.value);
        const statusText = isWetted ? 'Wetted' : 'Dry';
        let _completedEvas = numberDisplay(status?.totalEvas?.value);
        _completedEvas = alertableStatusDisplay(status?.totalEvas, _completedEvas);
        // adding row class based on iodinateDueDate
        const rowClass = item.status?.iodinateDueDate?.alertType === AlertType.ALERT
            ? 'red lighten-3'
            : item.status?.iodinateDueDate?.alertType === AlertType.WARNING
                ? 'orange lighten-3'
                : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _actualWet: status?.lastLcvgFillDate?.value ? dateDisplay(status?.lastLcvgFillDate?.value) : 'Dry',
            _statusIcon: buildIconAsString({
                iconClass: isWetted
                    ? `${CosmicIcons['WETTED']} text--darken-2`
                    : `${CosmicIcons['NON_WETTED']} text--darken-2`,
                color: isWetted ? 'success' : 'error',
                title: `${item.status?.lastLcvgFillDate?.description} - ${statusText}`
            }),
            _completedEvas,
            _statusText: statusText
        });
        return _item;
    });
};
