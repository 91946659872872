import { __decorate, __metadata } from "tslib";
import { addDays } from 'date-fns';
import qs from 'qs';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { BaseModalMixin } from '~/mixins';
import { ActivityType, ActivityTypeDisplay, HeaderSortOptions } from '~/types';
import { ACTIVITY_TABLE_HEADERS, ErrorUtility, formatTableValuesForHref, sortObjectBy, transformActivities } from '~/utils';
import { enumToSelectOptions } from '~/utils/ui/enumToSelectOptions';
let ModalCreateActivity = class ModalCreateActivity extends Mixins(BaseModalMixin) {
    ActivityType = ActivityType;
    activities = null;
    activityTypeOptions = enumToSelectOptions(ActivityType, ActivityTypeDisplay).sort(sortObjectBy('value'));
    isPlanning = false;
    isSaving = false;
    date = null;
    selectedActivityType = null;
    selectedRow = null;
    get activitiesTableHeaders() {
        return [
            ...ACTIVITY_TABLE_HEADERS,
            {
                align: 'right',
                headerSortType: HeaderSortOptions.NUMBER,
                sortable: true,
                text: '# of Hardware<br/>Logged Against',
                value: '_hardwareCount'
            }
        ];
    }
    get activityTypeDisplay() {
        return this.selectedActivityType ? ActivityTypeDisplay.get(this.selectedActivityType) : '';
    }
    get activityDateDisplay() {
        return this.$dateDisplay(this.date);
    }
    get filters() {
        if (!this.date) {
            return null;
        }
        return {
            activityDateAfter: this.date,
            activityDateBefore: addDays(new Date(this.date), 1),
            subType: [this.selectedActivityType]
        };
    }
    get formIsValid() {
        return Boolean(this.date && this.selectedActivityType);
    }
    get disableSubmitButton() {
        return !this.formIsValid || this.isSaving;
    }
    get relatedActivities() {
        const activities = this.activities ? transformActivities(this.activities) : [];
        const activitiesWithLink = activities.map((a) => ({
            ...a,
            _subType: formatTableValuesForHref(a._subType, `/activities/manage/${a.id}`)
        }));
        return activitiesWithLink;
    }
    get rowsPerPageItems() {
        return [
            50,
            100,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    buildQueryVariables() {
        const _qv = { ...this.filters };
        return _qv;
    }
    async fetchActivities() {
        if (!this.date || !this.selectedActivityType) {
            return;
        }
        try {
            const resp = await this.$http.get(`/activities?${qs.stringify(this.buildQueryVariables())}`);
            this.activities = resp.data;
        }
        catch (err) {
            this.activities = null;
            ErrorUtility({
                err,
                backupErrorText: 'An error occurred during the fetching of data'
            });
        }
    }
    dismissRelatedActivitiesAlert() {
        this.activities = [];
    }
    async onClickOfSubmit() {
        if (!this.formIsValid) {
            return;
        }
        this.isSaving = true;
        try {
            const payload = {
                activityDate: this.date,
                itemInstances: [],
                subType: this.selectedActivityType,
                isPlanning: this.isPlanning
            };
            const { data } = await this.$http.post('/activities', payload);
            this.$notification.add({ type: 'success', text: 'Activity Created.' });
            this.$router.push({
                path: `/activities/manage/${data.id}`,
                query: { subType: this.$route.params?.subType }
            });
        }
        catch (err) {
            ErrorUtility({ err });
        }
        this.isSaving = false;
    }
    checkForSimilarActivities() {
        if (this.date && this.selectedActivityType) {
            this.fetchActivities();
        }
        else {
            this.activities = null;
        }
    }
};
__decorate([
    Watch('date'),
    Watch('selectedActivityType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCreateActivity.prototype, "checkForSimilarActivities", null);
ModalCreateActivity = __decorate([
    Component
], ModalCreateActivity);
export default ModalCreateActivity;
