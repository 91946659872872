import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/utils/base/BaseButton';
let ButtonRunReport = class ButtonRunReport extends BaseButton {
    isSearching;
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ButtonRunReport.prototype, "color", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonRunReport.prototype, "isSearching", void 0);
ButtonRunReport = __decorate([
    Component
], ButtonRunReport);
export default ButtonRunReport;
