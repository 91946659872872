import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { UserMixin } from '~/mixins/user/UserMixin';
let TheNavBar = class TheNavBar extends Mixins(UserMixin) {
    navOpenState = null;
    userNeedsSessionInterval = null;
    get currentUser() {
        return this.user;
    }
    get userId() {
        return this.user?.uupic;
    }
    created() {
        this.navOpenState = false; // default to closed
    }
    handleLaunchpadSessionAuthorizeClick() {
        window.open('/api/oauth/authorize', '_blank');
    }
    handleSideNav(val) {
        this.navOpenState = val;
    }
    toggleSideNavigationOpenState() {
        this.navOpenState = !this.navOpenState;
    }
    emitNavOpenState() {
        return this.navOpenState;
    }
    navigateTo(path) {
        this.$router.push({ path, query: {} });
    }
};
__decorate([
    Emit('nav-open-state'),
    Watch('navOpenState'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TheNavBar.prototype, "emitNavOpenState", null);
TheNavBar = __decorate([
    Component
], TheNavBar);
export default TheNavBar;
