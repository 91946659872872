var _a;
import { __decorate, __metadata } from "tslib";
import isEmpty from 'lodash.isempty';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AlertType, INotification } from '~/types';
let TheToasterController = class TheToasterController extends Vue {
    showNotification = false;
    autoClose;
    timeOutCache = {};
    get timeout() {
        return this.autoClose ? 6000 : 0;
    }
    get checkboxIsChecked() {
        return !this.showNotification;
    }
    get color() {
        return this.nextNotification ? this.nextNotification.type : AlertType.INFO;
    }
    get displayText() {
        return this.nextNotification ? this.nextNotification.text : '';
    }
    get nextNotification() {
        return this.$notification.nextNotification;
    }
    get shouldShowNotification() {
        return this.nextNotification !== undefined;
    }
    clearNotification() {
        if (this.nextNotification) {
            if (!isEmpty(this.timeOutCache)) {
                Object.keys(this.timeOutCache).forEach((timeout) => {
                    window.clearTimeout(this.timeOutCache[timeout]);
                    delete this.timeOutCache[timeout];
                });
            }
            this.$notification.remove(this.nextNotification);
        }
    }
    onNextNotification(notification) {
        if (notification) {
            this.showNotification = true;
            this.setTimeOutOfNotification(notification);
        }
        else {
            this.showNotification = false;
        }
    }
    setTimeOutOfNotification(notification) {
        // create a unique, temporary uuid for the timeout so we can clear it specifically if needed
        const uuid = new Date().getTime();
        this.timeOutCache[uuid] = window.setTimeout(() => {
            this.$notification.remove(notification);
            this.showNotification = false;
        }, this.timeout);
    }
};
__decorate([
    Prop({ default: true }),
    __metadata("design:type", Boolean)
], TheToasterController.prototype, "autoClose", void 0);
__decorate([
    Watch('nextNotification', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof INotification !== "undefined" && INotification) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TheToasterController.prototype, "onNextNotification", null);
TheToasterController = __decorate([
    Component
], TheToasterController);
export default TheToasterController;
