var _a;
import { __decorate, __metadata } from "tslib";
import { toDate, utcToZonedTime } from 'date-fns-tz';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { CENTRAL_TIME_ZONE } from '~/types';
import BaseInput from '~/utils/base/BaseInput';
import { isNullOrUndefined } from '~/utils/helpers/isNullOrUndefined';
/**
 * Parses a date string and converts it to an IParsedDate representing the
 * equivalent central time. The string provided is assumed to be central unless
 * it includes a specific time zone offset.
 *
 * @param date A whole or partial ISO-formatted date string
 */
function parseDateIntoCentral(date) {
    try {
        if (date.includes('/')) {
            throw new Error('parseDate accepts only a whole or partial ISO-formatted string.');
        }
        // check if the date string already has an offset
        const hasOffset = dateStringHasOffset(date);
        let dateObj = null;
        if (hasOffset) {
            dateObj = utcToZonedTime(date, CENTRAL_TIME_ZONE);
        }
        else {
            // getting client timezone https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || CENTRAL_TIME_ZONE;
            dateObj = toDate(date, { timeZone });
        }
        return {
            year: dateObj.getFullYear(),
            month: dateObj.getMonth(),
            day: dateObj.getDate(),
            hours: dateObj.getHours(),
            minutes: dateObj.getMinutes()
        };
    }
    catch (error) {
        console.log(error);
        return null;
    }
}
function dateStringHasOffset(dateString) {
    const parts = dateString.split('T');
    if (parts.length < 2) {
        return false;
    }
    return parts[1].includes('-') || parts[1].includes('+') || parts[1].includes('Z');
}
let AppDate = class AppDate extends BaseInput {
    date = null;
    day = null;
    month = null;
    showInformationalText = false; // Used to toggle the blue info text
    year = null;
    informationText;
    fatLabel;
    // Regular textual messages
    get computedMessages() {
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return ['REQUIRED'];
        }
        if (this.messages) {
            return this.messages;
        }
        // show messages based on rules
        // @ts-ignore
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string');
    }
    get isYearValid() {
        return !!(this.year && this.year.length === 4 && parseInt(this.year) > 1900);
    }
    get isMonthValid() {
        return !!(this.month && this.month.length === 2 && this.month !== '00' && parseInt(this.month) <= 12);
    }
    get isDayValid() {
        return !!(this.day && this.day.length === 2 && this.day !== '00' && parseInt(this.day) <= 31);
    }
    get isInputsEmpty() {
        return Boolean(!this.year && !this.month && !this.day);
    }
    get isInputsValid() {
        // if all of the inputs are empty and this input is not required, consider the whole thing valid
        if (!this.required && this.isInputsEmpty) {
            return true;
        }
        return this.isYearValid && this.isMonthValid && this.isDayValid && this.errorMessages.length === 0;
    }
    get paddedMonth() {
        return this.month && this.month.length === 1 ? `0${this.month}` : this.month;
    }
    /**
     * Show if value == null/undefined AND every field is individually not valid
     */
    get shouldShowNoDateEnteredHelperIcon() {
        return !this.year && !this.month && !this.day;
    }
    /**
     * Show if at least one value is filled out and at least one input is invalid
     */
    get shouldShowInvalidDateHelperIcon() {
        return !this.isInputsEmpty && (!this.isYearValid || !this.isMonthValid || !this.isDayValid);
    }
    /**
     * Show if value != null/undefined AND everything is valid
     */
    get shouldShowValidDateHelperIcon() {
        return !isNullOrUndefined(this.value) && this.isInputsValid;
    }
    get useTodayIconClass() {
        const hoverClass = this.disabled ? '' : 'useTodaysDate';
        return `${this.$icons['calendar_day']} ${hoverClass} mr-2`;
    }
    clearInput(inputName) {
        this[inputName] = null;
    }
    clearInputs() {
        const inputs = ['year', 'month', 'day'];
        inputs.forEach((inputName) => (this[inputName] = null));
    }
    formatInputWithTwoDigits(val) {
        return val !== undefined && val !== null && val.length === 1 ? `0${val}` : `${val}`;
    }
    padMonth() {
        this.month = this.month && this.month.length === 1 && this.month !== '0' ? `0${this.month}` : this.month;
    }
    padDay() {
        this.day = this.day && this.day.length === 1 ? `0${this.day}` : this.day;
    }
    emitKeydown() { }
    emitInput(val) {
        const dateString = `${this.year}-${this.paddedMonth}-${this.day}`;
        // if the inputs are valid and are not empty, emit a date string. otherwise emit null
        return this.isInputsValid && !this.isInputsEmpty ? dateString : null;
    }
    updateDate(val) {
        if (val) {
            const date = val instanceof Object ? parseDateIntoCentral(val.toString()) : parseDateIntoCentral(val); // assumes central if no offset is provided
            if (date) {
                this.year = date.year + '';
                this.month = this.formatInputWithTwoDigits(date.month + 1 + '');
                this.day = this.formatInputWithTwoDigits(date.day + '');
            }
        }
        // clear the inputs when passed a value of null and not typing in the inputs
        if (val === null) {
            if (this.shouldShowInvalidDateHelperIcon) {
                // don't clear when typing in the inputs and its invalid
            }
            else {
                // clear inputs when passed null
                this.clearInputs();
            }
        }
    }
    emitError(isValid) {
        return !isValid;
    }
    // called from template with @click
    useTodaysDate() {
        if (!this.disabled) {
            this.updateDate(this.$dateDisplay(new Date()));
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppDate.prototype, "informationText", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppDate.prototype, "fatLabel", void 0);
__decorate([
    Emit('keydown'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "emitKeydown", null);
__decorate([
    Watch('month'),
    Watch('day'),
    Watch('year'),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "updateDate", null);
__decorate([
    Watch('isInputsValid'),
    Emit('error'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppDate.prototype, "emitError", null);
AppDate = __decorate([
    Component
], AppDate);
export default AppDate;
