import { ACTIVITY_EMU_BATTERY_LLB_TABLE_HEADERS, ACTIVITY_EMU_BIOCIDE_FILTER_TABLE_HEADERS, ACTIVITY_EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS, ACTIVITY_EMU_SCRUB_ASSEMBLY_TABLE_HEADERS, ACTIVITY_EMU_TABLE_HEADERS, ACTIVITY_EMU_WATER_PROCESSING_JUMPER_TABLE_HEADERS, ACTIVITY_FPS_TABLE_HEADERS, ACTIVITY_HAB_HAP_E_TABLE_HEADERS, ACTIVITY_HL_BATTERY_TABLE_HEADERS, ACTIVITY_HUT_ORU_SCRUBBER_TABLE_HEADERS, ACTIVITY_HUT_ORU_TABLE_HEADERS, ACTIVITY_IEU_SCU_TABLE_HEADERS, ACTIVITY_LCVG_TABLE_HEADERS, ACTIVITY_LIB_CHARGER_TABLE_HEADERS, ACTIVITY_LPGT_BATTERY_TABLE_HEADERS, ACTIVITY_LREBA_TABLE_HEADERS, ACTIVITY_METOX_SIMULATOR_TABLE_HEADERS, ACTIVITY_METOX_TABLE_HEADERS, ACTIVITY_PGT_BATTERY_TABLE_HEADERS, ACTIVITY_PGT_TABLE_HEADERS, ACTIVITY_REBA_TABLE_HEADERS, ACTIVITY_SAFER_TABLE_HEADERS, ACTIVITY_SAFETY_TETHER_55_TABLE_HEADERS, ACTIVITY_SAFETY_TETHER_85_TABLE_HEADERS, ACTIVITY_SCOF_TABLE_HEADERS, ACTIVITY_TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS, ACTIVITY_UIA_BIOCIDE_FILTER_TABLE_HEADERS, ACTIVITY_WAIST_TETHER_TABLE_HEADERS } from '~/utils';
import { ACTIVITY_EHIP_LIGHT_TABLE_HEADERS } from '~/utils/constants/ehipLight';
import { ACTIVITY_EMU_BATTERY_TABLE_HEADERS } from '~/utils/constants/emuBattery';
import { ACTIVITY_EMU_MICRON_FILTER_TABLE_HEADERS } from '~/utils/constants/emuMicronFilter';
import { ActivityType, EvaReadiness, HardwareType } from './schemas';
export const EvaReadinessDisplay = new Map([
    [EvaReadiness.BACKUP_USE_ONLY, 'Backup Use Only'],
    [EvaReadiness.GO_FOR_EVA, 'Go for EVA'],
    [EvaReadiness.NO_GO_FOR_EVA, 'NO GO for EVA'],
    [EvaReadiness.PENDING_ANALYSIS, 'Pending Analysis'],
    [EvaReadiness.REQUIRES_INSPECTION, 'Requires Inspection']
]);
export const HardwareTypeToActivityTypes = new Map([
    [
        HardwareType.EHIP_LIGHT,
        [ActivityType.ANNUAL_CHECK_OUT, ActivityType.EVA, ActivityType.INSPECTION, ActivityType.OTHER]
    ],
    [
        HardwareType.EMU,
        [
            ActivityType.ANNUAL_CHECK_OUT,
            ActivityType.BATTERY_MAINTENANCE,
            ActivityType.DCM_MWC_FLOW_TEST,
            ActivityType.EMU_CHECK_OUT,
            ActivityType.EMU_H2O_DUMP_FILL,
            ActivityType.EMU_H2O_RECHARGE,
            ActivityType.EMU_WATER_SAMPLE,
            ActivityType.EVA,
            ActivityType.HUT_WLVTA_REMOVE_REPLACE,
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.ITEM_146_CYCLE,
            ActivityType.LOOP_SCRUB,
            ActivityType.OTHER,
            ActivityType.SOP_CHECK_OUT,
            ActivityType.SUBLIMATOR_LEAK_TEST,
            ActivityType.INSPECTION
        ]
    ],
    [
        HardwareType.EMU_BATTERY,
        [ActivityType.BATTERY_MAINTENANCE, ActivityType.EVA, ActivityType.INSPECTION, ActivityType.OTHER]
    ],
    [
        HardwareType.EMU_BATTERY_LLB,
        [
            ActivityType.CHARGE,
            ActivityType.DISCHARGE,
            ActivityType.AUTOCYCLE,
            ActivityType.EVA,
            ActivityType.INSPECTION,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.EMU_BIOCIDE_FILTER,
        [
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.LOOP_SCRUB,
            ActivityType.INSPECTION,
            ActivityType.EVA,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.EMU_FEEDWATER_SUPPLY_FILTER,
        [ActivityType.EMU_H2O_DUMP_FILL, ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]
    ],
    [
        HardwareType.EMU_SCRUB_ASSEMBLY,
        [
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.LOOP_SCRUB,
            ActivityType.INSPECTION,
            ActivityType.EVA,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.EMU_WATER_PROCESSING_JUMPER,
        [ActivityType.IODINATE_LOOP_SCRUB, ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]
    ],
    [HardwareType.FPS, [ActivityType.GREASE_CIRCULATE, ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]],
    [HardwareType.HAB_HAP_E, [ActivityType.EVA, ActivityType.HELMET_INSTALL]],
    [
        HardwareType.HL_BATTERY,
        [ActivityType.BATTERY_MAINTENANCE, ActivityType.EVA, ActivityType.OTHER, ActivityType.INSPECTION]
    ],
    [
        HardwareType.HUT_ORU,
        [
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.PWR_FILL,
            ActivityType.HUT_WLVTA_REMOVE_REPLACE,
            ActivityType.INSPECTION,
            ActivityType.OTHER,
            ActivityType.EVA
        ]
    ],
    [
        HardwareType.HUT_ORU_SCRUBBER,
        [
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.SSM_EXTENSION_APPROVAL,
            ActivityType.INSPECTION,
            ActivityType.EVA,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.IEU_SCU,
        [
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.EVA,
            ActivityType.LOOP_SCRUB,
            ActivityType.INSPECTION,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.LCVG,
        [
            ActivityType.LCVG_FILL,
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.LOOP_SCRUB,
            ActivityType.INSPECTION,
            ActivityType.EVA,
            ActivityType.OTHER
        ]
    ],
    [HardwareType.LEGACY_FPU, [ActivityType.INSPECTION, ActivityType.OTHER]],
    [HardwareType.LIB_CHARGER, [ActivityType.CALIBRATION, ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]],
    [
        HardwareType.LPGT_BATTERY,
        [
            ActivityType.CHARGE,
            ActivityType.DISCHARGE,
            ActivityType.AUTOCYCLE,
            ActivityType.EVA,
            ActivityType.INSPECTION,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.LREBA,
        [
            ActivityType.CHARGE,
            ActivityType.DISCHARGE,
            ActivityType.AUTOCYCLE,
            ActivityType.EVA,
            ActivityType.INSPECTION,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.METOX,
        [
            ActivityType.OPS_10_2,
            ActivityType.EVA,
            ActivityType.METOX_REGENERATION,
            ActivityType.OTHER,
            ActivityType.INSPECTION
        ]
    ],
    [
        HardwareType.METOX_SIMULATOR,
        [ActivityType.ANNUAL_CHANGE_OUT, ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]
    ],
    [
        HardwareType.PGT,
        [
            ActivityType.EVA,
            ActivityType.TAK_DATA_APPROVAL,
            ActivityType.TAK_DATA_COLLECTION,
            ActivityType.INSPECTION,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.PGT_BATTERY,
        [ActivityType.BATTERY_MAINTENANCE, ActivityType.EVA, ActivityType.OTHER, ActivityType.INSPECTION]
    ],
    [
        HardwareType.REBA,
        [ActivityType.BATTERY_MAINTENANCE, ActivityType.EVA, ActivityType.OTHER, ActivityType.INSPECTION]
    ],
    [
        HardwareType.SAFER,
        [
            ActivityType.EVA,
            ActivityType.GN2_CHECK,
            ActivityType.SAFER_CHECK_OUT,
            ActivityType.SAFER_TM_CHECK_OUT,
            ActivityType.INSPECTION,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.SAFER_LPGT_BATTERY,
        [
            ActivityType.CHARGE,
            ActivityType.DISCHARGE,
            ActivityType.AUTOCYCLE,
            ActivityType.EVA,
            ActivityType.INSPECTION,
            ActivityType.OTHER
        ]
    ],
    [HardwareType.SAFETY_TETHER_55, [ActivityType.EVA, ActivityType.INSPECTION, ActivityType.OTHER]],
    [HardwareType.SAFETY_TETHER_85, [ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]],
    [HardwareType.SCOF, [ActivityType.ANNUAL_CHECK_OUT, ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]],
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER, [ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]],
    [
        HardwareType.UIA_BIOCIDE_FILTER,
        [
            ActivityType.EMU_H2O_RECHARGE,
            ActivityType.EMU_H2O_DUMP_FILL,
            ActivityType.INSPECTION,
            ActivityType.EVA,
            ActivityType.OTHER
        ]
    ],
    [
        HardwareType.UIA,
        [
            ActivityType.IODINATE_LOOP_SCRUB,
            ActivityType.LOOP_SCRUB,
            ActivityType.INSPECTION,
            ActivityType.EVA,
            ActivityType.OTHER
        ]
    ],
    [HardwareType.WAIST_TETHER, [ActivityType.INSPECTION, ActivityType.EVA, ActivityType.OTHER]]
]);
// This is used on the hardware manage page at the bottom under the Activities section
export const HardwareTypeToActivityTableHeaders = new Map([
    [HardwareType.EHIP_LIGHT, ACTIVITY_EHIP_LIGHT_TABLE_HEADERS],
    [HardwareType.EMU, ACTIVITY_EMU_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY, ACTIVITY_EMU_BATTERY_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY_LLB, ACTIVITY_EMU_BATTERY_LLB_TABLE_HEADERS],
    [HardwareType.EMU_BIOCIDE_FILTER, ACTIVITY_EMU_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_FEEDWATER_SUPPLY_FILTER, ACTIVITY_EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_MICRON_FILTER, ACTIVITY_EMU_MICRON_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_SCRUB_ASSEMBLY, ACTIVITY_EMU_SCRUB_ASSEMBLY_TABLE_HEADERS],
    [HardwareType.EMU_WATER_PROCESSING_JUMPER, ACTIVITY_EMU_WATER_PROCESSING_JUMPER_TABLE_HEADERS],
    [HardwareType.FPS, ACTIVITY_FPS_TABLE_HEADERS],
    [HardwareType.HAB_HAP_E, ACTIVITY_HAB_HAP_E_TABLE_HEADERS],
    [HardwareType.HL_BATTERY, ACTIVITY_HL_BATTERY_TABLE_HEADERS],
    [HardwareType.HUT_ORU, ACTIVITY_HUT_ORU_TABLE_HEADERS],
    [HardwareType.HUT_ORU_SCRUBBER, ACTIVITY_HUT_ORU_SCRUBBER_TABLE_HEADERS],
    [HardwareType.IEU_SCU, ACTIVITY_IEU_SCU_TABLE_HEADERS],
    [HardwareType.LCVG, ACTIVITY_LCVG_TABLE_HEADERS],
    [HardwareType.LIB_CHARGER, ACTIVITY_LIB_CHARGER_TABLE_HEADERS],
    [HardwareType.LPGT_BATTERY, ACTIVITY_LPGT_BATTERY_TABLE_HEADERS],
    [HardwareType.LREBA, ACTIVITY_LREBA_TABLE_HEADERS],
    [HardwareType.METOX, ACTIVITY_METOX_TABLE_HEADERS],
    [HardwareType.METOX_SIMULATOR, ACTIVITY_METOX_SIMULATOR_TABLE_HEADERS],
    [HardwareType.PGT, ACTIVITY_PGT_TABLE_HEADERS],
    [HardwareType.PGT_BATTERY, ACTIVITY_PGT_BATTERY_TABLE_HEADERS],
    [HardwareType.REBA, ACTIVITY_REBA_TABLE_HEADERS],
    [HardwareType.SAFER, ACTIVITY_SAFER_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_55, ACTIVITY_SAFETY_TETHER_55_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_85, ACTIVITY_SAFETY_TETHER_85_TABLE_HEADERS],
    [HardwareType.SCOF, ACTIVITY_SCOF_TABLE_HEADERS],
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER, ACTIVITY_TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS],
    [HardwareType.UIA_BIOCIDE_FILTER, ACTIVITY_UIA_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.WAIST_TETHER, ACTIVITY_WAIST_TETHER_TABLE_HEADERS]
]);
export const ActivityTypeToHardwareTypes = new Map([
    [ActivityType.ANNUAL_CHECK_OUT, [HardwareType.EMU, HardwareType.SCOF]],
    [ActivityType.ANNUAL_CHANGE_OUT, [HardwareType.METOX, HardwareType.METOX_SIMULATOR]],
    [
        ActivityType.AUTOCYCLE,
        [HardwareType.EMU_BATTERY_LLB, HardwareType.LPGT_BATTERY, HardwareType.LREBA, HardwareType.SAFER_LPGT_BATTERY]
    ],
    [
        ActivityType.BATTERY_MAINTENANCE,
        [HardwareType.EMU, HardwareType.HL_BATTERY, HardwareType.PGT_BATTERY, HardwareType.REBA]
    ],
    [ActivityType.CALIBRATION, [HardwareType.LIB_CHARGER]],
    [
        ActivityType.CHARGE,
        [HardwareType.EMU_BATTERY_LLB, HardwareType.LPGT_BATTERY, HardwareType.LREBA, HardwareType.SAFER_LPGT_BATTERY]
    ],
    [ActivityType.CWC_DUMP, []],
    [ActivityType.DCM_MWC_FLOW_TEST, [HardwareType.EMU]],
    [
        ActivityType.DISCHARGE,
        [HardwareType.EMU_BATTERY_LLB, HardwareType.LPGT_BATTERY, HardwareType.LREBA, HardwareType.SAFER_LPGT_BATTERY]
    ],
    [ActivityType.EMU_CHECK_OUT, [HardwareType.EMU]],
    [
        ActivityType.EMU_H2O_DUMP_FILL,
        [HardwareType.EMU, HardwareType.EMU_FEEDWATER_SUPPLY_FILTER, HardwareType.UIA_BIOCIDE_FILTER]
    ],
    [
        ActivityType.EMU_H2O_RECHARGE,
        [HardwareType.EMU, HardwareType.EMU_FEEDWATER_SUPPLY_FILTER, HardwareType.UIA_BIOCIDE_FILTER]
    ],
    [ActivityType.EMU_WATER_SAMPLE, [HardwareType.EMU]],
    [
        ActivityType.EVA,
        [
            HardwareType.EHIP_LIGHT,
            HardwareType.EMU,
            HardwareType.EMU_BATTERY_LLB,
            HardwareType.EMU_BIOCIDE_FILTER,
            HardwareType.EMU_FEEDWATER_SUPPLY_FILTER,
            HardwareType.EMU_SCRUB_ASSEMBLY,
            HardwareType.EMU_WATER_PROCESSING_JUMPER,
            HardwareType.FPS,
            HardwareType.HUT_ORU,
            HardwareType.HUT_ORU_SCRUBBER,
            HardwareType.HL_BATTERY,
            HardwareType.IEU_SCU,
            HardwareType.LCVG,
            HardwareType.LPGT_BATTERY,
            HardwareType.LREBA,
            HardwareType.LIB_CHARGER,
            HardwareType.METOX,
            HardwareType.METOX_SIMULATOR,
            HardwareType.PGT,
            HardwareType.PGT_BATTERY,
            HardwareType.REBA,
            HardwareType.SAFER,
            HardwareType.SAFER_LPGT_BATTERY,
            HardwareType.SAFETY_TETHER_55,
            HardwareType.SAFETY_TETHER_85,
            HardwareType.SCOF,
            HardwareType.TETHER_EXTENSION_D_RING_EXTENDER,
            HardwareType.UIA,
            HardwareType.UIA_BIOCIDE_FILTER,
            HardwareType.WAIST_TETHER
        ]
    ],
    [ActivityType.GN2_CHECK, [HardwareType.SAFER]],
    [ActivityType.GREASE_CIRCULATE, [HardwareType.FPS]],
    [ActivityType.HELMET_INSTALL, [HardwareType.HAB_HAP_E]],
    [ActivityType.HUT_WLVTA_REMOVE_REPLACE, [HardwareType.EMU]],
    [ActivityType.INSPECTION, [...Object.values(HardwareType)]],
    [
        ActivityType.IODINATE_LOOP_SCRUB,
        [
            HardwareType.EMU,
            HardwareType.EMU_BIOCIDE_FILTER,
            HardwareType.EMU_SCRUB_ASSEMBLY,
            HardwareType.EMU_WATER_PROCESSING_JUMPER,
            HardwareType.HUT_ORU,
            HardwareType.HUT_ORU_SCRUBBER,
            HardwareType.IEU_SCU,
            HardwareType.LCVG,
            HardwareType.UIA
        ]
    ],
    [ActivityType.ITEM_146_CYCLE, [HardwareType.EMU]],
    [ActivityType.LCVG_FILL, [HardwareType.LCVG]],
    [
        ActivityType.LOOP_SCRUB,
        [
            HardwareType.EMU,
            HardwareType.EMU_BIOCIDE_FILTER,
            HardwareType.EMU_SCRUB_ASSEMBLY,
            HardwareType.IEU_SCU,
            HardwareType.LCVG
        ]
    ],
    [ActivityType.METOX_REGENERATION, [HardwareType.METOX]],
    [ActivityType.OPS_10_2, [HardwareType.METOX]],
    [ActivityType.OTHER, [...Object.values(HardwareType)]],
    [ActivityType.PWR_FILL, [HardwareType.HUT_ORU]],
    [ActivityType.PWR_VENT, []],
    [ActivityType.SAFER_CHECK_OUT, [HardwareType.SAFER]],
    [ActivityType.SAFER_TM_CHECK_OUT, [HardwareType.SAFER]],
    [ActivityType.SOP_CHECK_OUT, [HardwareType.EMU]],
    [ActivityType.SUBLIMATOR_LEAK_TEST, [HardwareType.EMU]],
    [ActivityType.TAK_DATA_APPROVAL, [HardwareType.PGT]],
    [ActivityType.TAK_DATA_COLLECTION, [HardwareType.PGT]],
    [ActivityType.HUT_WLVTA_REMOVE_REPLACE, [HardwareType.HUT_ORU]],
    [ActivityType.SSM_EXTENSION_APPROVAL, [HardwareType.HUT_ORU_SCRUBBER]]
]);
