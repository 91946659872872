import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/utils/base/BaseInput';
let AppTime = class AppTime extends BaseInput {
    minutes = null;
    hours = null;
    showInformationalText = false; // Used to toggle the blue info text
    isValid = false;
    informationText;
    // Regular textual messages
    get computedMessages() {
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return ['REQUIRED'];
        }
        if (this.isHourValid && this.isMinuteValid) {
            return [`The time will be formatted as ${this.paddedHour}:${this.paddedMinute}`];
        }
        if (this.messages) {
            return this.messages;
        }
        // show messages based on rules
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string');
    }
    get isHourValid() {
        return Boolean(this.hours && parseInt(this.hours) <= 24);
    }
    get isMinuteValid() {
        return Boolean(this.minutes && parseInt(this.minutes) <= 59);
    }
    get areInputsValid() {
        return ((this.isMinuteValid && this.errorMessages.length === 0) || (this.isHourValid && this.errorMessages.length === 0));
    }
    get isValueNullOrUndefined() {
        return !!(this.value === null || this.value === undefined);
    }
    get paddedHour() {
        return this.hours && this.hours.length === 1 ? `0${this.hours}` : this.hours;
    }
    get paddedMinute() {
        return this.minutes && this.minutes.length === 1 ? `0${this.minutes}` : this.minutes;
    }
    /**
     * Show if value == null/undefined AND every field is individually not valid
     */
    get shouldShowNoTimeEnteredHelperIcon() {
        return this.isValueNullOrUndefined && !this.isHourValid && !this.isMinuteValid;
    }
    /**
     * Show if value == null/undefined AND one of the fields is VALID AND overall the control isnt valid
     */
    get shouldShowInvalidTimeHelperIcon() {
        return this.isValueNullOrUndefined && (this.isHourValid || this.isMinuteValid) && !this.areInputsValid;
    }
    /**
     * Show if value != null/undefined AND everything is valid
     */
    get shouldShowValidTimeHelperIcon() {
        return !this.isValueNullOrUndefined && this.areInputsValid;
    }
    clearInput(inputName) {
        this[inputName] = null;
    }
    clearInputs() {
        const inputs = ['hours', 'minutes'];
        inputs.forEach((inputName) => (this[inputName] = null));
    }
    formatInputWithTwoDigits(val) {
        return val !== undefined && val !== null && val !== 'null' && val.length === 1 ? `0${val}` : `${val}`;
    }
    setIsValid(val) {
        this.isValid = val;
    }
    emitInput(val) {
        return this.areInputsValid ? `${this.paddedHour}:${this.paddedMinute}` : null;
    }
    updateTime(val) {
        if (this.value && !this.isValueNullOrUndefined) {
            const [_hours, _minutes] = this.value.split(':');
            this.hours = _hours && _hours !== 'null' ? this.formatInputWithTwoDigits(_hours) + '' : '0';
            this.minutes = _minutes && _minutes !== 'null' ? this.formatInputWithTwoDigits(_minutes) + '' : '0';
        }
        // clear the inputs when passed a value of null and not typing in the inputs
        if (val === null) {
            if (this.shouldShowInvalidTimeHelperIcon) {
                // don't clear when typing in the inputs and its invalid
            }
            else {
                // clear inputs when passed null
                this.clearInputs();
            }
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppTime.prototype, "informationText", void 0);
__decorate([
    Prop({ default: '', type: String }),
    __metadata("design:type", String)
], AppTime.prototype, "value", void 0);
__decorate([
    Watch('hours'),
    Watch('minutes'),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppTime.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppTime.prototype, "updateTime", null);
AppTime = __decorate([
    Component
], AppTime);
export default AppTime;
