import get from 'lodash.get';
export const sortObjectBy = (sortBy, desc = false) => {
    return (a, b) => {
        try {
            const sortByA = get(a, sortBy);
            const sortByB = get(b, sortBy);
            const order = desc ? 1 : -1;
            if (sortByA === sortByB) {
                return 0;
            }
            return sortByA > sortByB ? -1 * order : 1 * order;
        }
        catch (err) {
            return 0;
        }
    };
};
