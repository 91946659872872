export var Side;
(function (Side) {
    Side["LEFT"] = "LEFT";
    Side["RIGHT"] = "RIGHT";
    Side["NONE"] = "NONE";
})(Side || (Side = {}));
export const HandDominanceDisplay = new Map([
    [Side.LEFT, 'L'],
    [Side.RIGHT, 'R'],
    [Side.NONE, 'n/a']
]);
