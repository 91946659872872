/* unplugin-vue-components disabled */import __unplugin_components_8 from '/app/src/components/buttons/ButtonDelete/ButtonDelete.vue';
import __unplugin_components_7 from '/app/src/components/buttons/ButtonCancel/ButtonCancel.vue';
import __unplugin_components_6 from '/app/src/components/buttons/ButtonSave/ButtonSave.vue';
import __unplugin_components_5 from '/app/src/components/buttons/ButtonSave/ButtonSave.vue';
import __unplugin_components_4 from '/app/src/components/buttons/ButtonCreate/ButtonCreate.vue';
import __unplugin_components_3 from '/app/src/components/ReadOnlyEVA/ReadOnlyEVA.vue';
import __unplugin_components_2 from '/app/src/components/Alert/Alert.vue';
import __unplugin_components_1 from '/app/src/components/EvaForm/EvaForm.vue';
import __unplugin_components_0 from '/app/src/components/AppModal/AppModal.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{attrs:{"color":"events"},on:{"modal-close":_vm.emitModalClose},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.isEditing ? 'Update' : 'Create')+" "+_vm._s(_vm.editEventName || 'EVA')+" ")]},proxy:true},{key:"content",fn:function(){return [(_vm.isActive || !_vm.isEditing)?_c(__unplugin_components_1,{attrs:{"form-data":_vm.formData,"is-editing":_vm.isEditing,"comments":_vm.event && _vm.event.comments}}):_vm._e(),(_vm.event && !_vm.isActive)?_c('div',[_c(__unplugin_components_2,{attrs:{"type":"warning"}},[_c('p',[_vm._v(" This EVA is not currently active. If you would like to make changes, you must first make this EVA active. ")])]),_c(__unplugin_components_3,{attrs:{"event-data":_vm.event}})],1):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('v-flex',{attrs:{"xs6":""}},[(!_vm.isEditing)?_c(__unplugin_components_4,{staticClass:"ml-0 mr-2",attrs:{"disabled":_vm.disableCreateButton},on:{"click":_vm.onClickOfCreate}},[_vm._v(" Create EVA ")]):_vm._e(),(_vm.isEditing && _vm.isActive)?_c(__unplugin_components_5,{staticClass:"ml-0 mr-2",attrs:{"disabled":_vm.disableSaveButton},on:{"click":_vm.onClickOfUpdate}},[_vm._v(" Update "+_vm._s(_vm.editEventName)+" ")]):_vm._e(),(_vm.isEditing && !_vm.isActive)?_c(__unplugin_components_6,{staticClass:"ml-0 mr-2",on:{"click":_vm.onClickOfReactivate}},[_vm._v(" Reactivate "+_vm._s(_vm.editEventName)+" ")]):_vm._e(),_c(__unplugin_components_7,{on:{"click":_vm.emitModalClose}})],1),(_vm.isEditing)?_c('v-flex',{attrs:{"xs6":"","text-xs-right":""}},[_c(__unplugin_components_8,{on:{"click":_vm.onClickOfDelete}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }