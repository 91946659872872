/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ActivitiesTable.vue?vue&type=template&id=61f8fd14"
import script from "./ActivitiesTable.ts?vue&type=script&lang=ts&external"
export * from "./ActivitiesTable.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports