import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ReportButton = class ReportButton extends Vue {
    cssClass;
    disabled;
    href;
    title;
    icon;
    reportIcon = this.icon || this.$icons.reporting;
    get computedClass() {
        return `ml-0 ${this.cssClass}`;
    }
    onClick() {
        this.$emit('click');
    }
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ReportButton.prototype, "cssClass", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ReportButton.prototype, "disabled", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ReportButton.prototype, "href", void 0);
__decorate([
    Prop({
        default: 'View Report',
        type: String
    }),
    __metadata("design:type", String)
], ReportButton.prototype, "title", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ReportButton.prototype, "icon", void 0);
ReportButton = __decorate([
    Component
], ReportButton);
export default ReportButton;
