import Vue from 'vue';
import Vuex from 'vuex';
import pkg from '../../package.json';
import eventModule, { EventModuleName } from './event';
import hardwareModule, { HardwareModuleName } from './hardware';
import userModule, { UserModuleName } from './user';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        name: 'OOT',
        version: pkg.version
    },
    modules: {
        [EventModuleName]: eventModule,
        [HardwareModuleName]: hardwareModule,
        [UserModuleName]: userModule
    }
});
