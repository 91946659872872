import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { CENTRAL_TIME_ZONE } from '~/types';
import { DEFAULT_DASH } from '../constants';
/**
 * Returns a "central-zoned" date string.
 *
 * The display will assume that the date given is in UTC. If provided a date
 * string with an offset, it will follow the offset. If the date to be displayed
 * is already in central, pass false to the third parameter.
 *
 * @param date The date to display.
 * @param displayValue Default display value.
 * @param isUtc Is the date argument in UTC?
 * @returns A date string in the format `dd-MMM-yyyy` (Ex: 20-Aug-2018)
 */
export const reportDateDisplay = (date, displayValue = DEFAULT_DASH, isUtc = true) => {
    if (!date) {
        return displayValue;
    }
    const zonedTime = isUtc ? utcToZonedTime(date, CENTRAL_TIME_ZONE) : toDate(date, { timeZone: CENTRAL_TIME_ZONE });
    return format(zonedTime, 'dd-MMM-yyyy');
};
