import { AlertType } from '~/types';
import { AMOUNT_WATER_COL, FEMUR_COMMENTS_COL, FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL, SERIAL_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addBaseProperties, addFemurComments, addLaunchReturn } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const UIA_BIOCIDE_FILTER_TABLE_HEADERS = [...STANDARD_COLS, AMOUNT_WATER_COL];
export const ACTIVITY_UIA_BIOCIDE_FILTER_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    AMOUNT_WATER_COL
];
export const FEMUR_UIA_BIOCIDE_FILTER_TABLE_HEADERS = [
    SERIAL_COL,
    AMOUNT_WATER_COL,
    FEMUR_FLIGHT_UP_COL,
    FEMUR_FLIGHT_DOWN_COL,
    FEMUR_COMMENTS_COL
];
export const transformUiaBiocideFilter = (items) => {
    return items
        .map(addBaseProperties)
        .map(addFemurComments)
        .map(addLaunchReturn)
        .map((item) => {
        const status = item.status;
        const volume = status?.volume?.value || 0;
        const rounded = (Math.round(volume * 100) / 100).toFixed(2).toString();
        const _volume = alertableStatusDisplay(status.volume, rounded);
        // adding row class based on greaseCirculateDueDate
        const rowClass = item.status?.volume?.alertType === AlertType.ALERT
            ? 'red lighten-3'
            : item.status?.volume?.alertType === AlertType.WARNING
                ? 'orange lighten-3'
                : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _volume
        });
        return _item;
    });
};
