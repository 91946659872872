var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { BaseModalMixin } from '~/mixins';
import { CommentType, CommentTypeDisplay, Maybe } from '~/types';
import { ErrorUtility, ICommentsDisplay } from '~/utils';
let ModalComment = class ModalComment extends Mixins(BaseModalMixin) {
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    formData = {
        commentText: '',
        subType: CommentType.GENERAL
    };
    activityId;
    comment;
    commentsToBlacklist;
    commentTypeOverride;
    eventId;
    itemInstanceId;
    get commentPayload() {
        if (!this.isFormValid) {
            return null;
        }
        return {
            activityId: this.activityId,
            commentText: this.formData.commentText.trim() || '',
            eventId: this.eventId,
            itemInstanceId: this.itemInstanceId,
            subType: this.formData.subType
        };
    }
    get hasAtleastOneId() {
        return Boolean(this.activityId || this.eventId || this.itemInstanceId);
    }
    get isEditing() {
        return Boolean(this.comment);
    }
    get isFormValid() {
        return Boolean(this.formData.subType && this.formData.commentText.trim().length && this.hasAtleastOneId);
    }
    get shouldDisableCreateCommentButton() {
        return !this.isFormValid;
    }
    createEntity(payload) {
        return this.$http.post('/comments', payload);
    }
    async onClickOfCreate() {
        try {
            if (this.commentPayload) {
                const resp = await this.createEntity(this.commentPayload);
                if (resp.data) {
                    this.emitEntityCreated('Comment created.');
                }
                this.closeDialog();
            }
        }
        catch (err) {
            this.closeDialog();
            ErrorUtility({
                err
            });
        }
    }
    async onClickOfEdit() {
        try {
            if (this.commentPayload && this.comment) {
                const resp = await this.editEntity(this.commentPayload, this.comment.id);
                if (resp.data) {
                    this.emitEntityEdited('Comment updated.');
                }
                this.closeDialog();
            }
        }
        catch (err) {
            this.closeDialog();
            ErrorUtility({
                err
            });
        }
    }
    async onClickOfDelete() {
        try {
            if (this.comment) {
                const resp = await this.deleteEntity(this.comment.id);
                this.emitEntityDeleted('Comment deleted.');
                this.closeDialog();
            }
        }
        catch (err) {
            this.closeDialog();
            ErrorUtility({
                err
            });
        }
    }
    editEntity(payload, id) {
        return this.$http.put(`/comments/${id}`, payload);
    }
    deleteEntity(id) {
        return this.$http.delete(`/comments/${id}`);
    }
    fetchEntity(id) {
        throw new Error('Method not implemented.');
    }
    onChangeOfCommentTypeOverride(val) {
        if (val && this.formData) {
            this.formData.subType = val;
        }
    }
    onChangeOfComment(val) {
        if (val && val.id) {
            this.formData = val;
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalComment.prototype, "activityId", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof ICommentsDisplay !== "undefined" && ICommentsDisplay) === "function" ? _a : Object)
], ModalComment.prototype, "comment", void 0);
__decorate([
    Prop({
        required: true,
        type: Array,
        default: []
    }),
    __metadata("design:type", typeof (_b = typeof Array !== "undefined" && Array) === "function" ? _b : Object)
], ModalComment.prototype, "commentsToBlacklist", void 0);
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], ModalComment.prototype, "commentTypeOverride", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalComment.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalComment.prototype, "itemInstanceId", void 0);
__decorate([
    Watch('commentTypeOverride', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], ModalComment.prototype, "onChangeOfCommentTypeOverride", null);
__decorate([
    Watch('comment', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], ModalComment.prototype, "onChangeOfComment", null);
ModalComment = __decorate([
    Component
], ModalComment);
export default ModalComment;
