import { EntityType, EventType } from '~/types';
import { dateDisplay, sortObjectBy } from '~/utils';
import BaseController from '~/utils/base/BaseController';
import { buildChipAsString } from '~/utils/ui/buildChipAsString';
const { $http } = new BaseController();
export const EventModuleName = 'event';
export var StateTypes;
(function (StateTypes) {
    StateTypes["flights"] = "flights";
})(StateTypes || (StateTypes = {}));
const state = {
    flights: []
};
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["setFlights"] = "setFlights";
})(MutationTypes || (MutationTypes = {}));
const mutations = {
    [MutationTypes.setFlights](state, payload) {
        state.flights = payload;
    }
};
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["getFlights"] = "getFlights";
})(ActionTypes || (ActionTypes = {}));
const actions = {
    async [ActionTypes.getFlights]({ commit }) {
        const { data } = await $http.get('/events/flights?includeInactive=false');
        commit('setFlights', data);
    }
};
export var GetterTypes;
(function (GetterTypes) {
    GetterTypes["flightsDown"] = "flightsDown";
    GetterTypes["flightsDownOptions"] = "flightsDownOptions";
    GetterTypes["flightsUp"] = "flightsUp";
    GetterTypes["flightsUpOptions"] = "flightsUpOptions";
})(GetterTypes || (GetterTypes = {}));
const buildFlightSelectOption = (flight, flightType) => {
    const chipOptions = {
        chipClass: EntityType.EVENT,
        iconColor: EntityType.EVENT,
        dense: true
    };
    // Options for the UP chip
    const upChipOptions = Object.assign({}, chipOptions, {
        chipText: dateDisplay(flight.launchDate),
        chipIconClass: `fa fa-arrow-up fa-sm`
    });
    const upDateChipHtml = buildChipAsString(upChipOptions);
    // Options for the DOWN chip
    const downChipOptions = Object.assign({}, chipOptions, {
        chipText: dateDisplay(flight.returnDate),
        chipIconClass: `fa fa-arrow-down fa-sm`
    });
    const downDateChipHtml = buildChipAsString(downChipOptions);
    const value = flightType === EventType.LAUNCH ? flight.launchEventId : flight.returnEventId;
    return {
        displayText: `
        <div class="d-flex align-center">
          ${flight.name} 
          <div class="text-xs-right">
            ${upDateChipHtml} ${downDateChipHtml}
          </div>
        </div>`,
        value
    };
};
const getters = {
    [GetterTypes.flightsDown]({ flights }) {
        return flights.filter((f) => Boolean(f.returnEventId));
    },
    [GetterTypes.flightsDownOptions](_, { flightsDown }) {
        // These already have the inactives removed from the api call
        // Sort them by return date
        flightsDown.sort(sortObjectBy('returnDate', true));
        return flightsDown.map((f) => buildFlightSelectOption(f, EventType.RETURN));
    },
    [GetterTypes.flightsUp]({ flights }) {
        return flights.filter((f) => Boolean(f.launchEventId));
    },
    [GetterTypes.flightsUpOptions](_, { flightsUp }) {
        // These already have the inactives removed from the api call
        // Sort them by launchDate
        flightsUp.sort(sortObjectBy('launchDate', true));
        return flightsUp.map((f) => buildFlightSelectOption(f, EventType.LAUNCH));
    }
};
const eventModule = {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
export default eventModule;
