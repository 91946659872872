var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EntityType, EventType, EventTypeDisplay } from '~/types';
import { IEventsDisplay, transformEvents, transformIncrements } from '~/utils';
let EventMini = class EventMini extends Vue {
    entityType = EntityType.EVENT;
    eventData;
    eventType;
    get subType() {
        if (!this.eventType) {
            return;
        }
        return EventTypeDisplay.get(this.eventType) || '-';
    }
    get title() {
        if (this.subType?.toUpperCase() === EventType.INCREMENT) {
            return this.eventData.increment?.name || this.eventData.name;
        }
        return this.eventData?.name;
    }
    get computedIcon() {
        return this.subType ? this.$icons[this.subType] || this.$icons[this.eventType] : this.$icons[this.eventType];
    }
    get flightItems() {
        const transformedEvent = transformEvents([this.eventData])[0];
        return [
            {
                name: 'Launch',
                value: transformedEvent._launchDate
            },
            {
                name: 'Return',
                value: transformedEvent._returnDate
            },
            {
                name: 'Is Active?',
                title: transformedEvent.isActive ? 'Active Flight' : 'Inactive Flight',
                value: transformedEvent.isActive
                    ? transformedEvent._isActive?.replace('success--text', 'white--text')
                    : transformedEvent._isActive?.replace('secondary--text', 'white--text')
            }
        ];
    }
    get evaItems() {
        const transformedEvent = transformEvents([this.eventData])[0];
        return [
            {
                name: 'Start',
                value: transformedEvent._startDate
            },
            {
                name: 'End',
                value: transformedEvent._endDate
            },
            {
                name: 'Is Active?',
                title: transformedEvent.isActive ? 'Active EVA' : 'Inactive EVA',
                value: transformedEvent.isActive
                    ? transformedEvent._isActive?.replace('success--text', 'white--text')
                    : transformedEvent._isActive?.replace('secondary--text', 'white--text')
            }
        ];
    }
    get incrementItems() {
        const transformedEvent = transformIncrements([this.eventData])[0];
        return [
            {
                name: 'Num of Evas',
                value: transformedEvent._numOfEvas?.toString()
            },
            {
                name: 'Start',
                value: transformedEvent._startDate
            },
            {
                name: 'End',
                value: transformedEvent._endDate
            },
            {
                name: 'Is Active?',
                title: transformedEvent.isActive ? 'Active Increment' : 'Inactive Increment',
                value: transformedEvent.isActive
                    ? transformedEvent._isActive?.replace('success--text', 'white--text')
                    : transformedEvent._isActive?.replace('secondary--text', 'white--text')
            }
        ];
    }
    get verticalLabelDisplayItems() {
        if (!this.eventData) {
            return [];
        }
        let items;
        switch (this.eventType) {
            case EventType.LAUNCH:
            case EventType.RETURN:
                items = this.flightItems;
                break;
            case EventType.EVA:
                items = this.evaItems;
                break;
            case EventType.INCREMENT:
                items = this.incrementItems;
                break;
            default:
                items = [];
                break;
        }
        items.push({
            name: 'Type',
            value: this.subType
        });
        return items;
    }
};
__decorate([
    Prop({
        default: {},
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof IEventsDisplay !== "undefined" && IEventsDisplay) === "function" ? _a : Object)
], EventMini.prototype, "eventData", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof EventType !== "undefined" && EventType) === "function" ? _b : Object)
], EventMini.prototype, "eventType", void 0);
EventMini = __decorate([
    Component
], EventMini);
export default EventMini;
