var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Maybe } from '~/types';
export var FilterTypes;
(function (FilterTypes) {
    FilterTypes["ONLY_ACTIVE"] = "ONLY_ACTIVE";
    FilterTypes["ALL_ITEMS"] = "ALL_ITEMS";
})(FilterTypes || (FilterTypes = {}));
let EventsFilterToggle = class EventsFilterToggle extends Vue {
    FilterTypes = FilterTypes;
    showHasItemInstancesToggle;
    value;
    onToggleChange(value) { }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], EventsFilterToggle.prototype, "showHasItemInstancesToggle", void 0);
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], EventsFilterToggle.prototype, "value", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], EventsFilterToggle.prototype, "onToggleChange", null);
EventsFilterToggle = __decorate([
    Component
], EventsFilterToggle);
export default EventsFilterToggle;
