/* unplugin-vue-components disabled */import __unplugin_components_6 from '/app/src/components/ReadOnlyComments/ReadOnlyComments.vue';
import __unplugin_components_5 from '/app/src/components/HardwarePlannedActivities/HardwarePlannedActivities.vue';
import __unplugin_components_4 from '/app/src/components/ActivitiesTable/ActivitiesTable.vue';
import __unplugin_components_3 from '/app/src/components/EventsTable/EventsTable.vue';
import __unplugin_components_2 from '/app/src/components/AppHardwareDisplay/AppHardwareDisplay.vue';
import __unplugin_components_1 from '/app/src/components/AppLoader/AppLoader.vue';
import __unplugin_components_0 from '/app/src/components/ItemInstanceMini/ItemInstanceMini.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.queryResult)?_c('div',[_c(__unplugin_components_0,{staticClass:"mb-2",attrs:{"item-instance":_vm.queryResult}}),_c(__unplugin_components_1,{attrs:{"show":!_vm.queryResult,"color":"item_masters","type":"linear"}}),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.transformedItemInstance)?_c('div',{staticClass:"entity_content mb-3"},[_c(__unplugin_components_2,{attrs:{"data":_vm.transformedItemInstance,"show-edit-button":false}})],1):_vm._e()]),_c(__unplugin_components_3,{attrs:{"events":_vm.events},on:{"rowClicked":_vm.onClickOfEventTableRow}}),_c(__unplugin_components_4,{attrs:{"activities":_vm.activities,"activity-comments":_vm.activityComments,"hardware-sub-type":_vm.hardwareSubType},on:{"row-click":_vm.onClickOfActivityTableRow}}),_c('div',{staticClass:"ml-2 mb-5"},[_c(__unplugin_components_5,{attrs:{"planned-activities":_vm.transformedItemInstance.plannedActivities}})],1),_c(__unplugin_components_6,{attrs:{"comments":_vm.comments,"displayed-comment-types":_vm.shownCommentTypes}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }