var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { HardwareType, HardwareTypeDisplay } from '~/types';
import { ErrorUtility } from '~/utils';
import HardwareController from '~/utils/HardwareController';
let AppHardwareSelect = class AppHardwareSelect extends Vue {
    hardwareItems = [];
    loading = false;
    hardwareType;
    get selectOptions() {
        return this.hardwareItems.map((item) => ({
            displayText: `${HardwareTypeDisplay.get(item.subType)}: ${item.serialNumber}`,
            value: item.id
        }));
    }
    async onHardwareTypeChange(hardwareType) {
        this.loading = true;
        try {
            const controller = new HardwareController(hardwareType);
            const { data } = await controller.fetchAllData(false);
            this.hardwareItems = data;
        }
        catch (err) {
            ErrorUtility({
                err,
                backupErrorText: `There was a problem getting ${HardwareTypeDisplay.get(hardwareType)} data`
            });
        }
        this.loading = false;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _a : Object)
], AppHardwareSelect.prototype, "hardwareType", void 0);
__decorate([
    Watch('hardwareType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], AppHardwareSelect.prototype, "onHardwareTypeChange", null);
AppHardwareSelect = __decorate([
    Component
], AppHardwareSelect);
export default AppHardwareSelect;
