import { EventTypeDisplay } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { COMMENTS_COL, END_DATE_COL, IS_ACTIVE_COL, LAST_UPDATE_BY_COL, LAST_UPDATE_DATE_COL, NAME_COL, START_DATE_COL, SUBTYPE_COL } from '../genericTableColumns';
import { booleanDisplay } from '../helpers/displays';
import { sortObjectBy } from '../helpers/sortObjectBy';
import { addComments, addComputedIncrements, addDateDisplays, addItemInstances, addItemInstancesUpDown, addLastUpdated } from '../mappers';
import { buildEventChip } from '../ui/buildChipAsString';
// For a generic "Event" table
export const EVENT_TABLE_HEADERS = [
    IS_ACTIVE_COL,
    NAME_COL,
    SUBTYPE_COL,
    START_DATE_COL,
    END_DATE_COL,
    LAST_UPDATE_DATE_COL,
    LAST_UPDATE_BY_COL
];
// EVA EventType specific
export const ITEM_INSTANCE_COL = {
    text: 'Item Instances',
    value: '_itemInstances',
    sortable: false,
    noEscape: true
};
export const EVAS_COL = {
    text: 'EVAs',
    value: '_evasDisplay',
    noEscape: true
};
export const EVA_DATE_COL = {
    text: 'EVA Start',
    value: '_startDate',
    sortable: true,
    noWrap: true,
    headerSortType: HeaderSortOptions.DATE
};
export const INCREMENT_COL = {
    text: 'Increment',
    value: '_increments',
    sortable: true,
    noEscape: true
};
export const EVA_TABLE_HEADERS = [
    IS_ACTIVE_COL,
    Object.assign({}, NAME_COL, { noWrap: true }),
    ITEM_INSTANCE_COL,
    EVA_DATE_COL,
    INCREMENT_COL,
    COMMENTS_COL
];
// Increment EventType specific
export const INCREMENT_TABLE_HEADERS = [
    IS_ACTIVE_COL,
    Object.assign({}, NAME_COL, { noWrap: true }),
    START_DATE_COL,
    END_DATE_COL,
    EVAS_COL
];
// Flight EventType specific
export const FLIGHT_TABLE_HEADERS = [
    IS_ACTIVE_COL,
    Object.assign({}, NAME_COL, { noWrap: true }),
    {
        text: 'Launch',
        value: '_launchDate'
    },
    {
        text: 'Up<br/>Item Instances',
        value: '_upItemInstances',
        sortable: false,
        noEscape: true
    },
    {
        text: 'Return',
        value: '_returnDate'
    },
    {
        text: 'Down<br/>Item Instances',
        value: '_downItemInstances',
        sortable: false,
        noEscape: true
    }
];
export const transformEvents = (items) => {
    return items
        .map(addDateDisplays)
        .map(addLastUpdated)
        .map(addItemInstances)
        .map(addItemInstancesUpDown)
        .map(addComments)
        .map(addComputedIncrements)
        .map((item) => {
        return {
            _name: item?.name || '-',
            _subType: EventTypeDisplay.get(item?.subType),
            _isActive: booleanDisplay(item?.isActive),
            _numOfEvas: item?.evas?.length,
            ...item
        };
    })
        .sort(sortObjectBy('_startDate', true));
};
export const transformIncrements = (items, reportFormat = false) => {
    return items
        .map((item) => {
        const evas = item.evas || [];
        const increment = item.increment;
        if (!increment) {
            return item;
        }
        const evasDisplay = evas.map(buildEventChip).sort(sortObjectBy('_name', true));
        evasDisplay.push(`<div class="my-2">Total EVAs assigned to ${increment.name}: <span class="font-weight-bold monospace_font">${evas.length}</span></div>`);
        return {
            _name: increment.name || '-',
            _subType: increment.subType ? EventTypeDisplay.get(increment.subType) : '-',
            _isActive: reportFormat ? Boolean(increment?.isActive).toString() : booleanDisplay(increment.isActive),
            ...addDateDisplays(increment),
            _evas: transformEvents(evas),
            _numOfEvas: evas.length,
            _evasDisplay: reportFormat
                ? evas
                    .map((eva) => eva.name)
                    .sort()
                    .join('\n') || 'No EVAs logged.'
                : evasDisplay.join(''),
            ...item
        };
    })
        .sort(sortObjectBy('_startDate', true));
};
