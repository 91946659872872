import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseInput from '~/utils/base/BaseInput';
import { isNullOrUndefined } from '~/utils/helpers/isNullOrUndefined';
let AppNumber = class AppNumber extends BaseInput {
    lowestMinAllowed = -Infinity;
    highestMaxAllowed = Infinity;
    min;
    max;
    step;
    wholeNumber;
    allowNegatives;
    get numRules() {
        return {
            isNumber: (value) => {
                if (value === undefined || value === null) {
                    return true;
                }
                const pattern = /^\d*\.?\d*$/;
                if (!pattern.test(Math.abs(value).toString())) {
                    return `MUST BE A NUMBER`;
                }
                return true;
            },
            min: (value) => {
                if (value === undefined || value === null) {
                    return true;
                }
                return value >= this.computedMin || `NUMBER MUST BE ≥ ${this.computedMin}`;
            },
            max: (value) => {
                if (value === undefined || value === null) {
                    return true;
                }
                return value <= this.computedMax || `NUMBER MUST BE ≤ ${this.computedMax}`;
            },
            wholeNumber: (value) => {
                if (value === undefined || value === null || value === '') {
                    return true;
                }
                const pattern = /^\d+$/;
                return pattern.test(value) ? true : 'INVALID NUMBER. WHOLE NUMBERS ONLY';
            },
            required: (value) => {
                if (value === undefined || value === null) {
                    return 'REQUIRED';
                }
                return true;
            }
        };
    }
    get computedMin() {
        return !isNullOrUndefined(this.min) && this.min >= this.lowestMinAllowed ? this.min : this.lowestMinAllowed;
    }
    get computedMax() {
        return !isNullOrUndefined(this.max) && this.max <= this.highestMaxAllowed ? this.max : this.highestMaxAllowed;
    }
    get localRulesBaseOnProps() {
        const rules = [...this.rules];
        if (this.preComputedRules.length > 0) {
            this.preComputedRules.forEach((preComputedRule) => rules.push(preComputedRule));
        }
        rules.push(this.numRules.isNumber);
        if (this.required) {
            rules.push(this.numRules.required);
        }
        if (!this.allowNegatives) {
            rules.push(this.numRules.min);
        }
        rules.push(this.numRules.max);
        if (this.wholeNumber) {
            rules.push(this.numRules.wholeNumber);
        }
        return rules;
    }
    get propsWithComputedMinMax() {
        return Object.assign({
            ...this.$props,
            min: this.computedMin,
            max: this.computedMax
        });
    }
    emitError(val) { }
    emitInput(val) {
        if (val === '' || val === null || val === undefined) {
            return null;
        }
        // Make sure this thing is a number (any number) before emitting
        let pattern;
        if (this.wholeNumber) {
            pattern = /^\d+$/;
        }
        else {
            pattern = /^\d*\.?\d*$/;
        }
        if (!pattern.test(val)) {
            return null;
        }
        return +val;
    }
};
__decorate([
    Prop({ default: 0, type: [Number, String] }),
    __metadata("design:type", Number)
], AppNumber.prototype, "min", void 0);
__decorate([
    Prop({ default: Infinity, type: [Number, String] }),
    __metadata("design:type", Number)
], AppNumber.prototype, "max", void 0);
__decorate([
    Prop({ default: 1, type: [Number, String] }),
    __metadata("design:type", Number)
], AppNumber.prototype, "step", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppNumber.prototype, "wholeNumber", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppNumber.prototype, "allowNegatives", void 0);
__decorate([
    Emit('error'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppNumber.prototype, "emitError", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppNumber.prototype, "emitInput", null);
AppNumber = __decorate([
    Component
], AppNumber);
export default AppNumber;
