var _a, _b;
import { __decorate, __metadata } from "tslib";
import { cloneDeep } from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { IHardwareFormBase } from '~/types';
import { FormControl } from '~/utils/forms/types';
let AttributesFormPartial = class AttributesFormPartial extends Vue {
    ControlTypes = FormControl;
    // this property represents `attributes` on the item instance. it's not easily
    // typed so just leaving it as an any.
    localAttributes = {};
    isLoading;
    formData;
    formPartials;
    // if isLoading is true then it means we're waiting on pre-pop form data
    onChangeOfIsLoading(isLoading) {
        // if isLoading is true, do nothing
        if (isLoading) {
            return;
        }
        // else, we have pre-pop data... clone it to local state
        this.localAttributes = cloneDeep(this.formData.attributes);
    }
    onChangeOfLocalAttributes(localAttributes) {
        return Object.assign({}, this.formData, {
            attributes: localAttributes
        });
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AttributesFormPartial.prototype, "isLoading", void 0);
__decorate([
    Prop({ required: true }),
    __metadata("design:type", typeof (_a = typeof IHardwareFormBase !== "undefined" && IHardwareFormBase) === "function" ? _a : Object)
], AttributesFormPartial.prototype, "formData", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], AttributesFormPartial.prototype, "formPartials", void 0);
__decorate([
    Watch('isLoading', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AttributesFormPartial.prototype, "onChangeOfIsLoading", null);
__decorate([
    Emit('input'),
    Watch('localAttributes', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Partial !== "undefined" && Partial) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AttributesFormPartial.prototype, "onChangeOfLocalAttributes", null);
AttributesFormPartial = __decorate([
    Component
], AttributesFormPartial);
export default AttributesFormPartial;
