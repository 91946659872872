var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/types';
import { AlertType } from '~/types/schemas';
import { ErrorUtility, MAINTENANCE_SUMMARY_TABLE_COLS } from '~/utils';
import HardwareController from '~/utils/HardwareController';
let PendingMaintenanceTable = class PendingMaintenanceTable extends Vue {
    isFetching = false;
    hydratedItemInstance = null;
    selectedRow = null;
    showCreateActivityModal = false;
    createActivityIsPlanning = false;
    AlertType = AlertType;
    items;
    get computedTableHeaders() {
        return [...MAINTENANCE_SUMMARY_TABLE_COLS];
    }
    onClickOfCreateActivity(isPlanning) {
        this.createActivityIsPlanning = Boolean(isPlanning);
        this.showCreateActivityModal = true;
    }
    onCloseOfCreateActivityModal() {
        this.showCreateActivityModal = false;
    }
    onEntityCreated() {
        this.selectedRow = null;
        this.onCloseOfCreateActivityModal();
    }
    async onTableRowSelection() {
        if (!this.selectedRow) {
            this.hydratedItemInstance = null;
            return;
        }
        try {
            if (this.selectedRow?.itemInstance?.subType && this.selectedRow.itemInstance.id) {
                this.isFetching = true;
                const hardwareController = new HardwareController(this.selectedRow.itemInstance.subType);
                // This call should return to us the attributes and status which is missing from the original mapping
                const resp = await hardwareController.fetchSingleData(this.selectedRow.itemInstance.id);
                this.isFetching = false;
                if (resp.data) {
                    this.hydratedItemInstance = hardwareController.transformItemForTable([resp.data])[0];
                }
            }
        }
        catch (err) {
            this.isFetching = false;
            ErrorUtility({
                err
            });
        }
    }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], PendingMaintenanceTable.prototype, "items", void 0);
__decorate([
    Emit('entityCreated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PendingMaintenanceTable.prototype, "onEntityCreated", null);
__decorate([
    Watch('selectedRow'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], PendingMaintenanceTable.prototype, "onTableRowSelection", null);
PendingMaintenanceTable = __decorate([
    Component
], PendingMaintenanceTable);
export default PendingMaintenanceTable;
