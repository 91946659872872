import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/utils/base/BaseButton';
let ButtonSave = class ButtonSave extends BaseButton {
    get computedIcon() {
        return this.$slots.icon ? null : this.icon || this.$icons.save;
    }
};
__decorate([
    Prop({
        default: 'success',
        type: String
    }),
    __metadata("design:type", String)
], ButtonSave.prototype, "color", void 0);
ButtonSave = __decorate([
    Component
], ButtonSave);
export default ButtonSave;
