var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ActionTypes, HardwareModuleName, StateTypes } from '~/store/hardware';
import { HardwareType } from '~/types';
import { ErrorUtility, READ_ONLY_HARDWARE_TYPES, sortObjectBy } from '~/utils';
import BaseTable from '~/utils/base/BaseTable';
import { alertableFields, alertFieldToText } from '~/utils/constants/alertableFields';
import { FEMUR_FLIGHT_DOWN_COL, FEMUR_FLIGHT_UP_COL } from '~/utils/genericTableColumns';
import HardwareController from '~/utils/HardwareController';
const hardwareModule = namespace(HardwareModuleName);
let HardwareTypeOverviewTable = class HardwareTypeOverviewTable extends BaseTable {
    isFetching = false;
    isViewingArchive = false;
    entities;
    hardwareController;
    hardwareType;
    fetchEntities;
    get alertableFields() {
        return alertableFields[this.hardwareType].map(alertFieldToText);
    }
    get archivedHardware() {
        return this.tableItems.filter((item) => Boolean(item.isArchived));
    }
    get computedItems() {
        const items = this.isViewingArchive ? this.archivedHardware : this.currentHardware;
        return items.sort(sortObjectBy('serialNumber', true));
    }
    get computedItemsOnGround() {
        return this.computedItems.filter((item) => !item.status?.isOnOrbit?.value);
    }
    get computedItemsOnOrbit() {
        return this.computedItems.filter((item) => item.status?.isOnOrbit?.value);
    }
    get currentHardware() {
        return this.tableItems.filter((item) => Boolean(!item.isArchived));
    }
    get hasArchivedHardware() {
        return Boolean(this.archivedHardware.length);
    }
    get shouldHidePagination() {
        return false;
    }
    get tableHeaders() {
        const headers = [...this.hardwareController.tableHeaderDefinitions];
        if (this.isViewingArchive) {
            headers.splice(2, 0, FEMUR_FLIGHT_UP_COL, FEMUR_FLIGHT_DOWN_COL);
        }
        return headers;
    }
    get tableItems() {
        return this.hardwareController.transformItemForTable(this.entities).sort(sortObjectBy('_serial', true));
    }
    async getHardware(includeArchive) {
        try {
            this.isFetching = true;
            await this.fetchEntities(includeArchive);
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            ErrorUtility({
                err
            });
        }
    }
    async onClickOfArchiveToggle() {
        this.isViewingArchive = !this.isViewingArchive;
        return this.getHardware(this.isViewingArchive);
    }
    onClickOfRow(val) {
        if (!val.id) {
            return null;
        }
        return val;
    }
    onMetaClickOfRow(val) {
        if (!val.id) {
            return null;
        }
        return val;
    }
    async hardwareTypeChange() {
        if (!this.hardwareType) {
            return;
        }
        const typeIsReadOnly = READ_ONLY_HARDWARE_TYPES.includes(this.hardwareType);
        this.isViewingArchive = typeIsReadOnly;
        return this.getHardware(this.isViewingArchive);
    }
};
__decorate([
    hardwareModule.State(StateTypes.entities),
    __metadata("design:type", Array)
], HardwareTypeOverviewTable.prototype, "entities", void 0);
__decorate([
    hardwareModule.State(StateTypes.controller),
    __metadata("design:type", typeof (_a = typeof HardwareController !== "undefined" && HardwareController) === "function" ? _a : Object)
], HardwareTypeOverviewTable.prototype, "hardwareController", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _b : Object)
], HardwareTypeOverviewTable.prototype, "hardwareType", void 0);
__decorate([
    hardwareModule.Action(ActionTypes.fetchEntities),
    __metadata("design:type", Function)
], HardwareTypeOverviewTable.prototype, "fetchEntities", void 0);
__decorate([
    Emit('rowClicked'),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], HardwareTypeOverviewTable.prototype, "onClickOfRow", null);
__decorate([
    Emit('metaRowClicked'),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], HardwareTypeOverviewTable.prototype, "onMetaClickOfRow", null);
__decorate([
    Watch('hardwareType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], HardwareTypeOverviewTable.prototype, "hardwareTypeChange", null);
HardwareTypeOverviewTable = __decorate([
    Component
], HardwareTypeOverviewTable);
export default HardwareTypeOverviewTable;
