import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseInput from '~/utils/base/BaseInput';
let AppTextarea = class AppTextarea extends BaseInput {
    removeBorders;
};
__decorate([
    Prop({ default: false }),
    __metadata("design:type", Boolean)
], AppTextarea.prototype, "removeBorders", void 0);
AppTextarea = __decorate([
    Component
], AppTextarea);
export default AppTextarea;
