import { BARCODE_COL, COMMENTS_COL, LOCATION_ICON_COL, SERIAL_COL, SUBTYPE_COL } from '../genericTableColumns';
import { addBaseProperties, addDateDisplays, addLastUpdated } from '../mappers';
export const ITEM_INSTANCES_TABLE_HEADERS = [
    SUBTYPE_COL,
    {
        text: 'Drawing Number',
        value: 'drawingNumber',
        sortable: true
    },
    {
        text: 'As-Built',
        value: '_asBuiltNumber',
        sortable: true
    },
    SERIAL_COL,
    BARCODE_COL,
    LOCATION_ICON_COL,
    {
        text: 'Archived?',
        value: '_isArchivedIcon',
        sortable: true,
        align: 'center',
        noEscape: true
    }
];
/*
When fetching item instances as a nested entity (e.g from /v1/activities/{id})
Item instances do not have a `status` property
*/
export const NON_STATUS_ITEM_INSTANCE_TABLE_HEADERS = [...ITEM_INSTANCES_TABLE_HEADERS].filter((header) => header !== LOCATION_ICON_COL);
export const ACTIVITY_HARDWARE_TABLE_HEADERS = [
    Object.assign({}, { ...SUBTYPE_COL }, { text: 'Hardware Type', width: '150px' }),
    Object.assign({}, { ...SERIAL_COL }, { text: 'Hardware Serial', width: '150px' }),
    Object.assign({}, { ...COMMENTS_COL }, { text: 'Comment', value: '_comment' })
];
export const transformItemInstances = (items) => {
    return items
        .map(addBaseProperties)
        .map(addLastUpdated)
        .map(addDateDisplays)
        .map((ii) => {
        return {
            ...ii
        };
    });
};
