import { User } from '~/utils';
export const UserModuleName = 'user';
export var StateTypes;
(function (StateTypes) {
    StateTypes["authStateChecked"] = "authStateChecked";
    StateTypes["isLoadingUser"] = "isLoadingUser";
    StateTypes["missingNamsApproval"] = "missingNamsApproval";
    StateTypes["user"] = "user";
})(StateTypes || (StateTypes = {}));
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["setAuthStateChecked"] = "setAuthStateChecked";
    MutationTypes["setIsLoadingUser"] = "setIsLoadingUser";
    MutationTypes["setMissingNamsApproval"] = "setMissingNamsApproval";
    MutationTypes["setUser"] = "setUser";
})(MutationTypes || (MutationTypes = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["fetchUser"] = "fetchUser";
})(ActionTypes || (ActionTypes = {}));
const state = {
    authStateChecked: false,
    isLoadingUser: false,
    missingNamsApproval: false,
    user: null
};
const mutations = {
    [MutationTypes.setAuthStateChecked](state, payload) {
        state.authStateChecked = payload;
    },
    [MutationTypes.setIsLoadingUser](state, payload) {
        state.isLoadingUser = payload;
    },
    [MutationTypes.setMissingNamsApproval](state, payload) {
        state.missingNamsApproval = payload;
    },
    [MutationTypes.setUser](state, payload) {
        state.user = payload;
    }
};
const actions = {
    async [ActionTypes.fetchUser]({ commit }) {
        commit(MutationTypes.setIsLoadingUser, true);
        try {
            const res = await User.get();
            commit(MutationTypes.setIsLoadingUser, false);
            commit(MutationTypes.setAuthStateChecked, true);
            const user = res.data;
            commit(MutationTypes.setUser, user);
        }
        catch (error) {
            commit(MutationTypes.setAuthStateChecked, true);
            commit(MutationTypes.setIsLoadingUser, false);
            if (error?.response?.status === 403) {
                commit(MutationTypes.setMissingNamsApproval, true);
                commit(MutationTypes.setUser, null);
                return;
            }
            throw error;
        }
    }
};
const userModule = {
    state,
    mutations,
    actions,
    namespaced: true
};
export default userModule;
