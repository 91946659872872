import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { ModalEventMixin } from '~/mixins';
import { ManagableEventTypes } from '~/types';
let ModalIncrement = class ModalIncrement extends Mixins(ModalEventMixin) {
    baseUrl = '/events/increments';
    eventType = ManagableEventTypes.INCREMENT;
    formData = {
        isActive: true,
        name: null,
        commentText: null,
        startDate: null,
        endDate: null
    };
    get annexTwoLink() {
        return `/reporting/annex_2?startDate=${this.event?.startDate}&endDate=${this.event?.endDate}&autoExecute=true`;
    }
    get isFormValid() {
        return Boolean(this.formData.startDate && this.formData.endDate && this.formData.name);
    }
};
ModalIncrement = __decorate([
    Component
], ModalIncrement);
export default ModalIncrement;
