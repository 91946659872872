import { BatteryState } from '~/types/schemas';
export const getColorFromBatteryState = (state) => {
    if (!state) {
        return 'error';
    }
    switch (state) {
        case BatteryState.CHARGED:
            return 'success';
        case BatteryState.DISCHARGED:
            return 'error';
        case BatteryState.NOT_CHARGED:
            return 'error';
        case BatteryState.PARTIAL:
            return 'warning';
        case BatteryState.UNKNOWN:
            return 'error';
        default:
            return 'error';
    }
};
