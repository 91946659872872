var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ManagableEventTypeDisplay, ManagableEventTypes } from '~/types';
import { ErrorUtility, IEventsDisplay } from '~/utils';
import { BaseModalMixin } from './BaseModalMixin';
let ModalEventMixin = class ModalEventMixin extends BaseModalMixin {
    baseUrl = '/events';
    eventType = null;
    event;
    get disableCreateButton() {
        return !this.isFormValid;
    }
    get disableSaveButton() {
        return Boolean(!this.isEditing || !this.isFormValid);
    }
    get eventTypeDisplay() {
        return this.eventType ? ManagableEventTypeDisplay.get(this.eventType) : '-';
    }
    get isFormValid() {
        return true;
    }
    get isEditing() {
        return Boolean(this.event);
    }
    get isActive() {
        return Boolean(this.event?.isActive);
    }
    get editEventName() {
        return this.event?.name;
    }
    onEventChange(event) {
        if (!event) {
            return;
        }
        this.formData = {
            startDate: event.startDate || null,
            endDate: event.endDate || null,
            commentText: null,
            itemInstances: event.itemInstances?.map((itemInstance) => itemInstance.id) || null,
            name: event.name,
            isActive: Boolean(event.isActive),
            incrementEventId: event.increment?.id || null,
            launchDate: event.launchDate,
            returnDate: event.returnDate
        };
    }
    buildCreatePayload() {
        if (!this.formData.startDate || !this.formData.endDate) {
            return null;
        }
        const payload = {
            commentText: this.formData.commentText,
            endDate: this.formData.endDate,
            isActive: this.formData.isActive,
            name: this.formData.name,
            startDate: this.formData.startDate
        };
        return payload;
    }
    async onClickOfCreate() {
        const payload = this.buildCreatePayload();
        if (!payload) {
            return;
        }
        try {
            await this.$http.post(this.baseUrl, payload);
            this.emitEntityCreated(`${this.eventTypeDisplay} created.`);
            this.closeDialog();
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    async onClickOfDelete() {
        if (!this.event) {
            throw Error(`No ${this.eventTypeDisplay} to delete.`);
        }
        try {
            const url = this.eventType === ManagableEventTypes.FLIGHT
                ? `${this.baseUrl}/${this.event.launchEventId}`
                : `${this.baseUrl}/${this.event.id}`;
            await this.$http.delete(url);
            this.emitEntityDeleted(`${this.eventTypeDisplay} deleted.`);
            this.closeDialog();
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    async onClickOfReactivate() {
        if (!this.event) {
            throw Error(`No ${this.eventTypeDisplay} to update`);
        }
        try {
            const url = this.eventType === ManagableEventTypes.FLIGHT
                ? `${this.baseUrl}/${this.event.launchEventId}`
                : `${this.baseUrl}/${this.event.id}`;
            await this.$http.put(url, Object.assign({}, this.formData, { isActive: true }));
            this.emitEntityEdited(`${this.eventTypeDisplay} ACTIVATED.`);
            this.closeDialog();
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    async onClickOfUpdate() {
        if (!this.event) {
            throw Error(`No ${this.eventTypeDisplay} to update`);
        }
        try {
            const url = this.eventType === ManagableEventTypes.FLIGHT
                ? `${this.baseUrl}/${this.event.launchEventId}`
                : `${this.baseUrl}/${this.event.id}`;
            await this.$http.put(url, this.formData);
            this.emitEntityEdited(`${this.eventTypeDisplay} updated.`);
            this.closeDialog();
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof IEventsDisplay !== "undefined" && IEventsDisplay) === "function" ? _a : Object)
], ModalEventMixin.prototype, "event", void 0);
__decorate([
    Watch('event', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof IEventsDisplay !== "undefined" && IEventsDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalEventMixin.prototype, "onEventChange", null);
ModalEventMixin = __decorate([
    Component
], ModalEventMixin);
export { ModalEventMixin };
