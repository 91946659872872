import { AlertType, IeuLocationTypeDisplay } from '~/types';
import { dateDisplay } from '../dates/dateDisplay';
import { IODINATION_DUE_DATE_COL, STANDARD_COLS, WATER_CIRCULATION_DUE_DATE_COL } from '../genericTableColumns';
import { alertableStatusDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addEvaEvents, addFemurComments, addIodinateDueDate, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
import { FEMUR_UIA_TABLE_HEADERS } from './uia';
export const IEU_SCU_TABLE_HEADERS = [
    ...STANDARD_COLS,
    WATER_CIRCULATION_DUE_DATE_COL,
    IODINATION_DUE_DATE_COL
];
// ieu/scu/uia all have the same cols
export const FEMUR_IEU_SCU_TABLE_HEADERS = [...FEMUR_UIA_TABLE_HEADERS];
export const ACTIVITY_IEU_SCU_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformIeuScu = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addEvaEvents)
        .map(addFemurComments)
        .map(addIodinateDueDate)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        .map((item) => {
        const attributes = item.attributes;
        const status = item.status;
        let _waterCirculationDueDate = dateDisplay(status?.scrubDueDate?.value);
        _waterCirculationDueDate = alertableStatusDisplay(status?.scrubDueDate, _waterCirculationDueDate);
        let _lastIodinateDate = dateDisplay(status.lastIodinateDate?.value);
        _lastIodinateDate = alertableStatusDisplay(status?.lastIodinateDate, _lastIodinateDate);
        let _postEvaScrubDueDate = dateDisplay(status?.postEvaScrubDueDate?.value);
        _postEvaScrubDueDate = alertableStatusDisplay(status?.postEvaScrubDueDate, _postEvaScrubDueDate);
        let _preEvaScrubDueDate = dateDisplay(status?.preEvaScrubDueDate?.value);
        _preEvaScrubDueDate = alertableStatusDisplay(status?.preEvaScrubDueDate, _preEvaScrubDueDate);
        let _scrubDueDate = dateDisplay(status?.scrubDueDate?.value);
        _scrubDueDate = alertableStatusDisplay(status?.scrubDueDate, _scrubDueDate);
        // alerts based on status
        const alerts = [
            status?.scrubDueDate?.alertType,
            status?.lastIodinateDate?.alertType,
            status?.postEvaScrubDueDate?.alertType,
            status?.preEvaScrubDueDate?.alertType
        ];
        const redAlert = alerts.find((alert) => alert === AlertType.ALERT);
        const orangeAlert = alerts.find((alert) => alert === AlertType.WARNING);
        const rowClass = redAlert ? 'red lighten-3' : orangeAlert ? 'orange lighten-3' : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _ieuLocation: attributes.ieuLocation ? IeuLocationTypeDisplay.get(attributes.ieuLocation) : '-',
            _lastActualPostScrubDate: _lastIodinateDate,
            _postEVANLTDate: _postEvaScrubDueDate,
            _preEvaScrubNetDate: _preEvaScrubDueDate,
            _scrubDate: _scrubDueDate,
            _waterCirculationDueDate
        });
        return _item;
    });
};
export const transformIeuScuStatusPropertiesForAhd = (keys) => {
    const statusKeys = keys;
    return statusKeys.map((key) => {
        // Use "Water Circulation" instead of "Scrub" for IEU/SCU
        return key === 'scrubDueDate' ? 'waterCirculationDueDate' : key;
    });
};
