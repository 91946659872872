import { FemurSpecificTypes, HardwareType } from '~/types';
import { EMU_BATTERY_LLB_TABLE_HEADERS, EMU_BIOCIDE_FILTER_TABLE_HEADERS, EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS, EMU_SCRUB_ASSEMBLY_TABLE_HEADERS, EMU_TABLE_HEADERS, EMU_WATER_PROCESSING_JUMPER_TABLE_HEADERS, FEMUR_EMU_BATTERY_LLB_TABLE_HEADERS, FEMUR_EMU_BIOCIDE_FILTER_TABLE_HEADERS, FEMUR_EMU_EXPORT_TABLE_HEADERS, FEMUR_EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS, FEMUR_EMU_SCRUB_ASSEMBLY_TABLE_HEADERS, FEMUR_EMU_TABLE_HEADERS, FEMUR_EMU_WATER_PROCESSING_JUMPER_TABLE_HEADERS, FEMUR_HUT_ORU_SCRUBBER_TABLE_HEADERS, FEMUR_HUT_ORU_TABLE_HEADERS, FEMUR_IEU_SCU_TABLE_HEADERS, FEMUR_IEU_SCU_UIA_EMU_TABLE_HEADERS, FEMUR_LCVG_TABLE_HEADERS, FEMUR_LIB_CHARGER_TABLE_HEADERS, FEMUR_LPGT_BATTERY_TABLE_HEADERS, FEMUR_LREBA_TABLE_HEADERS, FEMUR_METOX_SIMULATOR_TABLE_HEADERS, FEMUR_METOX_TABLE_HEADERS, FEMUR_PGT_TABLE_HEADERS, FEMUR_SAFER_TABLE_HEADERS, FEMUR_SCOF_TABLE_HEADERS, FEMUR_UIA_BIOCIDE_FILTER_TABLE_HEADERS, FPS_TABLE_HEADERS, HL_BATTERY_TABLE_HEADERS, HUT_ORU_SCRUBBER_TABLE_HEADERS, HUT_ORU_TABLE_HEADERS, IEU_SCU_TABLE_HEADERS, LCVG_TABLE_HEADERS, LEGACY_FPU_TABLE_HEADERS, LIB_CHARGER_TABLE_HEADERS, LPGT_BATTERY_TABLE_HEADERS, LREBA_TABLE_HEADERS, METOX_SIMULATOR_TABLE_HEADERS, METOX_TABLE_HEADERS, PGT_BATTERY_TABLE_HEADERS, PGT_TABLE_HEADERS, REBA_TABLE_HEADERS, SAFER_TABLE_HEADERS, SAFETYTETHER55_TABLE_HEADERS, SAFETYTETHER85_TABLE_HEADERS, SCOF_TABLE_HEADERS, TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS, UIA_BIOCIDE_FILTER_TABLE_HEADERS, WAIST_TETHER_TABLE_HEADERS } from '.';
import { EHIP_LIGHT_TABLE_HEADERS, FEMUR_EHIP_LIGHT_TABLE_HEADERS } from './ehipLight';
import { EMU_BATTERY_TABLE_HEADERS } from './emuBattery';
import { EMU_ION_FILTER_TABLE_HEADERS } from './emuIonFilter';
import { EMU_MICRON_FILTER_TABLE_HEADERS } from './emuMicronFilter';
import { HAB_HAP_E_TABLE_HEADERS } from './habHapE';
import { FEMUR_SAFER_LPGT_BATTERY_TABLE_HEADERS, SAFER_LPGT_BATTERY_TABLE_HEADERS } from './saferLpgtBattery';
import { FEMUR_UIA_TABLE_HEADERS, UIA_TABLE_HEADERS } from './uia';
export const TableHeadersByHardwareType = new Map([
    [HardwareType.EHIP_LIGHT, EHIP_LIGHT_TABLE_HEADERS],
    [HardwareType.EMU, EMU_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY, EMU_BATTERY_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY_LLB, EMU_BATTERY_LLB_TABLE_HEADERS],
    [HardwareType.EMU_BIOCIDE_FILTER, EMU_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_FEEDWATER_SUPPLY_FILTER, EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_ION_FILTER, EMU_ION_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_MICRON_FILTER, EMU_MICRON_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_SCRUB_ASSEMBLY, EMU_SCRUB_ASSEMBLY_TABLE_HEADERS],
    [HardwareType.EMU_WATER_PROCESSING_JUMPER, EMU_WATER_PROCESSING_JUMPER_TABLE_HEADERS],
    [HardwareType.FPS, FPS_TABLE_HEADERS],
    [HardwareType.HAB_HAP_E, HAB_HAP_E_TABLE_HEADERS],
    [HardwareType.HL_BATTERY, HL_BATTERY_TABLE_HEADERS],
    [HardwareType.HUT_ORU, HUT_ORU_TABLE_HEADERS],
    [HardwareType.HUT_ORU_SCRUBBER, HUT_ORU_SCRUBBER_TABLE_HEADERS],
    [HardwareType.IEU_SCU, IEU_SCU_TABLE_HEADERS],
    [HardwareType.LCVG, LCVG_TABLE_HEADERS],
    [HardwareType.LEGACY_FPU, LEGACY_FPU_TABLE_HEADERS],
    [HardwareType.LIB_CHARGER, LIB_CHARGER_TABLE_HEADERS],
    [HardwareType.LPGT_BATTERY, LPGT_BATTERY_TABLE_HEADERS],
    [HardwareType.LREBA, LREBA_TABLE_HEADERS],
    [HardwareType.METOX, METOX_TABLE_HEADERS],
    [HardwareType.METOX_SIMULATOR, METOX_SIMULATOR_TABLE_HEADERS],
    [HardwareType.PGT, PGT_TABLE_HEADERS],
    [HardwareType.PGT_BATTERY, PGT_BATTERY_TABLE_HEADERS],
    [HardwareType.REBA, REBA_TABLE_HEADERS],
    [HardwareType.SAFER, SAFER_TABLE_HEADERS],
    [HardwareType.SAFER_LPGT_BATTERY, SAFER_LPGT_BATTERY_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_55, SAFETYTETHER55_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_85, SAFETYTETHER85_TABLE_HEADERS],
    [HardwareType.SCOF, SCOF_TABLE_HEADERS],
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER, TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS],
    [HardwareType.UIA, UIA_TABLE_HEADERS],
    [HardwareType.UIA_BIOCIDE_FILTER, UIA_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.WAIST_TETHER, WAIST_TETHER_TABLE_HEADERS]
]);
export const FemurTableHeadersByHardwareType = new Map([
    [HardwareType.EHIP_LIGHT, FEMUR_EHIP_LIGHT_TABLE_HEADERS],
    [FemurSpecificTypes.EMU_IEU_SCU_UIA, FEMUR_IEU_SCU_UIA_EMU_TABLE_HEADERS],
    [HardwareType.EMU, FEMUR_EMU_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY, EMU_BATTERY_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY_LLB, FEMUR_EMU_BATTERY_LLB_TABLE_HEADERS],
    [HardwareType.EMU_BIOCIDE_FILTER, FEMUR_EMU_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_FEEDWATER_SUPPLY_FILTER, FEMUR_EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_ION_FILTER, EMU_ION_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_MICRON_FILTER, EMU_MICRON_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_WATER_PROCESSING_JUMPER, FEMUR_EMU_WATER_PROCESSING_JUMPER_TABLE_HEADERS],
    [HardwareType.EMU_SCRUB_ASSEMBLY, FEMUR_EMU_SCRUB_ASSEMBLY_TABLE_HEADERS],
    [HardwareType.FPS, FPS_TABLE_HEADERS],
    [HardwareType.HL_BATTERY, HL_BATTERY_TABLE_HEADERS],
    [HardwareType.HUT_ORU, FEMUR_HUT_ORU_TABLE_HEADERS],
    [HardwareType.HUT_ORU_SCRUBBER, FEMUR_HUT_ORU_SCRUBBER_TABLE_HEADERS],
    [HardwareType.IEU_SCU, IEU_SCU_TABLE_HEADERS],
    [HardwareType.LCVG, FEMUR_LCVG_TABLE_HEADERS],
    [HardwareType.LIB_CHARGER, FEMUR_LIB_CHARGER_TABLE_HEADERS],
    [HardwareType.LREBA, LREBA_TABLE_HEADERS],
    [HardwareType.IEU_SCU, FEMUR_IEU_SCU_TABLE_HEADERS],
    [HardwareType.LCVG, FEMUR_LCVG_TABLE_HEADERS],
    [HardwareType.LIB_CHARGER, FEMUR_LIB_CHARGER_TABLE_HEADERS],
    [HardwareType.LPGT_BATTERY, FEMUR_LPGT_BATTERY_TABLE_HEADERS],
    [HardwareType.LREBA, FEMUR_LREBA_TABLE_HEADERS],
    [HardwareType.METOX, FEMUR_METOX_TABLE_HEADERS],
    [HardwareType.METOX_SIMULATOR, FEMUR_METOX_SIMULATOR_TABLE_HEADERS],
    [HardwareType.PGT, FEMUR_PGT_TABLE_HEADERS],
    [HardwareType.PGT_BATTERY, PGT_BATTERY_TABLE_HEADERS],
    [HardwareType.REBA, REBA_TABLE_HEADERS],
    [HardwareType.SAFER, FEMUR_SAFER_TABLE_HEADERS],
    [HardwareType.SAFER_LPGT_BATTERY, FEMUR_SAFER_LPGT_BATTERY_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_55, SAFETYTETHER55_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_85, SAFETYTETHER85_TABLE_HEADERS],
    [HardwareType.SCOF, FEMUR_SCOF_TABLE_HEADERS],
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER, TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS],
    [HardwareType.UIA, FEMUR_UIA_TABLE_HEADERS],
    [HardwareType.UIA_BIOCIDE_FILTER, FEMUR_UIA_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.WAIST_TETHER, WAIST_TETHER_TABLE_HEADERS]
]);
export const FemurXlsExportTableHeadersByHardwareType = new Map([
    [HardwareType.EHIP_LIGHT, FEMUR_EHIP_LIGHT_TABLE_HEADERS],
    [FemurSpecificTypes.EMU_IEU_SCU_UIA, FEMUR_IEU_SCU_UIA_EMU_TABLE_HEADERS],
    [HardwareType.EMU, FEMUR_EMU_EXPORT_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY, EMU_BATTERY_TABLE_HEADERS],
    [HardwareType.EMU_BATTERY_LLB, FEMUR_EMU_BATTERY_LLB_TABLE_HEADERS],
    [HardwareType.EMU_BIOCIDE_FILTER, FEMUR_EMU_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_FEEDWATER_SUPPLY_FILTER, FEMUR_EMU_FEEDWATER_SUPPLY_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_ION_FILTER, EMU_ION_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_MICRON_FILTER, EMU_MICRON_FILTER_TABLE_HEADERS],
    [HardwareType.EMU_WATER_PROCESSING_JUMPER, FEMUR_EMU_WATER_PROCESSING_JUMPER_TABLE_HEADERS],
    [HardwareType.EMU_SCRUB_ASSEMBLY, FEMUR_EMU_SCRUB_ASSEMBLY_TABLE_HEADERS],
    [HardwareType.FPS, FPS_TABLE_HEADERS],
    [HardwareType.HL_BATTERY, HL_BATTERY_TABLE_HEADERS],
    [HardwareType.HUT_ORU, FEMUR_HUT_ORU_TABLE_HEADERS],
    [HardwareType.HUT_ORU_SCRUBBER, FEMUR_HUT_ORU_SCRUBBER_TABLE_HEADERS],
    [HardwareType.IEU_SCU, IEU_SCU_TABLE_HEADERS],
    [HardwareType.LCVG, FEMUR_LCVG_TABLE_HEADERS],
    [HardwareType.LIB_CHARGER, FEMUR_LIB_CHARGER_TABLE_HEADERS],
    [HardwareType.LREBA, LREBA_TABLE_HEADERS],
    [HardwareType.IEU_SCU, FEMUR_IEU_SCU_TABLE_HEADERS],
    [HardwareType.LCVG, FEMUR_LCVG_TABLE_HEADERS],
    [HardwareType.LIB_CHARGER, FEMUR_LIB_CHARGER_TABLE_HEADERS],
    [HardwareType.LPGT_BATTERY, FEMUR_LPGT_BATTERY_TABLE_HEADERS],
    [HardwareType.LREBA, FEMUR_LREBA_TABLE_HEADERS],
    [HardwareType.METOX, FEMUR_METOX_TABLE_HEADERS],
    [HardwareType.METOX_SIMULATOR, FEMUR_METOX_SIMULATOR_TABLE_HEADERS],
    [HardwareType.PGT, FEMUR_PGT_TABLE_HEADERS],
    [HardwareType.PGT_BATTERY, PGT_BATTERY_TABLE_HEADERS],
    [HardwareType.REBA, REBA_TABLE_HEADERS],
    [HardwareType.SAFER, FEMUR_SAFER_TABLE_HEADERS],
    [HardwareType.SAFER_LPGT_BATTERY, FEMUR_SAFER_LPGT_BATTERY_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_55, SAFETYTETHER55_TABLE_HEADERS],
    [HardwareType.SAFETY_TETHER_85, SAFETYTETHER85_TABLE_HEADERS],
    [HardwareType.SCOF, FEMUR_SCOF_TABLE_HEADERS],
    [HardwareType.TETHER_EXTENSION_D_RING_EXTENDER, TETHER_EXTENSION_D_RING_EXTENDER_TABLE_HEADERS],
    [HardwareType.UIA, FEMUR_UIA_TABLE_HEADERS],
    [HardwareType.UIA_BIOCIDE_FILTER, FEMUR_UIA_BIOCIDE_FILTER_TABLE_HEADERS],
    [HardwareType.WAIST_TETHER, WAIST_TETHER_TABLE_HEADERS]
]);
