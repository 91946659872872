import { axiosInstance } from '../../plugins/axios';
export default class BaseController {
    $http;
    constructor() {
        const ENV = process.env.ENV || 'production';
        if (['production', 'staging'].includes(ENV)) {
            axiosInstance.defaults.baseURL = '/api/v1';
        }
        else {
            axiosInstance.defaults.baseURL = 'http://localhost:8080/v1/';
        }
        axiosInstance.defaults.withCredentials = true;
        this.$http = axiosInstance;
    }
}
