var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EntityType, HardwareType, HardwareTypeToActivityTableHeaders } from '~/types';
import { buildChipAsString, markdownToHtml, sortObjectBy } from '~/utils';
import { ACTIVITY_TABLE_HEADERS, transformActivities } from '~/utils/constants';
import { COMMENTS_COL } from '~/utils/genericTableColumns';
let ActivitiesTable = class ActivitiesTable extends Vue {
    subType = null;
    activities;
    hardwareSubType;
    activityComments;
    get computedActivities() {
        if (!this.activities) {
            return [];
        }
        const activitiesWithComments = transformActivities(this.activities, this.hardwareSubType).map((activity) => {
            const _activity = { ...activity };
            const matchingComment = this.activityComments.find((c) => c.activityId === activity.id);
            if (matchingComment) {
                _activity._comments = markdownToHtml(matchingComment.commentText);
            }
            else {
                _activity._comments = buildChipAsString({
                    chipIconClass: this.$icons[EntityType.COMMENT],
                    chipText: 'No Comments logged.',
                    color: EntityType.COMMENT,
                    iconColor: EntityType.COMMENT
                });
            }
            return _activity;
        });
        return activitiesWithComments.sort(sortObjectBy('_activityDate', true));
    }
    get computedActivityHardwareTableHeaders() {
        const DEFAULT_COLS = [...ACTIVITY_TABLE_HEADERS];
        if (this.hardwareSubType) {
            const hardwareActivityHeaders = HardwareTypeToActivityTableHeaders.get(this.hardwareSubType) || DEFAULT_COLS;
            return [...hardwareActivityHeaders, COMMENTS_COL];
        }
        return DEFAULT_COLS;
    }
    get rowsPerPageItems() {
        return [
            10,
            25,
            50,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    onMetaClickOfActivityTableRow(item) {
        return item;
    }
    onClickOfActivityTableRow(item) {
        return item;
    }
};
__decorate([
    Prop({
        type: Array,
        default: () => []
    }),
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], ActivitiesTable.prototype, "activities", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _b : Object)
], ActivitiesTable.prototype, "hardwareSubType", void 0);
__decorate([
    Prop({
        default: () => [],
        type: Array
    }),
    __metadata("design:type", typeof (_c = typeof Array !== "undefined" && Array) === "function" ? _c : Object)
], ActivitiesTable.prototype, "activityComments", void 0);
__decorate([
    Emit('meta-row-click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ActivitiesTable.prototype, "onMetaClickOfActivityTableRow", null);
__decorate([
    Emit('row-click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ActivitiesTable.prototype, "onClickOfActivityTableRow", null);
ActivitiesTable = __decorate([
    Component
], ActivitiesTable);
export default ActivitiesTable;
