var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { MaintenanceInterval } from '~/types';
let MaintenanceAlertEditForm = class MaintenanceAlertEditForm extends Vue {
    item;
    warningValue = null;
    get maxDescription() {
        return this.item.unitName === 'days' ? 'due date' : 'max usage';
    }
    onClickOfSave() {
        return this.warningValue;
    }
    onItemUpdate() {
        this.warningValue = this.item.warningValue;
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof MaintenanceInterval !== "undefined" && MaintenanceInterval) === "function" ? _a : Object)
], MaintenanceAlertEditForm.prototype, "item", void 0);
__decorate([
    Emit('submit'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MaintenanceAlertEditForm.prototype, "onClickOfSave", null);
__decorate([
    Watch('item', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MaintenanceAlertEditForm.prototype, "onItemUpdate", null);
MaintenanceAlertEditForm = __decorate([
    Component
], MaintenanceAlertEditForm);
export default MaintenanceAlertEditForm;
