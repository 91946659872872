var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ActivityItemInstanceForm, ActivityType, ActivityTypeDisplay, CommentType, EntityType } from '~/types';
import { ACTIVITY_HARDWARE_TABLE_HEADERS, buildChipAsString, ErrorUtility, markdownToHtml, sortObjectBy, transformItemInstances } from '~/utils';
let ActivityHardwareManage = class ActivityHardwareManage extends Vue {
    itemInstanceToEdit = null;
    showModal = false;
    tableHeaders = ACTIVITY_HARDWARE_TABLE_HEADERS;
    activityComments;
    activityItemInstances;
    activityId;
    activityType;
    get activityToHardwareComment() {
        if (!this.itemInstanceToEdit) {
            return null;
        }
        const foundCommentForThisItemInstance = this.activityToHardwareComments.find((comment) => comment.itemInstanceId === this.itemInstanceToEdit?.itemInstance?.id);
        return foundCommentForThisItemInstance ? foundCommentForThisItemInstance : null;
    }
    get activityToHardwareCommentText() {
        return this.activityToHardwareComment?.commentText;
    }
    get activityToHardwareComments() {
        return this.activityComments
            ? this.activityComments.filter((comment) => this.activityItemInstances.find((aii) => aii.itemInstance?.id === comment.itemInstanceId))
            : [];
    }
    get activityTypeDisplay() {
        return this.activityType ? ActivityTypeDisplay.get(this.activityType) : '';
    }
    get shouldShowAttachedHardwareTable() {
        return this.activityItemInstances.length > 0;
    }
    get transformedItemInstances() {
        if (!this.activityItemInstances) {
            return [];
        }
        const items = transformItemInstances(this.activityItemInstances.map((ii) => ii.itemInstance)).map((ii) => {
            const _ii = { ...ii };
            const foundComment = this.activityToHardwareComments.find((comment) => comment.itemInstanceId === ii.id);
            if (!foundComment) {
                _ii._comment = buildChipAsString({
                    chipIconClass: this.$icons[EntityType.COMMENT],
                    chipText: 'No Comments logged.',
                    color: EntityType.COMMENT,
                    iconColor: EntityType.COMMENT
                });
            }
            else {
                _ii._comment = markdownToHtml(foundComment.commentText);
            }
            return _ii;
        });
        return items.sort(sortObjectBy('serialNumber', false)); // initial sort on table is hardware type. this is secondary
    }
    onModalClose() {
        this.showModal = false;
        this.itemInstanceToEdit = null;
    }
    onClickOfTableRow(tableRow) {
        const itemInstance = this.activityItemInstances.find(({ itemInstance }) => itemInstance?.id === tableRow.id);
        if (!itemInstance) {
            throw Error('Unable to find item instance to update');
        }
        this.itemInstanceToEdit = itemInstance;
        this.showModal = true;
    }
    async createNewComment(commentText, itemInstanceId) {
        if (!commentText || !itemInstanceId) {
            return;
        }
        try {
            const url = `/comments`;
            const opts = {
                activityId: this.activityId,
                commentText,
                itemInstanceId,
                subType: CommentType.ACTIVITY
            };
            await this.$http.post(url, opts);
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    async deleteComment(commentId) {
        if (!commentId) {
            return;
        }
        try {
            const url = `/comments/${commentId}`;
            await this.$http.delete(url);
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    async updateComment(commentText, commentId) {
        if (!commentText || !commentId) {
            return;
        }
        try {
            const url = `/comments/${commentId}`;
            const opts = {
                commentText
            };
            await this.$http.put(url, opts);
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
    async onHardwareAdd(form) {
        try {
            const url = `/activities/${this.activityId}/${form.hardwareType}/${form.itemInstanceId}`;
            await this.$http.put(url, form.attributes);
            const loggingAssociatedHardware = form.associatedHardwareItemInstanceId && form.associatedHardwareType;
            if (loggingAssociatedHardware) {
                const associatedHardwareActivityCreateUrl = `/activities/${this.activityId}/${form.associatedHardwareType}/${form.associatedHardwareItemInstanceId}`;
                await this.$http.put(associatedHardwareActivityCreateUrl, form.attributes);
            }
            this.$notification.add({ text: 'Hardware added to Activity.', type: 'success' });
            // This is when its a new piece of hardware with this activity so its always a create comment
            if (form.commentText && form.itemInstanceId) {
                await this.createNewComment(form.commentText, form.itemInstanceId);
                if (loggingAssociatedHardware) {
                    await this.createNewComment(form.commentText, form.associatedHardwareItemInstanceId);
                }
            }
            this.showModal = false;
        }
        catch (err) {
            ErrorUtility({ err });
        }
        return form;
    }
    async onHardwareUpdate(form) {
        try {
            const url = `/activities/${this.activityId}/${form.hardwareType}/${form.itemInstanceId}`;
            await this.$http.put(url, form.attributes);
            this.$notification.add({ text: 'Hardware on Activity updated.', type: 'success' });
            if (this.activityToHardwareComment?.id &&
                form.commentText &&
                form.itemInstanceId &&
                this.activityToHardwareComment.commentText !== form.commentText) {
                await this.updateComment(form.commentText, this.activityToHardwareComment.id);
            }
            else if (form.commentText && form.itemInstanceId) {
                await this.createNewComment(form.commentText || '', form.itemInstanceId);
            }
            else if (this.activityToHardwareComment?.id && form.commentText === '') {
                await this.deleteComment(this.activityToHardwareComment.id);
            }
            this.onModalClose();
        }
        catch (err) {
            ErrorUtility({ err });
        }
        return form;
    }
    async onHardwareDelete() {
        if (!this.itemInstanceToEdit) {
            return;
        }
        try {
            const url = `/activities/${this.activityId}/item-instance/${this.itemInstanceToEdit.itemInstance?.id}`;
            await this.$http.delete(url);
            this.$notification.add({ text: 'Hardware removed from Activity.', type: 'success' });
            this.onModalClose();
        }
        catch (err) {
            ErrorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: Array
    }),
    __metadata("design:type", Array)
], ActivityHardwareManage.prototype, "activityComments", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], ActivityHardwareManage.prototype, "activityItemInstances", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], ActivityHardwareManage.prototype, "activityId", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof ActivityType !== "undefined" && ActivityType) === "function" ? _a : Object)
], ActivityHardwareManage.prototype, "activityType", void 0);
__decorate([
    Emit('hardware-added'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof ActivityItemInstanceForm !== "undefined" && ActivityItemInstanceForm) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], ActivityHardwareManage.prototype, "onHardwareAdd", null);
__decorate([
    Emit('hardware-updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof ActivityItemInstanceForm !== "undefined" && ActivityItemInstanceForm) === "function" ? _c : Object]),
    __metadata("design:returntype", Promise)
], ActivityHardwareManage.prototype, "onHardwareUpdate", null);
__decorate([
    Emit('hardware-deleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ActivityHardwareManage.prototype, "onHardwareDelete", null);
ActivityHardwareManage = __decorate([
    Component
], ActivityHardwareManage);
export default ActivityHardwareManage;
