/* unplugin-vue-components disabled */import __unplugin_components_7 from '/app/src/components/buttons/ButtonDelete/ButtonDelete.vue';
import __unplugin_components_6 from '/app/src/components/buttons/ButtonCancel/ButtonCancel.vue';
import __unplugin_components_5 from '/app/src/components/buttons/ButtonSave/ButtonSave.vue';
import __unplugin_components_4 from '/app/src/components/buttons/ButtonCreate/ButtonCreate.vue';
import __unplugin_components_3 from '/app/src/components/inputs/AppTextarea/AppTextarea.vue';
import __unplugin_components_2 from '/app/src/components/Alert/Alert.vue';
import __unplugin_components_1 from '/app/src/components/inputs/AppSelectEnum/AppSelectEnum.vue';
import __unplugin_components_0 from '/app/src/components/AppModal/AppModal.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{attrs:{"color":"comments"},on:{"modal-close":_vm.emitModalClose},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.isEditing ? 'Edit' : 'Create')+" Comment ")]},proxy:true},{key:"content",fn:function(){return [_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{attrs:{"xs6":"","md6":""}},[_c(__unplugin_components_1,{attrs:{"required":"","chips":false,"enum":_vm.CommentType,"existingValuesToBeRemoved":_vm.commentsToBlacklist,"display":_vm.CommentTypeDisplay,"label":"Comment Type"},model:{value:(_vm.formData.subType),callback:function ($$v) {_vm.$set(_vm.formData, "subType", $$v)},expression:"formData.subType"}})],1),(_vm.formData.subType === _vm.CommentType.FEMU_R_003)?_c(__unplugin_components_2,{attrs:{"type":"info"}},[_c('p',[_vm._v("This comment will display on the FEMU-R-003 report")])]):_vm._e(),_c('v-flex',[_c(__unplugin_components_3,{attrs:{"required":"","label":"Comment","outline":""},model:{value:(_vm.formData.commentText),callback:function ($$v) {_vm.$set(_vm.formData, "commentText", $$v)},expression:"formData.commentText"}})],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-flex',{attrs:{"xs6":""}},[(!_vm.isEditing)?_c(__unplugin_components_4,{staticClass:"ml-0 mr-2",attrs:{"disabled":_vm.shouldDisableCreateCommentButton},on:{"click":_vm.onClickOfCreate}},[_vm._v(" Create Comment ")]):_vm._e(),(_vm.isEditing)?_c(__unplugin_components_5,{staticClass:"ml-0 mr-2",attrs:{"disabled":_vm.shouldDisableCreateCommentButton},on:{"click":_vm.onClickOfEdit}},[_vm._v(" Update Comment ")]):_vm._e(),_c(__unplugin_components_6,{on:{"click":_vm.emitModalClose}})],1),(_vm.isEditing)?_c('v-flex',{attrs:{"xs6":"","text-xs-right":""}},[_c(__unplugin_components_7,{on:{"click":_vm.onClickOfDelete}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }