import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseInput from '~/utils/base/BaseInput';
let AppText = class AppText extends BaseInput {
    upperCase;
    replaceSpacesWithUnderscores;
    get computedMessages() {
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return ['REQUIRED'];
        }
        if (this.messages) {
            return this.messages;
        }
        if (this.upperCase && !this.replaceSpacesWithUnderscores && this.value) {
            return [`${this.value.toUpperCase()} accepted.`];
        }
        if (this.upperCase && this.replaceSpacesWithUnderscores && this.value) {
            // trim value for whitespace
            let val = this.value && this.value.trim ? this.value.trim() : this.value;
            if (this.upperCase && val.toUpperCase) {
                val = val.toUpperCase();
            }
            if (this.replaceSpacesWithUnderscores && val) {
                val = val.split(' ').join('_');
            }
            return [`${val} accepted.`];
        }
        // show messages based on rules
        // @ts-ignore
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string');
    }
    emitInput(val) {
        // trim value for whitespace
        val = val && val.trim ? val.trim() : val;
        if (this.upperCase && val.toUpperCase) {
            val = val.toUpperCase();
        }
        if (this.replaceSpacesWithUnderscores && val) {
            val = val.split(' ').join('_');
        }
        return val;
    }
    emitKeydown() { }
};
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppText.prototype, "upperCase", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppText.prototype, "replaceSpacesWithUnderscores", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppText.prototype, "emitInput", null);
__decorate([
    Emit('keydown'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppText.prototype, "emitKeydown", null);
AppText = __decorate([
    Component
], AppText);
export default AppText;
