import { EventType } from './schemas';
export const EventTypeDisplay = new Map([
    [EventType.EVA, 'EVA'],
    [EventType.LAUNCH, 'Launch'],
    [EventType.RETURN, 'Return'],
    [EventType.INCREMENT, 'Increment']
]);
export var ManagableEventTypes;
(function (ManagableEventTypes) {
    ManagableEventTypes["EVA"] = "EVA";
    ManagableEventTypes["FLIGHT"] = "FLIGHT";
    ManagableEventTypes["INCREMENT"] = "INCREMENT";
})(ManagableEventTypes || (ManagableEventTypes = {}));
export const ManagableEventTypeDisplay = new Map([
    [ManagableEventTypes.EVA, 'EVA'],
    [ManagableEventTypes.INCREMENT, 'Increment'],
    [ManagableEventTypes.FLIGHT, 'Flight']
]);
