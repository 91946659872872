import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { CommentsTableMixin } from '~/mixins/comments/CommentsTableMixin';
import { CommentType, CommentTypeDisplay } from '~/types';
let ReadOnlyComments = class ReadOnlyComments extends Mixins(CommentsTableMixin) {
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    showTimeline = false;
};
ReadOnlyComments = __decorate([
    Component
], ReadOnlyComments);
export default ReadOnlyComments;
