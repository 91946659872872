var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AlertType } from '~/types';
let Alert = class Alert extends Vue {
    alert;
    dismissible;
    text;
    type;
    shouldShowAlert = true;
    get hasAlertProp() {
        return this.alert !== null && this.alert !== undefined;
    }
    get computedAlertType() {
        const type = this.hasAlertProp ? this.alert.type : this.type;
        return type && Object.values(AlertType).includes(type) ? type : undefined;
    }
    get computedAlertText() {
        const text = this.hasAlertProp ? this.alert.text : this.text;
        return text;
    }
    get computedClasses() {
        switch (this.computedAlertType) {
            case AlertType.ERROR:
                return 'error';
            case AlertType.INFO:
                return 'info';
            case AlertType.ALERT:
                return 'error';
            case AlertType.WARNING:
                return 'warning';
            default:
                return 'info';
        }
    }
    get computedIcon() {
        switch (this.computedAlertType) {
            case AlertType.ERROR:
                return 'fad fa-exclamation';
            case AlertType.INFO:
                return 'fad fa-info-circle';
            case AlertType.ALERT:
                return 'fad fa-exclamation';
            case AlertType.WARNING:
                return 'fad fa-exclamation-triangle';
            default:
                return 'fad fa-info-circle';
        }
    }
    dismissAlert() {
        this.shouldShowAlert = false;
        this.$emit('input');
    }
};
__decorate([
    Prop({
        type: [Object]
    }),
    __metadata("design:type", typeof (_a = typeof Partial !== "undefined" && Partial) === "function" ? _a : Object)
], Alert.prototype, "alert", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", typeof (_b = typeof Boolean !== "undefined" && Boolean) === "function" ? _b : Object)
], Alert.prototype, "dismissible", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], Alert.prototype, "text", void 0);
__decorate([
    Prop({
        type: [String, String]
    }),
    __metadata("design:type", typeof (_c = typeof AlertType !== "undefined" && AlertType) === "function" ? _c : Object)
], Alert.prototype, "type", void 0);
Alert = __decorate([
    Component
], Alert);
export default Alert;
