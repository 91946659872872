import { EntityType, HardwareTypeDisplay } from '~/types';
import { serialNumberDisplay } from '..';
export const buildChipAsString = (options) => {
    if (!options || !options.chipText) {
        return '';
    }
    const chipClass = options.chipClass || '';
    const chipText = options.chipText || '?';
    const chipIconClass = options.chipIconClass || '';
    const iconColor = options.iconColor || options.color || 'default';
    return `
    <span class="v-chip v-chip--outline ${chipClass}" style="height: ${options.dense ? '24px' : '32px'}">
      <div class="v-chip__content ${iconColor}--text" style="padding: 0 ${options.dense ? '6px' : '12px'}">
        <i class="${chipIconClass ? 'pr-1' : ''} ${chipIconClass}"></i>
        ${chipText}
      </div>
    </span>
  `;
};
export const buildItemInstanceChip = (itemInstance) => {
    return buildChipAsString({
        chipText: `${itemInstance.subType ? HardwareTypeDisplay.get(itemInstance.subType) : ''}: ${serialNumberDisplay(itemInstance.serialNumber)}`,
        chipClass: EntityType.ITEM_INSTANCE,
        iconColor: EntityType.ITEM_INSTANCE,
        dense: true
    });
};
export const buildEventChip = (event) => {
    return buildChipAsString({
        chipText: `${event.name}`,
        chipClass: EntityType.EVENT,
        iconColor: EntityType.EVENT,
        dense: true
    });
};
