import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { EventTableMixin } from '~/mixins/events/EventTableMixin';
import { transformItemInstances } from '~/utils';
let ReadOnlyFlight = class ReadOnlyFlight extends Mixins(EventTableMixin) {
    get transformedItemInstancesUp() {
        if (!this.eventData.upItemInstances) {
            return [];
        }
        return transformItemInstances(this.eventData.upItemInstances);
    }
    get transformedItemInstancesDown() {
        if (!this.eventData.downItemInstances) {
            return [];
        }
        return transformItemInstances(this.eventData.downItemInstances);
    }
    get comments() {
        return this.eventData.launchEvent?.comments ?? [];
    }
};
ReadOnlyFlight = __decorate([
    Component
], ReadOnlyFlight);
export default ReadOnlyFlight;
