import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ModalEventMixin } from '~/mixins';
import { ManagableEventTypes } from '~/types';
let ModalEva = class ModalEva extends Mixins(ModalEventMixin) {
    baseUrl = '/events/evas';
    eventType = ManagableEventTypes.EVA;
    formData = {
        name: null,
        startDate: null,
        endDate: null,
        commentText: null,
        incrementEventId: null,
        itemInstances: [],
        isActive: true
    };
    get isFormValid() {
        return Boolean(this.formData.startDate && this.formData.endDate);
    }
    buildCreatePayload() {
        const { name, startDate, endDate, itemInstances, commentText, isActive, incrementEventId } = this.formData;
        if (!startDate || !endDate) {
            return null;
        }
        return {
            name,
            startDate,
            endDate,
            itemInstances,
            commentText,
            isActive,
            incrementEventId
        };
    }
    onStartDateUpdate() {
        this.formData.endDate = this.formData.startDate;
    }
};
__decorate([
    Watch('formData.startDate'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalEva.prototype, "onStartDateUpdate", null);
ModalEva = __decorate([
    Component
], ModalEva);
export default ModalEva;
