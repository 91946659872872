import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ReportHeader = class ReportHeader extends Vue {
    heading;
    subheading;
    get subheaderText() {
        return this.subheading || this.$route.meta?.description || null;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", String)
], ReportHeader.prototype, "heading", void 0);
__decorate([
    Prop({
        required: false,
        type: String,
        default: ''
    }),
    __metadata("design:type", String)
], ReportHeader.prototype, "subheading", void 0);
ReportHeader = __decorate([
    Component
], ReportHeader);
export default ReportHeader;
