import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { BaseModalMixin, HardwareFormMixin } from '~/mixins';
import { EventType } from '~/types';
import { ErrorUtility } from '~/utils';
import HardwareController from '~/utils/HardwareController';
let ModalEditHardware = class ModalEditHardware extends Mixins(BaseModalMixin, HardwareFormMixin) {
    hardwareController = null;
    hardwareData = null;
    isPrepopulatingForm = true; // fetch pre-pop form data
    itemInstanceId;
    get shouldDisableActionButtons() {
        return this.isFetching || this.isSaving || !this.isBaseItemInstanceValid;
    }
    async fetchEntity(itemInstanceId) {
        if (!this.hardwareController) {
            throw Error('Unable to fetch entity because no HardwareController has been instantiated');
        }
        this.isFetching = true;
        try {
            const resp = await this.hardwareController.fetchSingleData(itemInstanceId);
            this.hardwareData = resp?.data;
        }
        catch (err) {
            ErrorUtility({
                err
            });
        }
        this.isFetching = false;
    }
    async onClickOfEdit() {
        if (!this.hardwareController) {
            throw Error('Unable to update entity because no HardwareController has been instantiated');
        }
        this.isSaving = true;
        try {
            await this.hardwareController.updateItemInstance(this.itemInstanceId, this.formData);
            this.emitEntityEdited(`${this.hardwareController.hardwareTypeDisplay} Updated.`);
            this.closeDialog();
        }
        catch (err) {
            ErrorUtility({
                err
            });
        }
        this.isSaving = false;
    }
    async onItemInstanceIdChange() {
        if (this.itemInstanceId && this.subType) {
            this.hardwareController = new HardwareController(this.subType);
        }
    }
    onHardwareControllerChange() {
        if (this.hardwareController && this.itemInstanceId) {
            this.fetchEntity(this.itemInstanceId);
        }
    }
    setDefaultFormValues() {
        if (!this.hardwareData) {
            return;
        }
        const launchEventId = this.hardwareData.events?.find((event) => event.subType === EventType.LAUNCH)?.id || null;
        const returnEventId = this.hardwareData.events?.find((event) => event.subType === EventType.RETURN)?.id || null;
        const { asBuiltNumber, attributes, barcode, drawingNumber, isArchived, serialNumber, size, parentItemInstance } = this.hardwareData;
        const formData = {
            drawingNumber,
            asBuiltNumber,
            serialNumber,
            barcode,
            isArchived,
            attributes,
            size,
            launchEventId,
            returnEventId,
            parentItemInstanceId: parentItemInstance?.id,
            femuRCommentText: null,
            generalCommentText: null
        };
        this.formData = formData;
        // done pre-populating form
        this.isPrepopulatingForm = false;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalEditHardware.prototype, "itemInstanceId", void 0);
__decorate([
    Watch('itemInstanceId', { immediate: true }),
    Watch('subType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalEditHardware.prototype, "onItemInstanceIdChange", null);
__decorate([
    Watch('hardwareController', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalEditHardware.prototype, "onHardwareControllerChange", null);
__decorate([
    Watch('hardwareData', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalEditHardware.prototype, "setDefaultFormValues", null);
ModalEditHardware = __decorate([
    Component
], ModalEditHardware);
export default ModalEditHardware;
