export function formatTableValuesForHref(tableRowValue, href) {
    if (tableRowValue === '-') {
        return tableRowValue;
    }
    return `<a href="${href}" target="_blank">${tableRowValue}</a>`;
}
export * from '~/utils/base';
export * from '~/utils/constants';
export * from '~/utils/dates';
export * from '~/utils/ErrorUtility';
export * from '~/utils/genericTableColumns';
export * from '~/utils/helpers';
export * from '~/utils/RouterLogUtility';
export * from '~/utils/ui';
export { default as EventBus } from './EventBus';
export { default as Log } from './Log';
export { default as Notification } from './Notification';
export * from './User';
export { default as User } from './User';
