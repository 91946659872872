import { asBuiltNumberDisplay, booleanDisplay, currencyDisplay, intervalDisplay, numberDisplay, percentageDisplay, serialNumberDisplay, sideDisplay, sizeDisplay, stringListDisplay, transformHtmlLineBreak } from '~/utils';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $asBuiltNumberDisplay: { value: asBuiltNumberDisplay },
            $booleanDisplay: { value: booleanDisplay },
            $currencyDisplay: { value: currencyDisplay },
            $intervalDisplay: { value: intervalDisplay },
            $numberDisplay: { value: numberDisplay },
            $percentageDisplay: { value: percentageDisplay },
            $serialNumberDisplay: { value: serialNumberDisplay },
            $sideDisplay: { value: sideDisplay },
            $sizeDisplay: { value: sizeDisplay },
            $stringListDisplay: { value: stringListDisplay },
            $transformHtmlLineBreak: { value: transformHtmlLineBreak }
        });
    }
};
