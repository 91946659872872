import BaseController from './base/BaseController';
const DEV_USER = {
    auid: 'cosmic',
    uupic: '42',
    name: 'Jek Porkins',
    givenName: 'Jek',
    familyName: 'Porkins',
    email: 'cosmic@appdat.jsc.nasa.gov',
    emailVerified: true,
    preferredUsername: 'cosmic@appdat.jsc.nasa.gov',
    roles: []
};
export default {
    async get() {
        // if (process.env.NODE_ENV === 'development') {
        //   return Promise.resolve(DEV_USER);
        // }
        const { $http } = new BaseController();
        const res = await $http.get('/auth/current-user');
        return res;
    }
};
