import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let ButtonArchive = class ButtonArchive extends Vue {
    isArchived;
    get text() {
        return this.isArchived ? 'Unarchive' : 'Archive';
    }
    get icon() {
        return this.isArchived ? 'fad fa-box-open' : 'fad fa-archive';
    }
};
__decorate([
    Prop({
        required: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonArchive.prototype, "isArchived", void 0);
ButtonArchive = __decorate([
    Component
], ButtonArchive);
export default ButtonArchive;
