import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { EventTableMixin } from '~/mixins/events/EventTableMixin';
import { transformEvents, transformItemInstances } from '~/utils';
let ReadOnlyEVA = class ReadOnlyEVA extends Mixins(EventTableMixin) {
    get associatedHardware() {
        if (!this.eventData.itemInstances) {
            return [];
        }
        return transformItemInstances(this.eventData.itemInstances);
    }
    get increments() {
        if (!this.eventData.computedIncrements) {
            return [];
        }
        return transformEvents(this.eventData.computedIncrements);
    }
    get comments() {
        return this.eventData?.comments ?? [];
    }
};
ReadOnlyEVA = __decorate([
    Component
], ReadOnlyEVA);
export default ReadOnlyEVA;
