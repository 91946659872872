import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let HelpModuleMixin = class HelpModuleMixin extends Vue {
    baseUrl = 'https://appdat.jsc.nasa.gov/cosmic/oot/user-guide/-/wikis/';
    fullUrl(slug) {
        return this.baseUrl + slug;
    }
    links = {
        activities: {
            manage: 'Activities'
        },
        hardware: {
        // Dynamic router links managed in the routerHardwareSubType() getter
        },
        reporting: {
            activities: 'reporting/Activities',
            femur_003: 'reporting/FEMU-R-003',
            annex_2: 'reporting/Annex-2',
            flight_hardware: 'reporting/Flight-Hardware',
            increments: 'reporting/Increments',
            item_instances: 'reporting/Item-Instances'
        }
    };
    get baseUrlForUserGuide() {
        return this.baseUrl;
    }
    get routeHash() {
        return this.$route.hash.substring(1);
    }
    get onSubModule() {
        return this.$route.path.split('/')[2];
    }
    get getHelpSlug() {
        const module = this.$route.path.split('/')[1];
        const fullUrl = this.links[module][this.onSubModule];
        return fullUrl;
    }
    get helpUrl() {
        return this.baseUrl + this.getHelpSlug;
    }
};
HelpModuleMixin = __decorate([
    Component
], HelpModuleMixin);
export default HelpModuleMixin;
