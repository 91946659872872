import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventType } from '~/types';
import { EVA_DATE_COL, ITEM_INSTANCE_COL, NON_STATUS_ITEM_INSTANCE_TABLE_HEADERS } from '~/utils';
import { END_DATE_COL, IS_ACTIVE_COL, NAME_COL, START_DATE_COL } from '~/utils/genericTableColumns';
let EventTableMixin = class EventTableMixin extends Vue {
    eventData;
    itemInstanceTableHeaders = NON_STATUS_ITEM_INSTANCE_TABLE_HEADERS;
    evaTableHeaders = [IS_ACTIVE_COL, NAME_COL, ITEM_INSTANCE_COL, EVA_DATE_COL];
    incrementTableHeaders = [
        Object.assign({}, NAME_COL, { noWrap: true }),
        IS_ACTIVE_COL,
        START_DATE_COL,
        END_DATE_COL
    ];
    onClickOfHardwareRow(item) {
        this.$router.push({
            path: `/reporting/item_instances/${item.id}`,
            query: { subType: item.subType }
        });
    }
    onClickOfIncrementRow(item) {
        this.$router.push({
            path: `/reporting/events/${item.id}`,
            query: { subType: EventType.INCREMENT }
        });
    }
    onClickOfEvaRow(item) {
        this.$router.push({
            path: `/reporting/events/${item.id}`,
            query: { subType: EventType.EVA }
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", Object)
], EventTableMixin.prototype, "eventData", void 0);
EventTableMixin = __decorate([
    Component
], EventTableMixin);
export { EventTableMixin };
