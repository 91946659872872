import marked from 'marked';
import { sanitize } from '.';
export const markdownToHtml = (markdown) => {
    if (!markdown) {
        return '';
    }
    const opts = {
        pedantic: false,
        gfm: true,
        tables: true,
        breaks: true,
        sanitize: false,
        smartLists: true,
        smartypants: true,
        xhtml: true
    };
    return marked(sanitize(markdown), opts);
};
