import { EventBus, Log, Notification } from '~/utils';
import { CosmicIcons } from '~/utils/ui/CosmicIcons';
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $icons: { value: CosmicIcons },
            $listen: { value: EventBus.listen },
            $log: { value: new Log() },
            $message: { value: EventBus.sendMessage },
            $navigate: { value: (val) => (window.location.href = val) },
            $notification: { value: new Notification() },
            $stopListening: { value: EventBus.stopListening }
        });
        // disable scroll wheel on input elements
        document.addEventListener('wheel', () => {
            const numberInput = document.activeElement;
            if (numberInput.type === 'number') {
                numberInput.blur();
            }
        });
    }
};
