import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let AppModal = class AppModal extends Vue {
    color;
    modalWidth;
    title;
    emitModalClose() { }
};
__decorate([
    Prop({
        type: String,
        default: 'primary'
    }),
    __metadata("design:type", String)
], AppModal.prototype, "color", void 0);
__decorate([
    Prop({
        type: String,
        default: '90vw'
    }),
    __metadata("design:type", String)
], AppModal.prototype, "modalWidth", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], AppModal.prototype, "title", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppModal.prototype, "emitModalClose", null);
AppModal = __decorate([
    Component
], AppModal);
export default AppModal;
