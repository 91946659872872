import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BaseModalMixin, HardwareFormMixin } from '~/mixins';
import { ActionTypes, HardwareModuleName } from '~/store/hardware';
import { ErrorUtility } from '~/utils';
import HardwareController from '~/utils/HardwareController';
import formDefaults from '~/utils/forms/defaults';
const hardwareModule = namespace(HardwareModuleName);
let ModalCreateHardware = class ModalCreateHardware extends Mixins(BaseModalMixin, HardwareFormMixin) {
    hardwareController = null;
    get shouldDisableActionButtons() {
        return this.isFetching || this.isSaving || !this.isBaseItemInstanceValid;
    }
    fetchEntities;
    async onClickOfCreate() {
        if (!this.hardwareController) {
            throw Error('Unable to create entity because no HardwareController has been instantiated');
        }
        this.isSaving = true;
        try {
            await this.hardwareController.createItemInstance(this.formData);
            this.emitEntityCreated(`${this.hardwareController.hardwareTypeDisplay} Created.`);
            await this.fetchEntities(false);
            this.closeDialog();
        }
        catch (err) {
            ErrorUtility({ err, backupErrorText: `Failed to create ${this.hardwareController.hardwareTypeDisplay}` });
        }
        this.isSaving = false;
    }
    onHardwareTypeChange() {
        if (this.subType) {
            this.hardwareController = new HardwareController(this.subType);
            let defaults = formDefaults[this.subType];
            defaults = { ...defaults, attributes: { ...defaults?.attributes } };
            this.formData = {
                ...defaults,
                femuRCommentText: null,
                generalCommentText: null,
                launchEventId: null,
                returnEventId: null
            };
        }
    }
};
__decorate([
    hardwareModule.Action(ActionTypes.fetchEntities),
    __metadata("design:type", Function)
], ModalCreateHardware.prototype, "fetchEntities", void 0);
__decorate([
    Watch('subType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCreateHardware.prototype, "onHardwareTypeChange", null);
ModalCreateHardware = __decorate([
    Component
], ModalCreateHardware);
export default ModalCreateHardware;
