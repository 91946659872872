import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CHANGELOG from '../../../CHANGELOG.md'; // webpack plugin makes this a useable component
let Changelog = class Changelog extends Vue {
};
Changelog = __decorate([
    Component({
        components: {
            CHANGELOG
        }
    })
], Changelog);
export default Changelog;
