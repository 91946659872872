var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { BaseModalMixin } from '~/mixins';
import { ActivityType, ActivityTypeDisplay, ActivityTypeToHardwareTypes, BaseActivityItemInstance, HardwareType, HardwareTypeDisplay, Maybe } from '~/types';
import { ErrorUtility, transformItemInstances } from '~/utils';
import { getActivityFormFields } from '~/utils/FormBuilder';
import HardwareController from '~/utils/HardwareController';
let ModalCreateEditActivityHardware = class ModalCreateEditActivityHardware extends Mixins(BaseModalMixin) {
    activityFormPartials = [];
    hardware = [];
    selectedItemInstance = null;
    formData = {
        attributes: {},
        commentText: null,
        hardwareType: null,
        itemInstanceId: null,
        associatedHardwareItemInstanceId: null,
        associatedHardwareType: null
    };
    activityType;
    commentText;
    existingItemInstances;
    itemInstanceToEdit;
    onFormDataChange(val) {
        this.formData = val;
    }
    get activityTypeDisplay() {
        return this.activityType ? ActivityTypeDisplay.get(this.activityType) : '';
    }
    get addingEmuWithAssociatedHut() {
        return (this.formData.hardwareType === HardwareType.EMU &&
            this.selectedItemInstance?.childItemInstances?.find((child) => child.subType === HardwareType.HUT_ORU));
    }
    get addingHutWithAssociatedEmu() {
        return (this.formData.hardwareType === HardwareType.HUT_ORU &&
            this.selectedItemInstance?.parentItemInstance?.subType === HardwareType.EMU);
    }
    get associatedHardware() {
        // this should be cleaned up, trying to get something working today
        if (this.addingEmuWithAssociatedHut) {
            const associatedHut = this.selectedItemInstance?.childItemInstances?.find((child) => child.subType === HardwareType.HUT_ORU);
            this.formData.associatedHardwareItemInstanceId = associatedHut?.id;
            this.formData.associatedHardwareType = HardwareType.HUT_ORU;
            return {
                hardwareType: HardwareType.HUT_ORU,
                serialNumber: associatedHut?.serialNumber,
                itemInstanceId: associatedHut?.id
            };
        }
        if (this.addingHutWithAssociatedEmu) {
            this.formData.associatedHardwareItemInstanceId = this.selectedItemInstance?.parentItemInstance?.id;
            this.formData.associatedHardwareType = HardwareType.EMU;
            return {
                hardwareType: HardwareType.EMU,
                itemInstanceId: this.selectedItemInstance?.parentItemInstance?.id,
                serialNumber: this.selectedItemInstance?.parentItemInstance?.serialNumber
            };
        }
        return {};
    }
    get associatedHardwareOptions() {
        return [
            {
                displayText: `${HardwareTypeDisplay.get(this.associatedHardware.hardwareType)}: ${this.associatedHardware.serialNumber}`,
                value: this.associatedHardware.itemInstanceId
            }
        ];
    }
    get computedCommentMessage() {
        return `This Comment is tied to this ${this.activityTypeDisplay} and ${this.selectedItemInstance?._subType} ${this.selectedItemInstance?._serial}`;
    }
    get disableHardwareSelect() {
        return !this.formData.hardwareType || this.isEditing;
    }
    get disableSaveButton() {
        return !this.formIsValid;
    }
    get formIsValid() {
        return Boolean(this.formData.hardwareType && this.formData.itemInstanceId);
    }
    get hardwareTypeOptions() {
        const types = ActivityTypeToHardwareTypes.get(this.activityType) ?? [];
        return types.map((hardwareType) => ({
            displayText: HardwareTypeDisplay.get(hardwareType) || '',
            value: hardwareType
        }));
    }
    get hardwareOptions() {
        const allHardware = this.hardware.map((item) => ({
            displayText: `${HardwareTypeDisplay.get(item.subType)}: ${item.serialNumber}`,
            value: item.id
        }));
        if (this.isEditing) {
            return allHardware;
        }
        const availableHardware = allHardware.filter((item) => {
            return !this.existingItemInstances.some((existingItem) => existingItem.itemInstance.id === item.value);
        });
        return availableHardware;
    }
    get isEditing() {
        return Boolean(this.itemInstanceToEdit);
    }
    get loggingAssociatedHardware() {
        if (this.isEditing) {
            return false;
        }
        return this.addingEmuWithAssociatedHut || this.addingHutWithAssociatedEmu;
    }
    get shouldShowNoAdditionalInfoAlert() {
        return (!this.activityFormPartials.length || !this.activityFormPartials.some((formPartial) => formPartial.fields.length));
    }
    get title() {
        return `${this.isEditing ? 'Update' : 'Add'} Hardware ${this.isEditing ? 'on' : 'to'} ${this.activityTypeDisplay}`;
    }
    onClickOfAdd() {
        return this.formData;
    }
    onClickOfUpdate() {
        return this.formData;
    }
    onClickOfDelete() { }
    /**
     * Auto select hardwareType if theres only 1 option
     */
    onHardwareTypeOptionsBuild() {
        if (this.hardwareTypeOptions.length === 1) {
            this.formData.hardwareType = this.hardwareTypeOptions[0].value;
        }
    }
    /**
     * Auto select itemInstanceId if theres only 1 option
     */
    onHardwareOptionsBuild() {
        if (this.hardwareOptions.length === 1) {
            this.formData.itemInstanceId = this.hardwareOptions[0].value;
        }
    }
    async onItemInstanceSelection() {
        try {
            if (!this.formData.itemInstanceId || !this.formData.hardwareType) {
                return;
            }
            const hardwareController = new HardwareController(this.formData.hardwareType);
            const { data: itemInstance } = await hardwareController.fetchSingleData(this.formData.itemInstanceId);
            if (itemInstance) {
                this.selectedItemInstance = transformItemInstances([itemInstance])[0];
            }
            else {
                this.selectedItemInstance = null;
            }
        }
        catch (err) {
            ErrorUtility({
                err
            });
        }
    }
    async onActivityTypeChange(hardwareType) {
        if (!hardwareType) {
            return;
        }
        const controller = new HardwareController(hardwareType);
        const { data } = await controller.fetchAllData(false);
        this.hardware = data;
        this.activityFormPartials = await getActivityFormFields(hardwareType, this.activityType);
        if (!this.formData.itemInstanceId) {
            this.selectedItemInstance = null;
        }
        if (!this.isEditing) {
            this.formData.attributes = {};
        }
    }
    onItemInstanceToEditChange(itemInstance) {
        if (!itemInstance) {
            return;
        }
        this.formData.attributes = { ...itemInstance.activityAttributes };
        this.formData.hardwareType = itemInstance.itemInstance?.subType;
        this.formData.itemInstanceId = itemInstance.itemInstance?.id;
    }
    syncCommentTextPropWithFormData(val) {
        this.formData.commentText = val;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof ActivityType !== "undefined" && ActivityType) === "function" ? _a : Object)
], ModalCreateEditActivityHardware.prototype, "activityType", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], ModalCreateEditActivityHardware.prototype, "commentText", void 0);
__decorate([
    Prop({
        default: [],
        type: Array
    }),
    __metadata("design:type", Array)
], ModalCreateEditActivityHardware.prototype, "existingItemInstances", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], ModalCreateEditActivityHardware.prototype, "itemInstanceToEdit", void 0);
__decorate([
    Emit('hardware-add'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCreateEditActivityHardware.prototype, "onClickOfAdd", null);
__decorate([
    Emit('hardware-update'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCreateEditActivityHardware.prototype, "onClickOfUpdate", null);
__decorate([
    Emit('hardware-delete'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCreateEditActivityHardware.prototype, "onClickOfDelete", null);
__decorate([
    Watch('hardwareTypeOptions', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCreateEditActivityHardware.prototype, "onHardwareTypeOptionsBuild", null);
__decorate([
    Watch('hardwareOptions', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCreateEditActivityHardware.prototype, "onHardwareOptionsBuild", null);
__decorate([
    Watch('formData.itemInstanceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalCreateEditActivityHardware.prototype, "onItemInstanceSelection", null);
__decorate([
    Watch('formData.hardwareType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof HardwareType !== "undefined" && HardwareType) === "function" ? _d : Object]),
    __metadata("design:returntype", Promise)
], ModalCreateEditActivityHardware.prototype, "onActivityTypeChange", null);
__decorate([
    Watch('itemInstanceToEdit', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof BaseActivityItemInstance !== "undefined" && BaseActivityItemInstance) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], ModalCreateEditActivityHardware.prototype, "onItemInstanceToEditChange", null);
__decorate([
    Watch('commentText', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], ModalCreateEditActivityHardware.prototype, "syncCommentTextPropWithFormData", null);
ModalCreateEditActivityHardware = __decorate([
    Component
], ModalCreateEditActivityHardware);
export default ModalCreateEditActivityHardware;
