import { CommentTypeDisplay } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { markdownToHtml } from '..';
import { addDateDisplays } from '../mappers';
export const COMMENT_TABLE_HEADERS = [
    { text: 'Created by<br/>User', value: 'createdBy' },
    { text: 'Comment', value: '_comment', noEscape: true },
    { text: 'Comment Type', value: '_subType', noEscape: true },
    {
        text: 'Created On',
        noWrap: true,
        value: '_createdDate',
        sortable: true,
        headerSortType: HeaderSortOptions.DATE
    }
];
export const transformComments = (items) => {
    return items
        .map((item) => {
        if (item && 'comment' in item) {
            item._comment = markdownToHtml(item.comment);
        }
        else if (item && 'commentText' in item) {
            item._comment = markdownToHtml(item.commentText);
        }
        return item;
    })
        .map((item) => {
        item._subType = CommentTypeDisplay.get(item.subType) || '-';
        return item;
    })
        .map(addDateDisplays);
};
