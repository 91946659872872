import { CHARGE_LOCATION_COL, FINAL_CHARGE_STATE_COL, GENERIC_STATE_ICON_COL, NEXT_ACTIVITY_RESET_DUE_DATE_COL, POSTCHARGE_OCV_COL, PRECHARGE_OCV_COL, STANDARD_COLS, TOTAL_CHARGE_TIME_COL } from '../genericTableColumns';
import { addAttributeDateDisplays, addBaseProperties, addGenericState, addLaunchReturn, addNextActivityResetDueDate, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const HL_BATTERY_TABLE_HEADERS = [
    ...STANDARD_COLS,
    GENERIC_STATE_ICON_COL,
    NEXT_ACTIVITY_RESET_DUE_DATE_COL
];
export const ACTIVITY_HL_BATTERY_TABLE_HEADERS = [
    ...ACTIVITY_TABLE_HEADERS,
    FINAL_CHARGE_STATE_COL,
    PRECHARGE_OCV_COL,
    POSTCHARGE_OCV_COL,
    TOTAL_CHARGE_TIME_COL,
    CHARGE_LOCATION_COL
];
export const transformHlBattery = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addGenericState)
        .map(addNextActivityResetDueDate)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays);
};
