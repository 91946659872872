export var FormType;
(function (FormType) {
    FormType[FormType["Hardware"] = 0] = "Hardware";
    FormType[FormType["Activity"] = 1] = "Activity";
})(FormType || (FormType = {}));
export const getFormFields = async (hardwareType, formType = FormType.Hardware) => {
    try {
        const dir = formType === FormType.Hardware ? 'hardware' : 'activities';
        const { default: form } = await import(`./forms/${dir}/${hardwareType}`);
        if (typeof form === 'object') {
            return form;
        }
        if (typeof form === 'function') {
            return form();
        }
        throw Error('imported module does not export an object or factory function');
    }
    catch (err) {
        console.warn(`No matching attribute form partial defined ${hardwareType}`);
        return [];
    }
};
export const getActivityFormFields = async (hardwareType, activityType) => {
    const partials = await getFormFields(hardwareType, FormType.Activity);
    if (!partials) {
        return [];
    }
    const partialsFilteredByActivityType = partials.map((partial) => {
        const fields = partial.fields.filter((field) => {
            if (field.shownTypes) {
                return field.shownTypes.includes(activityType);
            }
            return true;
        });
        return {
            ...partial,
            fields
        };
    });
    return partialsFilteredByActivityType;
};
