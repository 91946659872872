import { AlertType } from '~/types';
import { HeaderSortOptions } from '~/types/misc';
import { dateDisplay } from '../dates/dateDisplay';
import { INSTALLED_COL, STANDARD_COLS } from '../genericTableColumns';
import { alertableStatusDisplay, booleanDisplay } from '../helpers';
import { addAttributeDateDisplays, addBaseProperties, addLaunchReturn, addStatusDateDisplays } from '../mappers';
import { ACTIVITY_TABLE_HEADERS } from './activities';
export const FPS_TABLE_HEADERS = [
    ...STANDARD_COLS,
    INSTALLED_COL,
    {
        text: '2hr operation required',
        value: '_twoHourOperationRequiredDueDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        headerSortType: HeaderSortOptions.DATE
    }
];
export const ACTIVITY_FPS_TABLE_HEADERS = [...ACTIVITY_TABLE_HEADERS];
export const transformFps = (items) => {
    return items
        .map(addAttributeDateDisplays)
        .map(addBaseProperties)
        .map(addLaunchReturn)
        .map(addStatusDateDisplays)
        .map((item) => {
        const isInstalled = item.attributes?.isInstalled || false;
        const isInstalledText = isInstalled ? 'Installed' : 'Not Installed';
        const _item = Object.assign({}, item, {
            _isInstalledIcon: booleanDisplay(isInstalled, isInstalledText),
            _isInstalledText: isInstalledText
        });
        return _item;
    })
        .map((item) => {
        const status = item.status;
        let _greaseCirculateDueDate = dateDisplay(status?.greaseCirculateDueDate?.value);
        _greaseCirculateDueDate = alertableStatusDisplay(status?.greaseCirculateDueDate, _greaseCirculateDueDate);
        // adding row class based on greaseCirculateDueDate
        const rowClass = item.status?.greaseCirculateDueDate?.alertType === AlertType.ALERT
            ? 'red lighten-3'
            : item.status?.greaseCirculateDueDate?.alertType === AlertType.WARNING
                ? 'orange lighten-3'
                : '';
        const _item = Object.assign({}, item, {
            rowClass,
            _twoHourOperationRequiredDueDate: _greaseCirculateDueDate
        });
        return _item;
    });
};
